<template>
<modal
    name="delete-reply"
    :width="0"
    @before-open="beforeOpen">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <div class="text__center margin-b__20">
                <b>{{ $t('DELETE_REPLY_MODAL.TITLE') }}</b>
            </div>
            <div class="text__center">
                <button
                    class="btn__reset margin-r__10"
                    @click="hide">
                    {{ $t('BUTTON.NO') }}
                </button>
                <button
                    class="btn btn__m btn__round"
                    @click="handleDeleteReply">
                    {{ $t('BUTTON.YES') }}
                </button>
            </div>
        </section>
    </div>
</modal>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'DeleteReplyModal',
        data() {
            return {
                reply: null
            }
        },
        methods: {
            ...mapActions('entities/comments', {
                deleteReply: 'DELETE_REPLY'
            }),
            // @vuese
            // Method to hide DeleteReply modal
            hide() {
                this.$modal.hide('delete-reply')
            },
            handleDeleteReply() {
                this.deleteReply(this.reply)
                this.hide()
            },
            // @vuese
            // Method that call before modal open and set to local data reply
            // @arg The argument is modal-open event with param reply [object]
            beforeOpen(event) {
                if (event && event.params && event.params.reply) {
                    this.reply = event.params.reply
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal__sphir_Online_Access_Agreement {
        section {
            width: 34rem;
        }
    }
</style>