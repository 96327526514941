<template>
<div v-if="show" class="incomplete-viewing-msg">
    <a class="modal-box"
       :href="holder_path + '#auto_play'">
        <div class="close"
             @click="close">
            <img src="https://enklav-production.s3.amazonaws.com/uploads/image/x-20x20-white.svg">
        </div>
        <div class="m-header bold">
            <img v-if="poster" class="poster" :src="poster">
            <p>Ready to {{ is_audio ? 'listen' : 'watch' }} "{{ holder.title }}" again?</p>
        </div>
        <div class="m-body">
            You don't want to miss the ending! Don't forget to {{ is_audio ? 'listen to' : 'watch' }} the rest of your {{ is_audio ? 'podcast' : 'video' }}
        </div>
    </a>
</div>
</template>
<script>
    export default {
        data() {
            return {
                show: false,
            }
        },
        computed: {
            user_id: function() { return JSON.parse(localStorage.getItem('user') || "{}").id || window.current_user_id || '' },
            lastMediaPlayIn: function() { return this.user_id && JSON.parse(localStorage.getItem(`lastMediaPlayIn_${this.user_id}`) || '{}') },
            holder: function() { return JSON.parse(this.lastMediaPlayIn.holder || '{}') },
            holder_path: function() {
                return ((this.holder.class && this.holder.id) ? `/${this.holder.class}/${this.holder.id}` : null)
            },
            is_audio: function () {
                return this.holder.type === "Podcast"
            },
            poster: function() { return this.lastMediaPlayIn.poster || '' },
            save_time: function() { return this.lastMediaPlayIn.p_time },
            save_event: function() { return this.lastMediaPlayIn.event },
            time_passed: function() {
                const minutes = (this.save_event === 'playing' ? 5 : 1) * 60 * 1000;
                return (this.save_time && this.save_time < (new Date().getTime() - minutes))
            },
            is_holder_page: function() {
                return (this.holder_path && this.holder_path === window.location.pathname )
            },
        },
        mounted(){
            if (this.time_passed && this.holder_path) {
                if (this.is_holder_page && window.player) {
                    window.player.muted = true;
                    window.player.autoplay = true;
                } else {
                    this.show = true;
                }
            } else {
                localStorage.removeItem(`lastMediaPlayIn_${this.user_id}`)
            }
            localStorage.removeItem('lastMediaPlayIn'); //remove old version; TODO remove me later,
        },
        methods: {
            close: function (event) {
                event.preventDefault();
                event.stopPropagation();
                localStorage.removeItem(`lastMediaPlayIn_${this.user_id}`)
                this.show = false
            }
        },
    }
</script>

<style lang="scss" scoped>
    .incomplete-viewing-msg {
        position: fixed;
        top: 70px;
        right: 10px;
        max-width: 400px;
        background: white;
        border: 1px solid #e6e7e8;
        box-shadow: 0 0 8px 2px #e6e7e8;
        z-index: 104;
        .modal-box {
            display: block;
            color: inherit;
            padding: 20px 40px 20px 20px;
            p {
                margin-bottom: 20px;
            }
        }
        .close {
            margin: 0 !important;
            padding: 10px !important;
            position: absolute;
            right: 0;
            top: 0;
            img {
                width: 20px;
                filter: invert(1.5) sepia(1) saturate(5) hue-rotate(175deg);
            }
            &:focus {
                outline: none;
            }
        }
        .m-header {
            text-align: center;
            .poster{
                max-width: 75px;
                float: left;
                margin: 0 10px 5px 0;
            }
        }
        .m-body {
            text-align: center;
            h3 {
                margin: 40px auto
            }
        }

        @media (min-width: 2000px) {
          top: 85px;
          max-width: 500px;
          .m-header .poster{
            max-width: 100px;
          }
        }

        @media (max-width:560px) {
            position: fixed;
            z-index: 1000;
            top: unset;
            right: 0;
            left: 0;
            bottom: 0;
            max-width: unset;
            .modal-box {
                padding: 10px 30px 10px 10px;
            }
            .close {
                padding: 5px !important;
            }
        }
    }
</style>