import { Model } from '@vuex-orm/core';
import Post from '@models/Post';

class Group extends Model {
    static entity = 'group'

    static fields () {
        return {
            id: this.attr(),
            name: this.attr(),
            score: this.attr(),
            avatar: this.attr(),
            is_active: this.attr(),
            description: this.attr(),
            shares_count: this.attr(),
            members_count: this.attr(),
            elevates_count: this.attr(),
            followers_count: this.attr(),
            group_folder_count: this.attr(),
            created_at: this.attr(),
            group_email: this.attr(),
            group_tags: this.attr(),
            group_website: this.attr(),
            group_rules: this.attr(),
            group_privacy: this.attr(),
            is_admin: this.attr(),
            is_approved: this.attr(),
            is_blocked: this.attr(),
            is_following: this.attr(),
            is_joined: this.attr(),
            is_owner: this.attr(),
            group_elevate_count: this.attr(),
            elevated: this.attr(),
            is_elevated: this.attr(),
            is_admin_post: this.attr(),
            is_admin_invited: this.attr(),
            is_admin_library: this.attr(),
            is_content_approved: this.attr(),
            is_post_approved_request: this.attr()
        }
    }

    static apiConfig = {
        actions: {
            async toggleElevation(id) {
                try {
                    return await this.post(`/groups/${id}/toggle_elevation`, {}, {dataKey: 'response'})
                }catch(response){
                    let message = `${Object.values(response.data.message)[0]}`
                    iziToast.error({ message: message, maxWidth: '700px' })
                    return;
                }
            }
        }
    }
}

// window.group = Group
export default Group