<template>
	<div :class="['upload-cover-scribe-component', {'complete': status==='COMPLETE' || image.initImageUrl}]">
		<input type="hidden" name="card[cover_image]" :value="image.id">
		<div class="content-wrapper" ref="wrapper">
			<div v-show="status === 'COMPLETE' || image.initImageUrl" class="displaying-image">
				<img v-if="image.croppedImageUrl" class="cropped-image" :src="image.croppedImageUrl" alt="Cropped Image">
				<img v-else-if="image.initImageUrl" class="cropped-image" :src="image.initImageUrl" alt="Init Image">

				<div class="control-panel">
					<div class="btn btn-danger btn-del"
						 v-on:click="closeCropperModal()">
						<i class="fa fa-trash"></i>
					</div>
				</div>
			</div>

			<div v-show="!showCropper && status !== 'COMPLETE' && !image.initImageUrl"
				 :class="['upload-dropzone', {'in-progress': !['INITIATED', 'COMPLETE'].includes(status), 'dragover': dragover}]"
				 @click="onClick"
				 @drop.prevent="onDrop"
				 @dragover.prevent="dragover = true"
				 @dragleave.prevent="dragover = false"
				 v-cloak>
				<input ref="fileInput"
					   @change="selectionProcessing($event)"
					   type="file"
					   accept=".jpeg, .jpg, .png">

				<div v-if="status === 'INITIATED'">
					<div class="upload-image-title">
						<div class="bold-text">Drag your image here or click to add one</div>
						<small class="">The max file size: {{ maxSize/1024/1024 }} MB</small>
					</div>
				</div>
				<div v-else-if="status==='CHECKING'">Checking...</div>
				<div v-else-if="status==='UPLOADING'">{{image.upload_percentage}}%</div>
				<div v-else-if="['COMPLETE'].includes(status)">Complete</div>
			</div>

			<vue-cropper
					class="vue-cropper"
					v-if="showCropper"
					ref="cropper"
					:src="image.url"
					alt="Source Image"
					:viewMode="3"
					:autoCropArea="1"
					:cropBoxResizable="false"
					:cropBoxMovable="false"
					:guides="false"
					:aspectRatio="3/2"
					dragMode="move"
					:minCropBoxWidth="minCropBoxWidth"
			>
			</vue-cropper>

			<div v-if="showCropper && ['CHECKING', 'INITIATED'].includes(status)" class="control-panel">
				<div class="btn btn-default" @click.prevent="cropper.relativeZoom(0.1)"><i class="fa fa-search-plus"></i></div>
				<div class="btn btn-default" @click.prevent="cropper.zoomTo(1)"><i class="fa fa-arrows-alt"></i></div>
				<div class="btn btn-default" @click.prevent="cropper.relativeZoom(-0.1)"><i class="fa fa-search-minus"></i></div>
				<div class="btn btn-default" @click.prevent="cropper.rotate(-45)"><i class="fa fa-undo-alt"></i></div>
				<div class="btn btn-default" @click.prevent="cropper.rotate(45)"><i class="fa fa-redo-alt"></i></div>
				<div class="btn btn-danger"  @click.prevent="closeCropperModal()"><i class="fa fa-times"></i></div>
				<div class="btn btn-success upload" @click.stop.prevent="done()"><i class="fa fa-check"></i></div>
			</div>
			<div v-else-if="showCropper && ['UPLOADING', 'UPLOADED'].includes(status)" class="control-panel upload-status">
				<div class="btn btn-default uploading">
					<div v-if="status==='UPLOADING'">{{image.upload_percentage}}%</div>
					<div v-else>Saving...</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueCropper from "vue-cropperjs";
	import "cropperjs/dist/cropper.css";

	export default {
		components: { VueCropper },
		props: ['uid', 'image_id', 'initImageUrl'],
		data() {
			return {
				dragover: false,
				showCropper: false,
				maxSize: 10 * 1024 *1024,
				cropper: null,
			}
		},
		watch: {
			status: function(ov, nv){ this.switchCropper() },
			showCropper: function(ov, nv){
				setTimeout(() => this.cropper = this.$refs.cropper)
			}
		},
		computed: {
			image: function () {
				return this.$store.getters['Covers/getImageByUid'](this.uid)
			},
			status: function() {
				return (this.image || {}).status
			},
			processing_errors: function () {
				return this.image.processing_errors
			},
			minCropBoxWidth: function () {
				return this.$refs.wrapper.offsetWidth;
			},
		},
		methods: {
			async selectionProcessing(event) {
				let file = event.target.files[0];
				if (typeof FileReader !== 'function') {
					alert('Sorry, FileReader API not supported');
				} else if (file) {
					const {uid, maxSize} = this

					if (maxSize && file.size > maxSize) {
						this.$store.commit('Covers/UPDATE', {
							uid,
							processing_errors: {too_big_file: ['Please select a file not exceeding 10 Mb']}
						})
					} else {
						this.$store.commit('Covers/PARSE_FILE', {uid, file, maxSize});
						const reader = new FileReader();
						reader.onload = (event) => {
							this.imgSrc = event.target.result;
							this.$refs.cropper.replace(event.target.result);
						};
						reader.readAsDataURL(file);
						this.showCropper = true
					}
				}
			},
			onClick() {
				if(["INITIATED", "COMPLETE"].includes(this.status)){
					this.$refs.fileInput.click()
				}
			},
			onDrop(e) {
				if(["INITIATED", "COMPLETE"].includes(this.status)){
					let {uid} = this,
							droppedFiles = e.dataTransfer.files;
					this.dragover = false;
					if (!droppedFiles) return;
					if (droppedFiles.length > 1) {
						this.$store.commit('Covers/UPDATE', {
							uid,
							processing_errors: {too_many_files: ['Select please only one file']}
						})
					} else {
						this.$refs.fileInput.files = droppedFiles
						this.$refs.fileInput.dispatchEvent(new Event("change"))
					}
				}
			},
			changeImage(){
				this.$refs.fileInput.click()
			},
			async done(){
				let {uid} = this
				if(this.status !== 'PROCESSING'){
					this.$store.commit('Covers/UPDATE', { uid, status: "PROCESSING"})
					let croppedImageUrl = this.$refs.cropper.getCroppedCanvas().toDataURL()
					let croppData 	    = this.$refs.cropper.getData()
					this.$refs.cropper.getCroppedCanvas({fillColor: '#FFFFFF'}).toBlob(async (blob) => {
						try {
							blob.lastModifiedDate = new Date()
							blob.name = this.image.origin_name
							this.$store.commit('Covers/UPDATE', { uid, croppedImageUrl, croppData, croppedImageFile: blob})
							this.$store.commit('Covers/PARSE_FILE', {uid, file: blob, status: 'PROCESSING'})
							await this.$store.dispatch('Covers/create', {uid})
							await this.$store.dispatch('Covers/upload', {uid})
							await this.$store.dispatch('Covers/update', {uid})
						} catch (error) {
							console.log(error)
							this.$store.commit('Covers/UPDATE', { uid, status: "ERROR"})
						}
						this.showCropper = false;

						var event = new CustomEvent('uploaded-cover-scribe', { detail: {status: this.image.status } });
						window.dispatchEvent(event);

					})
				}
			},
			closeCropperModal(){
				let {uid} = this
				this.showCropper = false
				this.$store.commit('Covers/UPDATE', { uid, initImageUrl: ''})

				this.$refs.fileInput.value = "";
				this.$store.commit('Covers/CLEAR', {uid})
			},
			switchCropper(){
				let {status} = this.image;
				if (this.cropper) {
					['INITIATED', 'CHECKING', 'ERROR'].includes(status) ? this.cropper.enable() : this.cropper.disable()
				}
			}
		},
		created() {
			let {uid, image_id, initImageUrl} = this
			if (this.image){
				this.$store.commit('Covers/CLEAR', {uid})
			}else{
				this.$store.commit('Covers/ADD', {uid})
				this.$store.commit('Covers/UPDATE', { uid, initImageUrl, id: image_id})
			}
		}
	}
</script>

<style lang="scss">
	.upload-cover-scribe-component {
		position: relative;
		padding-top: 66.6666%;
		&.complete .content-wrapper {
			outline: 1px solid lightgray;
		}
		.content-wrapper{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			.vue-cropper {
				height: 100%;
				overflow: hidden;
				border: none;
				.cropper-view-box {
					outline: none;
				}
			}
			.control-panel {
				position: absolute;
				top: 10px;
				right: 10px;
				z-index: 1;
				opacity: 0.5;
				transition: opacity ease-out 100ms;
				&:hover {
					opacity: 0.95;
				}
				.btn-default .fa {
					text-shadow: 0 0 10px white;
				}
				&.upload-status {
					opacity: 1;
					text-shadow: 0 0 10px white;
					.btn-default {
						background: rgba(255, 255, 255, 0.20);
					}
				}
			}
		}

		.displaying-image{
			img {
				width: 100%;
			}
		}

		.upload-dropzone{
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 5px;
			height: 100%;
			width: 100%;
			border: 1px #d1d3d4 dashed;
			background-color: #f8f8f9;
			cursor: pointer;
			z-index: 1;
			&.in-progress{
				cursor: progress;
			}
			&:hover {
				border-color: #8f969d;
				.upload-image-title {
					color: #808285;
				}
			}
			&.dragover {
				cursor: copy;
				border-color: #8f969d;
				.upload-image-title {
					color: #808285;
				}
			}
			.upload-image-title {
				font-family: Montserrat, sans-serif;
				font-size: 30px;
				color: #a7a9ac;
				text-align: center;
				.bold-text {
					margin-bottom: 10px;
				}
			}
			input[type='file'] {
				display: none;
			}
		}

	}
</style>