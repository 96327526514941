<template>
    <div class="subscriptions-settings-component">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="subscriptions.length > 0">
                        <div class="row bold sub-header">
                            <div class="col-md-2">Date</div>
                            <div class="col-md-1">Interval</div>
                            <div class="col-md-2">Plan</div>
                            <div class="col-md-1">Amount</div>
                            <div class="col-md-2">Status</div>
                            <div class="col-md-2">Current Period</div>
                            <div class="col-md-2">Actions</div>
                        </div>
                        <div v-for="sub in subscriptions" :key="sub.id" class="row">
                            <div class="col-md-2">{{format_date(sub.current_period_start)}}</div>
                            <div class="col-md-1">{{sub.price.interval}}</div>
                            <div class="col-md-2">{{format_plan(sub.price.lookup_key)}}</div>
                            <div class="col-md-1">{{format_price(sub.price.unit_amount)}}</div>
                            <div class="col-md-2">{{format_status(sub)}}</div>
                            <div class="col-md-2">{{format_date(sub.current_period_end)}}</div>
                            <div class="col-md-2">
                                <span v-if="updating">Updating...</span>
                                <a href="#"
                                   v-if="canBeCancelled(sub) && !updating"
                                   @click.prevent="updateSubscription(sub.id, {cancel_at_period_end: true})">
                                    Cancel
                                </a>
                                <a href="#"
                                   v-if="canBeRenewed(sub) && !updating"
                                   @click.prevent="updateSubscription(sub.id, {cancel_at_period_end: false})">
                                    Renew
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="subscriptions.length == 0 && !loading" class="text-center">
                        Currently, there is no active <a href="/subscriptions" class="sub-link">Plus level
                        subscription</a>
                    </div>
                    <div v-if="loading" class="text-center">
                        Getting subscriptions...
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data: function () {
            return {
                subscriptions: [],
                loading: false,
                updating: false
            }
        },
        methods: {
            getMySubscriptions: function () {
                if(this.loading){return}
                this.loading = true
                axios.get('/stripe/subscriptions').then((response) => {
                    this.subscriptions = response.data.response
                }).catch(function (error) {
                    iziToast.error({title: 'Error', message: error})
                }).then(() => {
                    this.loading = false
                })
            },
            async updateSubscription(sub_id, params) {
                if (this.updating) {
                    return
                }
                this.updating = true
                axios.put(`/stripe/subscriptions/${sub_id}`, {
                    subscription: params
                }).then((response) => {
                    let subIndex = this.subscriptions.findIndex(sub => sub.id == response.data.response.id)
                    Object.assign(this.subscriptions[subIndex], response.data.response)
                    if (this.subscriptions[subIndex].cancel_at_period_end) {
                        iziToast.success({
                            title: 'Success',
                            message: "Your PLUS Subscription has been successfully cancelled"
                        })
                    } else {
                        iziToast.success({
                            title: 'Success',
                            message: 'Your PLUS Subscription has been successfully renewed'
                        })
                    }
                }).catch((error) => {
                    iziToast.error({title: 'Error', message: error})
                }).then(() => {
                    this.updating = false
                })
            },
            format_date: function (timestamp) {
                let d = new Date(timestamp * 1000)
                return d.getMonth() + 1 + "/" + (d.getDate()) + "/" + d.getFullYear()
            },
            format_price: function (cents) {
                let dollars = cents / 100
                return dollars.toLocaleString("en-US", {style: "currency", currency: "USD"})
            },
            format_plan: function (lookup_key) {
                let plan = lookup_key
                switch (lookup_key) {
                    case "month":
                        plan = "Monthly PLUS";
                        break;
                    case "year":
                        plan = "Yearly PLUS";
                        break;
                }
                return plan
            },
            format_status: function(sub) {
                if (sub.status === 'active' && sub.cancel_at_period_end){
                    return `${sub.status} till end of current period`
                }else{
                    return sub.status
                }
            },
            canBeCancelled: function (sub) {
                return sub.cancel_at_period_end === false && sub.status == 'active'
            },
            canBeRenewed: function (sub) {
                return sub.cancel_at_period_end === true && sub.status == 'active'
            }
        },
        created() {
            this.getMySubscriptions()
        }
    }
</script>

<style lang="scss" scoped>
    .sub-header {
        font-weight: bold;
        padding: 15px 0;
    }

    a {
        font-weight: bold;
        color: #4b419a;
    }
</style>