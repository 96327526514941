// base
import Vue from 'vue/dist/vue.esm'
import router from '../app/routes.js'
import store from '../app/store/store.js'
import i18n from '../app/i18n.js'
import App from '../app/App.vue'
import plugins from '../app/plugins/'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import moment from 'moment'
import '@utils/string'

// import InfiniteLoading from 'vue-infinite-loading'

// window.Vue = Vue
// window.InfiniteLoading = InfiniteLoading
// TOOLTIP LIBRARY
// https://github.com/Akryum/v-tooltip#style-examples

// INFINITI SCROLL LOADER
// https://peachscript.github.io/vue-infinite-loading/guide/configure-load-msg.html#via-component-prop

//lib for modal window
import VModal from 'vue-js-modal'

//import css core
import '../app/assets/styles/core.scss'

import * as directives from '../app/directives/index'

// require('../app/old_assets/scripts/share_modal/shared_modal.js')

const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_key || '6Lcl06oZAAAAAB3XNZ-KpLPzywV5Inw2hxs-DMIi';

//detect device
const getDeviceType = () => {
  let device = {
    isMobile: false,
    isDesktop: false,
    isTablet: false
  }
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    device.isTablet = true
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    device.isMobile = true
  }
  device.isDesktop = !device.isTablet && !device.isMobile
  return device
}

Vue.prototype.showAuthBtn = process.env.SHOW_AUTH_BTN
Vue.prototype.$device = getDeviceType()
Vue.prototype.moment = moment

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import VueCarousel from '@chenfengyuan/vue-carousel';
Vue.use(VueCarousel);

import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
Vue.prototype.$toast = new VueIziToast()

import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import vuescroll from 'vuescroll';
Vue.use(vuescroll, {
  ops: {
    vuescroll: {},
    scrollPanel: {
      maxHeight: 500
    },
    rail: {
      background: '#c1c1c1',
      opacity: 0.1,
      size: '10px',
    },
    bar: {
      showDelay: 1000,
      background: '#c1c1c1',
      size: '8px',
    }
    // The global config
  },
  name: 'vueScroll' // customize component name, default -> vueScroll
});

import 'emoji-mart-vue-fast/css/emoji-mart.css'
import data from 'emoji-mart-vue-fast/data/facebook.json'
import { EmojiIndex } from 'emoji-mart-vue-fast'
Vue.prototype.$emojiIndex = new EmojiIndex(data)

// Vue.use(InfiniteLoading);
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

Vue.use(plugins)
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.use(directives)
Vue.use(VueReCaptcha, {
  siteKey: recaptchaSiteKey,
  loaderOptions: {
    useRecaptchaNet: true
  }
})
Vue.use(VModal,{dynamic: true, injectModalsContainer: true  })

// init app
const event = (typeof Turbolinks == 'object' && Turbolinks.supported) ? 'turbolinks:load' : 'DOMContentLoaded'
document.addEventListener(event, () => {

  new Vue({
    router: router,
    store: store,
    i18n: i18n,
    render: h => h(App)
  }).$mount('[data-behavior="vue"]')
})

///////////////////////////////////// video_pack

import video_pack_store from '../mix/store/store'
import CompWrapper from "../mix/CompWrapper"
import Bowser from "bowser";
Vue.prototype.$browser = window.browser = Bowser
document.dispatchEvent(new Event('init-browser'))

window.initVue2Media = (selector='#vue-2-media') => {
  if ( !$(selector).hasClass( "v2-initiated" ) ) {
    $(selector).addClass( "v2-initiated" )
    const app = new Vue({
      el: selector,
      store: video_pack_store,
      components: {CompWrapper},
    });
  }
};
// document.addEventListener(event, () => initVue2Media('#video_pack'));
window.config = {DEFAULT_IMAGE: process.env.DEFAULT_IMAGE }