<template>
<div class="follow-btn-component FollowBtn">
    <button class="btn btn__round btn__bordered"
            :class="{'active': user.is_following }"
            :disabled="loading"
            @click="follow"
            @mouseover="mouseover = true"
            @mouseleave="mouseover = false">
        <div v-if="loading" class="spinner-border" />
        <i v-else-if="!user.is_following" class="icon-follow">
            <span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" />
        </i>
        <img v-else-if="!mouseover" src="@assets/imgs/follow-confirmed.svg">
        <span>{{ btnText }}</span>
    </button>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import { userFollowToggle } from "@api/api.friends";

    export default {
        name: "FollowBtn",
        props: {
            user: {type: Object, default: () => {}},
            short: {type: Boolean, default: false},
        },
        data() {
            return {
                loading: false,
                mouseover: false,
            }
        },
        computed: {
            ...mapGetters('auth', {
                currentUser: 'getCurrentUser'
            }),
            btnText() {
                if (this.user.is_following && this.mouseover) {
                    return 'Unfollow'
                } else {
                    return this.short ? '' : (this.user.is_following ? 'Following' : 'Follow')
                }
            }
        },
        methods: {
            async follow() {
                this.loading = true
                const response = (await userFollowToggle({ user_id: this.user.id })).data.response
                this.loading = false
                const { is_following } = response
                this.$emit('is_following', is_following)
            },
        }
    }
</script>

<style lang="scss">
.follow-btn-component {
    font-size: 26px;
    .spinner-border {
        opacity: 0.4;
    }
}
</style>

<style lang="scss" scoped>
    .follow-btn-component {

        .btn {
            white-space: nowrap;
            padding: 0 1.5rem;
            &.active {
                //min-width: 140px;
                &:hover {
                    background: #e4e2f1;
                    border-color: #e4e2f1;
                    color: #4b419a;
                }
            }
            & > * {
                vertical-align: middle;
                line-height: 26px;
            }
            .icon-follow {
                font-size: 2.6rem;
            }
            img {
                max-width: 30px;
            }
        }

        .spinner-border {
            opacity: 0.4;
        }
    }
</style>