import {
    getDiscoveryList,
    getDiscoverTopics,
    searchTopics,
    createTopic,
    setTopics,
    groupFollow,
    groupJoin,
    groupUnjoin,
    userFollow,
    userUnfollow,
    userLike,
    contentLike
} from '../../api/api.discover'

import helper from '@utils/helper'
import Group from '@models/Group'

const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el.id === val ? [...acc, i] : acc), []);

const getDefaultState = () => {
    return {
        pagination: {
            timestamp: Date.now(),
            page: 1,
            limit: 12,
            selected_topics: [],
            domain: process.env.API_CLIENT
        },
        activeFilters: [],
        unactiveFilters: [],
        searched_filters: [],
        list: [],
        hide_loader: false,
        emptyDiscover: false,
    }
}

const discover = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        discoverList: state => state.list,
        getActiveFilters: state => state.activeFilters.filter(f => f),
        getUnactiveFilters: state => state.unactiveFilters.filter(f => f),
        getSearchedFilters: state => state.searched_filters,
        isNeedUpdate: state => state.need_update,
        hideLoader: state => state.hide_loader,
        emptyDiscover: state => state.emptyDiscover
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        HIDE_LOADER(state, value) {
            state.hide_loader = value
        },
        UPDATE_LIST(state, data) {
            if (data.length) {
                const newArray = [...state.list, ...data]
                const filteredArray = helper.removeDuplicates(newArray, 'id')
                state.list = filteredArray
            }
            if (!data.length || data.length < state.pagination.limit) {
                state.hide_loader = true
            }
        },
        RESET_DISCOVER_LIST(state) {
            state.list = []
        },
        UPDATE_PAGINATION(state) {
            state.pagination.page += 1
        },
        RESET_PAGINATION(state) {
            state.pagination.page = 1
        },
        ADD_FILTER(state, filter) {
            if (!state.pagination.selected_topics.includes(filter)) {
                state.pagination.selected_topics.push(filter)
            }
        },
        REMOVE_SELECTED_TOPIC(state, filter) {
            if (state.pagination.selected_topics.includes(filter)) {
                state.pagination.selected_topics.push(filter)
            }
        },
        PUSH_FILTERS(state, filters) {
            const filterNames = filters.map(filter => filter.name)
            state.pagination.selected_topics = filterNames
        },
        CREATE_FILTER(state, filter) {
            var newFilter = state.searched_filters.find(item => item.name === filter)
            if (newFilter && !state.unactiveFilters.some(item => item.id === newFilter.id) && !state.activeFilters.some(item => item.id === newFilter.id)) {
                state.unactiveFilters.push(newFilter)
            }
        },
        SET_FILTER_ACTIVE(state, filter) {
            if (!state.activeFilters.includes(filter)) {
                let index = state.unactiveFilters.indexOf(filter)
                state.unactiveFilters.splice(index, 1)
                state.activeFilters.push(filter)
            }
        },
        PUSH_ACTIVE_FILTER(state, filter) {
            if (!state.unactiveFilters.some(item => item.id === filter.id) && !state.activeFilters.some(item => item.id === filter.id)) {
                state.activeFilters.push(filter)
            } 
        },
        ADD_ACTIVE_TOPICS(state, topics) {
            state.activeFilters = topics
        },
        ADD_INACTIVE_TOPICS(state, topics) {
            state.unactiveFilters = topics
        },
        PUSH_UNACTIVE_TOPIC(state, topic) {
            const filter = {
                id: topic.id,
                name: topic.name,
                is_active: 'inactive'
            }
            if (!state.unactiveFilters.some(item => item && item.name === filter.name) && !state.activeFilters.some(item => item && item.name === filter.name)) {
                state.unactiveFilters.push(filter)
            }
        },
        REMOVE_ACTIVE_FILTER(state, filter) {
            if (state.activeFilters.includes(filter)) {
                let index = state.activeFilters.indexOf(filter)
                state.activeFilters.splice(index, 1)
                state.unactiveFilters.push(filter)
            }
        },
        DELETE_FILTER(state, filter) {
            if (state.unactiveFilters.includes(filter)) {
                let index = state.unactiveFilters.indexOf(filter)
                state.unactiveFilters.splice(index, 1)
            }
            if (state.activeFilters.includes(filter)) {
                let index = state.activeFilters.indexOf(filter)
                state.activeFilters.splice(index, 1)
            }
        },
        UPDATE_SELECTED_TOPICS(state) {
            const topics = state.activeFilters.map(topic => topic.name)
            state.pagination.selected_topics = topics
        },
        UPDATE_SEARCHED_FILTERS(state, filters) {
            state.searched_filters = filters
        },
        UPDATE_FOLLOW_STATUS(state, data) {
            state.list[data.index].is_following = data.status
        },
        UPDATE_JOIN_STATUS(state, data) {
            state.list[data.index].is_joined = data.status
        },
        UPDATE_USER_FOLLOW_STATUS(state, data) {
            state.list[data.index].followed = data.status
        },
        UPDATE_FOLLOW_COUNT(state, data) {
            state.list[data.index].followed_count += data.value
        },
        UPDATE_GROUP_FOLLOW_COUNT(state, data) {
            state.list[data.index].followers_count += data.value
        },
        UPDATE_GROUP_MEMBER_COUNT(state, data) {
            state.list[data.index].members_count += data.value
        },
        SET_GROUP_LIKE (state, data) {
            state.list[data.index].elevates_count += data.value
            if (data.value === 1) {
                state.list[data.index].elevated = true
            } else {
                state.list[data.index].elevated = false
            }
        },
        SET_USER_LIKE (state, data) {
            state.list[data.index].trusteds_count += data.value
            if (data.value === 1) {
                state.list[data.index].trusted = true
            } else {
                state.list[data.index].trusted = false
            }
        },
        SET_CONTENT_LIKE (state, data) {
            if (state.list[data.index].class_name === 'Product') {
                state.list[data.index].trusted_count += data.value
                state.list[data.index].trusted = (data.value === 1)
            } else if (state.list[data.index].class_name === 'CardNetwork') {
                state.list[data.index].trusteds_count += data.value
                state.list[data.index].card_tally_info.trusted = (data.value === 1)
            }
        },
        SET_EMPTY_LIST(state, status) {
            state.emptyDiscover = status
        }
    },
    actions: {
        async LIKE_USER ( {commit, state}, payload) {
            const response = await userLike(payload)
            const indexes = indexOfAll(state.list, payload.user_id)
            indexes.forEach((index) => {
                if (state.list[index].trusted) {
                    commit ('SET_USER_LIKE', {index: index, value: -1})
                } else {
                    commit ('SET_USER_LIKE', {index: index, value: 1})
                }
            })
            return response
        },
        async LIKE_CONTENT ({commit, state}, payload) {
            const response = await contentLike(payload)
            const indexes = indexOfAll(state.list, payload.resource_id)
            if (response.data.status === 200) {
                indexes.forEach((index) => {
                    if (state.list[index].class_name === 'Product') {
                        if (state.list[index].trusted === true) {
                            commit ('SET_CONTENT_LIKE', {index: index, value: -1})
                        } else {
                            commit ('SET_CONTENT_LIKE', {index: index, value: 1})
                        }
                    } else if (state.list[index].class_name === 'CardNetwork') {
                        if ((state.list[index].card_tally_info || {}).trusted === true) {
                            commit ('SET_CONTENT_LIKE', {index: index, value: -1})
                        } else {
                            commit ('SET_CONTENT_LIKE', {index: index, value: 1})
                        }
                    }
                })
            }
            return response
        },
        async LIKE_GROUP ( {commit, state}, payload) {
            const response = await Group.api().toggleElevation(payload.group_id)
            if (response.response.data.status === 200) {
                const indexes = indexOfAll(state.list, payload.group_id)
                indexes.forEach((index) => {
                    commit ('SET_GROUP_LIKE', {index: index, value: 1})
                })
            }
            return response
        },
        async UNLIKE_GROUP ( {commit, state}, payload) {
            const response = await Group.api().toggleElevation(payload.group_id)
            if (response.response.data.status === 200) {
                const indexes = indexOfAll(state.list, payload.group_id)
                indexes.forEach((index) => {
                    commit ('SET_GROUP_LIKE', {index: index, value: -1})
                })
            }
            return response
        },
        async UNFOLLOW_USER({commit, state}, payload) {
            const response = await userUnfollow(payload)
            if (response.data.status === 200) {
                const index = state.list.findIndex(item => item.id === payload.user_id);
                commit('UPDATE_FOLLOW_COUNT', {index: index, value: -1})
                commit('UPDATE_USER_FOLLOW_STATUS', {index: index, status: false})
            }
        },
        async FOLLOW_USER({commit, state}, payload) {
            const response = await userFollow(payload)
            if (response.data.status === 200) {
                const index = state.list.findIndex(item => item.id === payload.user_id);
                commit('UPDATE_FOLLOW_COUNT', {index: index, value: 1})
                commit('UPDATE_USER_FOLLOW_STATUS', {index: index, status: true})
            }
        },
        async JOIN_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                group_id: payload.group_id,
                group_member: {
                    user_id: payload.user_id,
                    group_id: payload.group_id,
                    is_joined: true,
                    is_approved: true,
                    user_activity: {
                        activity: 'joined',
                        action_name: 'Joined'
                    }
                }
            }
            const response = await groupJoin(data)
            if (response.data.status === 200) {
                const index = state.list.findIndex(item => item.id === payload.group_id);
                commit('UPDATE_GROUP_MEMBER_COUNT', {index: index, value: 1})
                commit('UPDATE_JOIN_STATUS', {index: index, status: true})
            }
        },
        async UNJOIN_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                user_id: payload.user_id,
                group_id: payload.group_id,
                is_following: false,
                is_joined: false,
                user_activity: {
                    activity: 'un joined',
                    action_name: 'Un Join'
                }
            }
            const response = await groupUnjoin(data)
            if (response.data.status === 200) {
                const index = state.list.findIndex(item => item.id === payload.group_id);
                commit('UPDATE_GROUP_MEMBER_COUNT', {index: index, value: -1})
                if (state.list[index].is_following) {
                    commit('UPDATE_GROUP_FOLLOW_COUNT', {index: index, value: -1})
                }
                commit('UPDATE_JOIN_STATUS', {index: index, status: false})
                commit('UPDATE_FOLLOW_STATUS', {index: index, status: false})
                
            }

        },
        async FOLLOW_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                group_id: payload.group_id,
                group_member: {
                    user_id: payload.user_id,
                    group_id: payload.group_id,
                    is_following: true,
                    user_activity: {
                        activity: 'follow',
                        action_name: 'Follow'
                    }
                }
            }
            const response = await groupFollow(data)
            if (response.data.status === 200) {
                const index = state.list.findIndex(item => item.id === payload.group_id);
                commit('UPDATE_GROUP_FOLLOW_COUNT', {index: index, value: 1})
                commit('UPDATE_FOLLOW_STATUS', {index: index, status: true})
            }
        },
        async UNFOLLOW_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                group_id: payload.group_id,
                group_member: {
                    user_id: payload.user_id,
                    group_id: payload.group_id,
                    is_following: false,
                    user_activity: {
                        activity: 'unfollow',
                        action_name: 'Un Follow'
                    }
                }
            }
            const response = await groupFollow(data)
            if (response.data.status === 200) {
                const index = state.list.findIndex(item => item.id === payload.group_id);
                commit('UPDATE_GROUP_FOLLOW_COUNT', {index: index, value: -1})
                commit('UPDATE_FOLLOW_STATUS', {index: index, status: false})
            }

        },
        async GET_DISCOVER_LIST({state, commit}) {
            commit('HIDE_LOADER', false)
            const response = await getDiscoveryList(state.pagination)
            if (response.data.response) {
                commit('UPDATE_PAGINATION')
                commit('UPDATE_LIST', response.data.response)
                commit('SET_EMPTY_LIST', false)
            }
            if (response.data.response.length === 0 && state.list.length === 0) {
                commit('SET_EMPTY_LIST', true)
            }
            return response
        },
        ADD_FILTER({commit}, filter) {
            commit('ADD_FILTER', filter)
        },
        CREATE_FILTER({commit, dispatch}, filter) {
            dispatch('CREATE_FINDED_TOPIC', filter)
            // commit('CREATE_FILTER', filter)
            // dispatch('SET_TOPICS')
            // commit('UPDATE_SELECTED_TOPICS')
        },
        SET_FILTER_ACTIVE({commit, dispatch}, filter) {
            commit('SET_FILTER_ACTIVE', filter)
            dispatch('SET_TOPICS')
            commit('UPDATE_SELECTED_TOPICS')
            dispatch('UPDATE_LIST')
        },
        REMOVE_ACTIVE_FILTER({commit, dispatch}, filter) {
            commit('REMOVE_ACTIVE_FILTER', filter)
            dispatch('SET_TOPICS')
            commit('UPDATE_SELECTED_TOPICS')
            dispatch('UPDATE_LIST')
        },
        PUSH_UNACTIVE_TOPIC({commit}, filter) {
            commit('PUSH_UNACTIVE_TOPIC', filter)
            commit('UPDATE_SELECTED_TOPICS')
            dispatch('UPDATE_LIST')
        },
        DELETE_FILTER({commit, dispatch}, filter) {
            commit('DELETE_FILTER', filter)
            dispatch('SET_TOPICS')
            commit('UPDATE_SELECTED_TOPICS')
            dispatch('UPDATE_LIST')
        },
        async GET_USER_TOPICS({commit, dispatch}) {
            const data = {
                domain: process.env.API_CLIENT,
                topics: 'true',
                timestamp: Date.now()
            }
            const response = await getDiscoverTopics(data)
            const activeTopics = response.data.response.user_topics.filter((item) => {
                if (item.is_active === 'active') {
                    return item
                }
            })
            const inactiveTopics = response.data.response.user_topics.filter((item) => {
                if (item.is_active === 'inactive') {
                    return item
                }
            })
            commit('ADD_ACTIVE_TOPICS', activeTopics)
            commit('PUSH_FILTERS', activeTopics)
            commit('ADD_INACTIVE_TOPICS', inactiveTopics)
        },
        async SEARCH_TOPIC({commit}, keyword) {
            const data = {
                domain: process.env.API_CLIENT,
                key_word: keyword
            }
            const response = await searchTopics(data)
            commit('UPDATE_SEARCHED_FILTERS', response.data.response.items)
        },
        async CREATE_FINDED_TOPIC({commit, dispatch}, topic) {
            const data = {
                domain: process.env.API_CLIENT,
                topic_name: topic
            }
            const response = await createTopic(data)
            commit('PUSH_ACTIVE_FILTER', response.data.response)
            dispatch('SET_TOPICS')
            // commit('PUSH_UNACTIVE_TOPIC', response.data.response)
            dispatch('UPDATE_LIST')
            commit('UPDATE_SELECTED_TOPICS')
        },
        async SET_TOPICS({state, commit}) {
            const activeTopicIds = state.activeFilters.map(item => item.id)
            const unactiveTopicIds = state.unactiveFilters.map(item => item.id)
            const data = {
                domain: process.env.API_CLIENT,
                user_follow_ids: activeTopicIds,
                user_follow_inactive_ids: unactiveTopicIds
            }
            await setTopics(data)
        },
        async UPDATE_LIST({commit, dispatch}) {
            await dispatch('loader/LOADER', true, {root:true})
            commit('RESET_PAGINATION')
            commit('RESET_DISCOVER_LIST')
            commit('UPDATE_SELECTED_TOPICS')
            await dispatch('GET_DISCOVER_LIST')
            await dispatch('loader/LOADER', false, {root:true})
        }
    }
}

export default discover