<template>
<div class="add-links-component">
    <div class="text__center margin-b__20 modal-header">
        <i class="icon-arrow-back pointer" @click="$emit('close')" />
        <b>Add links</b>
    </div>

    <div class="add-links-body">
        <div class="add-links-form" :class="{'has-img': attachURLImage }">
            <CoverImg v-if="attachURLImage" :src="attachURLImage" />
            <input v-model="attachURL"
                   class="full__width"
                   type="text"
                   placeholder="Type or paste url here"
                   @blur="getInfoFromUrl">
            <button v-if="attachURL" class="btn__reset" @click.stop.prevent="addLink">
                <i v-if="parsingProgress && awaitParsingForAdd && !awaitParsingForSave" class="spinner-border" />
                <i v-else class="icon-add color__secondary_60" />
            </button>
        </div>

        <ParsedLinks
            :links="links"
            :editable="true"
            @deleteLink="deleteLink" />
    </div>

    <div class="modal-footer text__right">
        <button class="btn btn__m btn__round btn__bordered" :disabled="attachURLDisabled" @click.stop.prevent="saveLinksToPost">
            Add
            <i v-if="parsingProgress && awaitParsingForSave" class="spinner-border" />
        </button>
    </div>
</div>
</template>

<script>
import {mapActions} from "vuex";
import CoverImg from "@kit/CoverImg";
import ParsedLinks from './ParsedLinks'

export default {
    name: "AddLinks",
    components: {ParsedLinks, CoverImg},
    props: {
        url_hash: {type: Array, default: () => []},
    },
    data() {
        return {
            links: [],
            attachURL: '',
            attachURLInfio: {},
            parsingProgress: false,
            awaitParsingForAdd: false,
            awaitParsingForSave: false,
        }
    },

    computed: {
        attachURLImage() {
            return this.attachURLInfio.s3_img_src || this.attachURLInfio.img_src
        },
        isAttachURLValid() {
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/g.test(this.attachURL)
        },
        attachURLDisabled() {
            return !(this.links.length || this.attachURL)
        },
    },
    mounted() {
        if (this.url_hash.length) {
            this.setLinks()
        }
    },
    methods: {
        ...mapActions('embed_url_info', {
            parseLink: 'PARSE_LINK',
        }),
        async getInfoFromUrl() {
            if (this.attachURL && this.isAttachURLValid && !this.parsingProgress) {
                this.parsingProgress = true
                try {
                    this.attachURLInfio = await this.parseLink({url: this.attachURL})
                } catch (e) {
                    console.log('getInfoFromUrl', e)
                    this.attachURLInfio = {}
                }
                this.parsingProgress = false
                if (this.awaitParsingForAdd) {
                    this.awaitParsingForAdd = false
                    this.addLink()
                }
            } else {
                this.attachURLInfio = {}
            }
            return true
        },
        addLink() {
            if (this.isAttachURLValid) {
                if (this.parsingProgress) {
                    this.awaitParsingForAdd = true
                } else {
                    const newLink = {
                        url: this.attachURLInfio.url || this.attachURL,
                        title: this.attachURLInfio.title,
                        image: this.attachURLImage,
                    }
                    this.deleteLink(newLink)
                    this.links.unshift(newLink)
                    this.attachURL = ''
                    this.attachURLInfio = {}
                    if (this.awaitParsingForSave) {
                        this.awaitParsingForSave = false
                        this.saveLinksToPost()
                    }
                }
            } else {
                iziToast.error({
                    title: 'Error',
                    message: 'Enter a valid URL',
                });
            }
        },
        deleteLink(link) {
            let index = this.links.findIndex(item => item.url === link.url)
            if (index > -1) {
                this.links.splice(index, 1)
            }
        },
        saveLinksToPost() {
            if (this.attachURL) {
                this.awaitParsingForSave = true
                this.addLink()
            } else {
                this.$emit('changed', this.links);
                this.$emit('close')
            }
        },
        setLinks(links) {
            this.links = JSON.parse(JSON.stringify(links || this.url_hash))
        },
        close() {
            if (this.onClose) {
                this.onClose()
            }
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
.add-links-component {
    .add-links-form .CoverImg {
        max-width: 75px;
    }
}
</style>

<style lang="scss" scoped>
.add-links-component {
    .modal-header .icon-arrow-back {
        position: absolute;
        left: 20px;
        color: #4c4c4c;
        font-size: 14px;
        line-height: 30px;
    }
    .add-links-body {
        margin: 20px 0;
    }
    .add-links-form {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        input {
            border: none;
            border-bottom: 1px solid #d1d3d4;
            height: 3rem;
        }
        button {
            padding: 8px 10px;
        }
        &.has-img {
            input {
                padding-left: 15px;
            }
        }
    }
    .spinner-border {
        opacity: 0.4;
    }
    @media (max-width: 540px){
        .modal-footer > button {
            width: 100%;
        }
    }
}
</style>