import request from '@utils/request'

export function getStorageSize () {
    return request({
        url: `/storage`,
        method: 'get'
    })
}

export function getFilesInfo(filter = '', page = 1) {
    return request({
        url: `/storage/file_infos`,
        method: 'get',
        params: {
            filter: filter,
            page: page,
            per_page: 10
        }
    })
}