<template>
    <modal
        name="signup-form"
        :width="0">
        <div class="modal modal__signup show">
            <section v-click-outside="hide">
                <a class="modal__close">
                    <i
                        class="icon-close"
                        @click="hide" />
                </a>
                <div class="modal__head">
                    <img
                        src="@assets/imgs/Sphir_logo_trademark_WEB-HEADER.svg"
                        class="sphir__logo"
                        alt="">
                    <h2>{{ $t('SIGN_UP.TITLE') }}</h2>
                </div>
                <div class="modal__body">
                    <form @submit.prevent="submit">
                        <section>
                            <div class="input__double">
                                <InputField
                                    :validation="['required', 'textInput', 'minFirstName', 'maxString']"
                                    :meta="meta">
                                    <input
                                        id="first_name"
                                        v-model="form.first_name"
                                        type="text"
                                        name="first_name"
                                        placeholder=" "
                                        required="required">
                                    <label
                                        for="first_name"
                                        class="label">{{ $t('INPUT.FIRST_NAME') }}</label>
                                </InputField>
                                <InputField
                                    :validation="['required', 'textInput', 'minLastName', 'maxString']"
                                    :meta="meta">
                                    <input
                                        id="last_name"
                                        v-model="form.last_name"
                                        type="text"
                                        name="last_name"
                                        placeholder=" "
                                        required="required">
                                    <label
                                        for="last_name"
                                        class="label">{{ $t('INPUT.LAST_NAME') }}</label>
                                </InputField>
                            </div>
                            <InputField
                                :validation="['required', 'isEmail']"
                                :meta="meta">
                                <input
                                    id="email"
                                    v-model="form.uid"
                                    type="email"
                                    name="uid"
                                    placeholder=" "
                                    required="required">
                                <label
                                    for="email"
                                    class="label">{{ $t('INPUT.EMAIL') }}</label>
                            </InputField>
                            <div class="input__double">
                                <InputField
                                    :validation="['required', 'passwordSpace', 'isPasswordValid']"
                                    :validation-func="checkEqualField"
                                    :meta="meta">
                                    <input
                                        id="password"
                                        v-model="form.password"
                                        maxlength="100"
                                        type="password"
                                        autocomplete="off"
                                        name="password"
                                        placeholder=" "
                                        required="required">
                                    <label
                                        for="password"
                                        class="label">{{ $t('INPUT.PASS') }}</label>
                                </InputField>
                                <InputField
                                    :validation="['required']"
                                    :validation-func="isPasswordEqual"
                                    :meta="meta">
                                    <input
                                        id="confirm_password"
                                        v-model="form.confirm_password"
                                        type="password"
                                        autocomplete="off"
                                        name="confirm_password"
                                        placeholder=" "
                                        required="required">
                                    <label
                                        for="confirm_password"
                                        class="label">{{ $t('INPUT.CONF_PASS') }}</label>
                                </InputField>
                            </div>
                        </section>
                        <section class="notes">
                            <transition name="fade">
                                <div
                                    v-if="isYahooEmail"
                                    class="YahooUsers"
                                    v-html="$t('SIGN_UP.P_0')" />
                            </transition>
                            <div
                                class="BraveUsers"
                                v-html="$t('SIGN_UP.P_1')" />
                            <p>
                                <label class="choice checkbox">
                                    <input
                                        id=""
                                        v-model="check"
                                        value=""
                                        type="checkbox">
                                    <span>
                                        {{ $t('SIGN_UP.P_2') }}
                                        <router-link
                                            :to="{name: 'terms-of-use'}"
                                            target="_blank"
                                            class="text__bold color__secondary">
                                            {{
                                                $t('LINK.TERM-2') }}
                                        </router-link>
                                        and 
                                        <router-link
                                            :to="{name: 'privacy-policy'}"
                                            target="_blank"
                                            class="text__bold color__secondary">
                                            {{ $t('LINK.PRIVACY') }}
                                        </router-link>
                                    </span>
                                </label>
                            </p>
                        </section>
                        <section class="text__center">
                            <button
                                type="submit"
                                class="btn full__width btn__round"
                                :disabled="disabled"
                                @click="submit">
                                {{ message || $t('BUTTON.JOIN') }}
                            </button>
                        </section>
                    </form>
                </div>
                <div class="modal__footer text__center">
                    <div class="fs__12">
                        {{ $t('SIGN_UP.P_3') }} 
                        <a class="text__bold color__main" @click="showLogin">{{ $t('BUTTON.LOGIN') }}</a>
                    </div>
                    <!-- <div
                        v-show="localError"
                        class="error">
                        {{ localError }}
                    </div> -->
                </div>
            </section>
        </div>
    </modal>
</template>
        
<script>
    import {mapActions, mapGetters} from 'vuex'
    import InputField from '@components/InputField'
    import helper from '@utils/helper'

    export default {
        name: 'SignupForm',
        components: {
            InputField
        },
        data() {
            return {
                message: '',
                loading: false,
                form: {
                    first_name: '',
                    last_name: '',
                    uid: '',
                    password: '',
                    confirm_password: '',
                    check: false
                },
                meta: {
                    form: 'sign_up',
                    events: ['keyup', 'blur']
                }
            }
        },
        computed: {
            ...mapGetters('errors', {
                isFormHasError: 'isFormHasError'
            }),
            disabled() {
                const isEmpty = helper.isObjectEmpty(this.form)
                const hasError = this.isFormHasError('sign_up')
                const check = this.form.check
                return hasError || isEmpty || check || this.loading
            },
            isYahooEmail() {
                return (/^[^@]+@yahoo\./i).test(this.form.uid)
            }
        },
        methods: {
            ...mapActions('errors', {
                setError: 'SET_ERROR'
            }),
            ...mapActions('auth', {
                register: 'SIGN_UP'
            }),
            * checkEqualField() {
                yield this.form.confirm_password !== '' && this.form.confirm_password !== this.form.password ? this.setError({
                    form: this.meta.form,
                    field: 'confirm_password',
                    message: 'VALIDATION.PASS:NOT_EQUAL'
                }) : this.setError({form: this.meta.form, field: 'confirm_password', message: ''})
            },
            * isPasswordEqual() {
                yield this.form.confirm_password !== this.form.password ? 'VALIDATION.PASS:NOT_EQUAL' : true
            },
            hide() {
                this.localError = ''
                this.$modal.hide('signup-form')
            },
            showLogin() {
                this.$modal.hide('signup-form')
                this.$modal.show('auth-form');
            },
            async submit(e) {
                e.preventDefault();
                e.stopPropagation();
                this.message = this.$t('BUTTON.LOAD')
                this.loading = true
                let data = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    uid: this.form.uid.trim(),
                    password: this.form.password,
                    confirm_password: this.form.confirm_password,
                    confirm_url: `${window.location.origin}/users/confirmation`
                }
                let response = await this.register(data)
                this.message = ''
                this.loading = false
                if (response.status === 200) {
                    this.$modal.show('thanks-form')
                }
            }
        }
    }
</script>
    
<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: all .7s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
        height: 0;
        padding: 0;
    }

    .modal__signup {
        section {
            width: 80rem;
            padding: 0rem;
        }
        .modal__head {
            text-align: center;
            padding-top: 4rem;

            .sphir__logo {
                max-width: 19rem;
            }

        }

        .modal__body {
            padding: 2rem 4rem;

            section {
                padding: 2rem;
                margin: 0vh auto;
                width: 90rem;
                max-width: 100%;

                button {
                    max-width: 40rem;
                }
            }

            .input__field {
                input {
                    border-width: 0.1rem;
                    border-radius: 10px;
                    margin-bottom: 1.5rem;
                    padding: 1.5rem;
                }

                input:focus+label, input:not(:placeholder-shown)+label {
                    transform: translateY(-100%) scale(.75);
                    top: 0rem;
                    left: 1rem;
                }

                label {
                    top: 1.5rem;
                    left: 1.5rem;
                }

                span {
                    margin-top: -12px;
                    margin-left: 10px;
                }
            }

            .checkbox>span:after {
                top: 0.4rem
            }
        }

        .modal__footer {
            background: #D9D9D950;
            min-height: 5rem;
            padding: 30px 20px;
        }
    }

    @media (max-width: 640px) {
    .modal__signup .modal__body {
        section {
            padding: 0rem;
        }
    }
}
</style>