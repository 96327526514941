<template>
<div class="edit-attach-item-component">
    <ImagePreview v-if="isImage" :item="item" @delete="$emit('delete')" />
    <ItemPreview v-else :item="item" @delete="$emit('delete')" />
</div>
</template>

<script>
import ImagePreview from "./ImagePreview";
import ItemPreview from "./ItemPreview";
    export default {
        components: { ImagePreview, ItemPreview },
        props: {
            item: { type: Object }
        },
        computed: {
            isImage() {
                return this.item.class_name === "Image"
            }
        },
    }
</script>


