<template>
    <div class="theo-player-component">
        <div ref="theoplayer"
             class="theoplayer-container video-js theoplayer-skin vjs-16-9"
             :class="[{ 'sources-unavailable': isUnavailable }]"
             v-show="canDisplay"
             v-if="playable"
        ></div>
        <div class="theo-unable" v-else>
            {{$browser.name}} play will be available soon. Please use Safari to play this content.
        </div>
    </div>
</template>

<script>
    import {mapDynamicFields} from "./helpers/mapDynamicFields"

    export default {
        props: ['kind', 'with_theatrical_mode'],
        data: function () {
            return {
                main_spath: `Videos.uploads.main`,
                sources: [],
                player: null,
                textTracks: [],
            }
        },
        computed: {
            spath: function() { return `Videos.uploads.${this.kind}` },
            ...mapDynamicFields('spath', {
                status: 'api_state.status',
                hls_sources: 'api_state.hls_sources',
                dash_sources: 'api_state.dash_sources',
                digital_access: 'api_state.digital_access',
                token: 'api_state.token'
            }),
            ...mapDynamicFields('main_spath', {
                poster_index: 'api_state.poster_index',
                posters_list: 'api_state.posters_list',
                v_poster: 'api_state.poster',
                main_video_id: 'api_state.id'
            }),
            poster: function() {
                let poster = (this.posters_list && this.poster_index ? { ...this.posters_list[this.poster_index] }.lg : this.v_poster.lg);
                return poster || 'https://prd-assets.sphir.io/assets/default.jpg';
            },
            canDisplay: function () {
                const { player, status, sources, poster } = this;
                return !!player && !!(sources || poster) && status === 'COMPLETE'
            },
            isUnavailable: function() {
                return !(this.hls_sources || this.dash_sources)
            },
            current_user_id: function(){
                return this.$store.getters['getField']('current_user_id')
            },
            licence_url_prefix: function(){
                if (this.digital_access == 'private'){
                    return this.$environment !== 'production' ? "staging." : ""
                }else{
                    return ''
                }
            },
            merchant: function(){
                return this.$environment !== 'production' ? 'principlesoft' : 'supirb'
            },
            playready_url: function(){
                return `https://lic.${this.licence_url_prefix}drmtoday.com/license-proxy-headerauth/drmtoday/RightsManager.asmx`
            },
            widevine_url: function(){
                return `https://lic.${this.licence_url_prefix}drmtoday.com/license-proxy-widevine/cenc/`
            },
            fairplay_url: function(){
                return `https://lic.${this.licence_url_prefix}drmtoday.com/license-server-fairplay/`
            },
            fairplay_cert_url: function(){
                return `https://lic.${this.licence_url_prefix}drmtoday.com/license-server-fairplay/cert/`
            },
            playable: function(){
                // reverse condition when not playable - only on iOS except Safari - other cases are playable
                return !(this.$browser.osname === 'iOS' && this.$browser.name !== 'Safari')
                // return true //!(this.$browser.name == 'Safari' && this.digital_access == 'private')
            },
            subtitles: function () {
                return this.$store.state.Subtitles.list
            },
        },
        watch: {
            isUnavailable: function (nv, ov) { this.setSource() },
            poster: function (nv, ov) { this.setSource() },
            kind: function (nv, ov) { this.setSource() },
            subtitles: {
                handler: function (nv, ov) {
                    const valid_subtitles = this.subtitles
                        .filter(s => (s.holder_id === this.main_video_id && !!s.srclang && !!s.origin_s3_path))
                        .map(s => ({kind: s.kind, label: s.label, srclang: s.srclang, src: s.origin_s3_path}));
                    if (this.textTracks.map(s => `${s.label}${s.src}`).join() !== valid_subtitles.map(s => `${s.label}${s.src}`).join()) {
                        this.textTracks = valid_subtitles
                    }
                }, deep:true
            },
            textTracks: function (nv, ov) { this.setSource() }
        },
        methods: {
            playerInit() {
                const theo_lib_key = document.getElementById('THEOPLAYER_LIB_KEY').dataset.key;
                const params = {
                    libraryLocation: `https://cdn.myth.theoplayer.com/${theo_lib_key}`,
                    // fluid: true,
                };
                if (!this.with_theatrical_mode) { params.initialRendition = 'first' }
                this.player = new window.THEOplayer.Player(this.$refs.theoplayer, params);
                this.setSource()
            },
            setSource() {
                const { canDisplay, player, poster, hls_sources, dash_sources } = this;
                if (this.digital_access == 'private') {
                    if(this.$browser.name == 'Safari' || this.$browser.osname == 'iOS'){
                        // this.sources = [{
                        //         "src": hls_sources[0],
                        //         "type": "application/x-mpegurl",
                        //         "contentProtection": {
                        //             "integration": "drmtoday",
                        //             "merchant": this.merchant,
                        //             "sessionId": this.token,
                        //             "userId": this.current_user_id,
                        //             "fairplay": {
                        //                 "licenseAcquisitionURL": this.fairplay_url,
                        //                 "certificateURL": this.fairplay_cert_url
                        //             }
                        //         }
                        //     }]
                        this.sources = hls_sources
                    }else{
                        this.sources = [{
                                "src": dash_sources[0],
                                "type": "application/dash+xml",
                                "contentProtection": {
                                    "integration": "drmtoday",
                                    "merchant": this.merchant,
                                    "sessionId": this.token,
                                    "userId": this.current_user_id,
                                    "playready": { "licenseAcquisitionURL": this.playready_url },
                                    "widevine": { "licenseAcquisitionURL": this.widevine_url }
                                }
                            }]
                    }
                }else{
                    this.sources = hls_sources
                }
                if (canDisplay) {
                    const { sources, textTracks } = this;
                    player.source = { sources, poster, textTracks }
                }
            }
        },
        mounted() {
            this.playerInit()
        }
    }
</script>
<style lang="scss" scoped>
    .theoplayer-container {
        &.sources-unavailable .vjs-big-play-button{
            display: none;
        }
    }
    .theo-unable{
        background-color: #f8f8f9;
        width: 100%;
        min-height: 240px;
        display: flex;
        align-items: center;
        text-align: center;
    }
</style>
