<template>
<modal
    name="delete-photo-modal"
    :width="0"
    @before-open="beforeOpen"
    @closed="closed">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section>
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <div class="text__center margin-b__20">
                <b>Are you sure you want to delete {{ count }} selected photo{{ count > 1 ? 's' : '' }}?</b>
            </div>
            <div v-if="!loading" class="text__center">
                <button
                    class="btn__reset margin-r__10"
                    @click="hide">
                    {{ $t('BUTTON.NO') }}
                </button>
                <button
                    class="btn btn__m btn__round"
                    @click="deletePhotos">
                    {{ $t('BUTTON.YES') }}
                </button>
            </div>
            <div v-else-if="errorMessage" class="text__center">
                {{ errorMessage }}
            </div>
            <div v-else class="text__center">
                Processing delete {{ count }} photos{{ count > 1 ? 's' : '' }}
                <i class="spinner-border" />
            </div>
        </section>
    </div>
</modal>
</template>

<script>
import Photo from '@models/Photo'

    export default {
        name: 'DeletePhotoModal',
        data() {
            return {
                photos: [],
                loading: false,
                requests: [],
                errorMessage: '',
            }
        },
        computed: {
            count() {
                return this.photos.length || 0
            },
        },
        methods: {
            // @vuese
            // Method to hide DeleteReply modal
            hide() {
                this.$modal.hide('delete-photo-modal')
            },
            async deletePhotos() {
                this.loading = true
                for (const photo of this.photos) {
                    this.requests.push(Photo.api().destroy(photo.id))
                }
                Promise.all(this.requests).then(this.hide, e => {
                    this.errorMessage = 'Some photos have not been deleted'
                });
            },
            
            // @vuese
            // Method that call before modal open and set to local data reply
            // @arg The argument is modal-open event with param reply [object]
            beforeOpen(event) {
                if (event && event.params && event.params.photos) {
                    this.photos = event.params.photos
                }
            },
            closed() {
                this.photos = []
                this.loading = false
                this.requests = []
                this.errorMessage = ''
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal__sphir_Online_Access_Agreement {
        section {
            width: 34rem;
            .spinner-border {
                opacity: 0.4;
            }
        }
    }
</style>