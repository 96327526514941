<template>
<modal
    name="disagree-form"
    :width="0">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <div class="modal__head">
                <h3>
                    {{ $t('JOIN.TITLE') }}
                </h3>
            </div>
            <div class="modal__body">
                {{ $t('JOIN.P_10') }}
            </div>
            <div class="modal__footer text__center margin-t__40">
                <button
                    class="btn btn__bordered btn__round"
                    @click="returnTo">
                    {{ $t('BUTTON.RETURN') }}
                </button>
                <button
                    class="btn btn__bordered btn__round"
                    @click="view">
                    {{ $t('BUTTON.VIEW_CONT') }}
                </button>
            </div>
        </section>
    </div>
</modal>
</template>

<script>
    export default {
        name: 'DisagreeModal',
        methods: {
            hide() {
                this.$modal.hide('disagree-form')
            },
            returnTo() {
                this.hide()
                this.$modal.show('join-form')
            },
            view() {
                location.href = '/discover'
            }
        }

    }
</script>