<template>
<modal
    name="delete-comment"
    :width="0"
    @before-open="beforeOpen">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <div class="text__center margin-b__20">
                <b>{{ $t('DELETE_COMMENT_MODAL.TITLE') }}</b>
            </div>
            <div class="text__center">
                <button
                    class="btn__reset margin-r__10"
                    @click="hide">
                    {{ $t('BUTTON.NO') }}
                </button>
                <button
                    class="btn btn__m btn__round"
                    @click="handleDeleteComment">
                    {{ $t('BUTTON.YES') }}
                </button>
            </div>
        </section>
    </div>
</modal>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'DeleteCommentModal',
        data() {
            return {
                comment: null
            }
        },
        methods: {
            ...mapActions('entities/comments', {
                deleteComment: 'DELETE_COMMENT'
            }),
            // @vuese
            // Method to hide DeleteComment modal
            hide() {
                this.$modal.hide('delete-comment')
            },
            handleDeleteComment() {
                this.deleteComment(this.comment)
                this.hide()
            },
            // @vuese
            // Method that call before modal open and set to local data comment
            // @arg The argument is modal-open event with param comment [object]
            beforeOpen(event) {
                if (event && event.params && event.params.comment) {
                    this.comment = event.params.comment
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal__sphir_Online_Access_Agreement {
        section {
            width: 34rem;
        }
    }
</style>