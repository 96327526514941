import request from '@utils/request'

export function getCommentsById(payload) {
    return request({
        url: '/feed/comments',
        method: 'get',
        params: {
            ...payload
        }
    })
}

export function getCommentById(id) {
    return request({
        url: `/feed/comments/${id}`,
        method: 'get'
    })
}

export function addNewReply(payload) {
    return request({
        url: '/feed/comments',
        method: 'post',
        params: {
            ...payload
        }
    })
}

export function loadReplies(pagination, comment_id) {
    return request({
        url: '/feed/comments',
        method: 'get',
        params: {
            parent_comment_id: comment_id,
            ...pagination
        }
    })
}

export function updateComment(id, payload) {
    return request({
        url: `/feed/comments/${id}`,
        method: 'put',
        data: {
            ...payload
        }
    })
}

export function getCommentAttachmentsById(id) {
    return request({
        url: '/feed/comments/get_attachments',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function createImage(payload) {
    return request({
        url: '/images',
        method: 'post',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: payload
    })
}

export function uploadImageToS3({data, file}, requestParams={} ) {
    return request({
        url: data.upload_links.put_url,
        method: 'put',
        timeout: 60 * 60 * 1000,
        headers: {
            'Content-Type': file.type
        },
        data: file,
        onUploadProgress: function (progressEvent) {
            // console.log(`uploaded: ${parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))}%`)
        },
        ...requestParams
    })
}

export function updateImage(payload, id) {
    return request({
        url: `/images/${id}`,
        method: 'put',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: payload
    })
}

export function addComment(payload) {
    return request({
        url: '/feed/comments',
        method: 'post',
        data: {
            ...payload,
        }
    })
}

export function deleteComment(payload) {
    return request({
        url: `/feed/comments/${payload.id}`,
        method: 'delete'
    })
}

export function updateReply(payload) {
    return request({
        url: `/feed/comments/${payload.id}`,
        method: 'put',
        params: {
            ...payload
        }
    })
}

export function deleteReply(payload) {
    return request({
        url: `/feed/comments/${payload.id}`,
        method: 'delete',
    })
}