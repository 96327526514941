import request from '@utils/request'

export function getFriendLists(payload) {
    return request({
        url: `/friends/lists`,
        method: 'get',
        params: {
            ...payload
        }
    })
}

export function userFollowToggle (data) {
    return request({
        url: '/friends/follow_toggle',
        method: 'post',
        data //{ user_id }
    })
}

export function addFriend(data) {
    return request({
        url: `/friends`,
        method: 'post',
        data //{ user_id }
    })
}
