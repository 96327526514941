<template>
<div class="add-link-btn-component">
    <button v-tooltip="$t('FEED.ATTACH_LINK')" class="btn__reset" @click="handleClick">
        <i class="icon-copy" />
    </button>
</div>
</template>

<script>
import AddLinks from './AddLinks'

    export default {
        name: "AddLinkBtn",
        props: {
            url_hash: {type: Array, default: () => []},
            showAddLinkView: {type: Function}, //default: null
        },
        methods: {
            handleClick() {
                if (this.showAddLinkView) {
                    this.showAddLinkView(this.url_hash)
                } else {
                    this.openModal()
                }
            },
            openModal() {
                const componentSelf = this;
                const addLinksModal = {
                    render(h) {
                        const modalSelf = this;
                        return h(AddLinks, {
                            props: {
                                url_hash: componentSelf.url_hash
                            },
                            on: {
                                changed(e) {
                                    componentSelf.$emit("changed", e);
                                },
                                close() {
                                    modalSelf.$emit("close");
                                }
                            }
                        });
                    }
                }

                this.$modal.show(addLinksModal, {},{
                    name: "AddLinksModal",
                    width: 0
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .add-link-btn-component {

    }
</style>