<template>
<div :class="['upload-cover-component', {'complete': image.status==='COMPLETE'}]">
    <div v-show="image.status === 'COMPLETE' || _initImageUrl" class="displaying-image">

			<img v-if="image.croppedImageUrl" class="cropped-image" :src="image.croppedImageUrl" alt="Cropped Image">
			<img v-else-if="_initImageUrl" class="cropped-image" :src="_initImageUrl" alt="Cropped Image">

			<div class="change-btn-wrap">
				<button @click.stop.prevent="changeImage()" class="round-btn-small">Change Image</button>
			</div>
    </div>
	<div v-show="image.status !== 'COMPLETE' && !_initImageUrl && !showCropperModal"
		:class="['upload-dropzone', in_progress, {'dragover': dragover}]"
		@click="onClick"
		@drop.prevent="onDrop"
		@dragover.prevent="dragover = true"
		@dragleave.prevent="dragover = false"
		v-cloak>
			<input ref="fileInput"
							@change="selectionProcessing($event)"
							type="file"
							accept=".jpeg, .jpg, .png">

			<div v-if="Object.keys(processing_errors).length > 0 || image.status === 'INITIATED'">
				<div class="upload-image-title">
						<span class="bold-text">Upload cover Image</span>
				</div>

        <div v-if="Object.keys(processing_errors).length > 0" v-for="(messages, field) in processing_errors" class="errors">
          <p>{{field | capitalize | unSnakeCase }}</p>
          <div v-if="field === 'space_limited'" class="space_limited_msg">
            The file you are trying to upload exceeds the allotted storage.
            Please clean your <a href="/my_storage"  target="_blank" @click.stop="">Storage</a> first before attempting to re-upload
            it<span v-if="!is_plus_user">, or upgrade to <a href="/subscriptions" target="_blank" @click.stop="">Plus account</a>.</span>
          </div>
          <ul v-else>
            <li v-for="message in messages">
              {{message | capitalize}}
            </li>
          </ul>
        </div>

			</div>
			<div v-else-if="image.status==='CHECKING'">Checking...</div>
			<div v-else-if="image.status==='UPLOADING'">{{image.upload_percentage}}%</div>
			<div v-else-if="['COMPLETE'].includes(image.status)">Complete</div>
    </div>
    <div v-if="showCropperModal" class="vue2-cropper modal show">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<div class="title">
						<b>Cover Image</b>
						</div>
						<button class="close" type="button" @click.stop.prevent="closeCropperModal()">
						<img style="width: 20px" src="/assets/icon/x-20x20-white-991e6626a05b3d3d42dcef5f8bd62d5463ab13964109a59e15c2507fc2b73334.svg">
						</button>
					</div>
					<div class="modal-body">
						<div class="crop-wrapper">
							<vue-cropper
									class="vue-cropper"
									ref="cropper"
									:src="image.url"
									alt="Source Image"
									:viewMode="3"
									:autoCropArea="1"
									:aspectRatio="3/2"
									:cropBoxResizable="false"
									:cropBoxMovable="false"
									:guides="false"
									dragMode="move"
							></vue-cropper>
						</div>

						<div class="change-btn-wrap">
							<button @click.stop.prevent="changeImage()" class="round-btn-small">Change Image</button>
						</div>
						<div class="done-btn-wrap">
							<button @click.stop.prevent="done()" class="round-btn-small">Done</button>
						</div>
					</div>
				</div>
			</div>
    </div>
</div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import device from 'current-device'

export default {
	components: { VueCropper },
	props: ['uid', 'initImageUrl'],
	data() {
		return {
			dragover: false,
			showCropperModal: false,
			_initImageUrl: '',
			maxFileSize: 1024 * 1024 * 10
		}
	},
	computed: {
		image: function () {
			return this.$store.getters['Covers/getImageByUid'](this.uid)
		},
		processing_errors: function () {
			return this.image.processing_errors
		},
		in_progress: function () {
			return (['INITIATED', 'COMPLETE'].includes(this.image.status) ? '' : 'in-progress');
		},
    is_plus_user(){
      const user = JSON.parse(localStorage.getItem('user'));
      return user && user.is_plus_account
    },
	},
	watch: {
		'image.status': function (val) {
			switch (val) {
				case 'CHECKING':
					$('.upload-cover-component').closest('form.form-horizontal').find('.section-button button').attr('disabled', 'disabled')
					break;
				case 'COMPLETE':
					$('.upload-cover-component').closest('form.form-horizontal').find('.section-button button').removeAttr("disabled")
					break;
			}
		},
		showCropperModal: function(val){
			this.switchParentModal(val)
		}
	},
	methods: {
		async selectionProcessing(event) {
			let file = event.target.files[0];
			const {uid} = this
			if(file){
				if(file.size > this.maxFileSize){
					this.$store.commit('Covers/UPDATE', {
						uid,
						processing_errors: {too_big_file: ['Please select a file not exceeding 10 Mb']}
					})
				}else{
					this.$store.commit('Covers/PARSE_FILE', {uid, file})

					if (typeof FileReader === 'function') {
						const reader = new FileReader();
						reader.onload = (event) => {
							this.imgSrc = event.target.result;
							this.$refs.cropper.replace(event.target.result);
						};
						reader.onerror = function(event) {
							console.error("Failed to read file!\n\n" + reader.error);
							reader.abort(); // (...does this do anything useful in an onerror handler?)
						};
						reader.readAsDataURL(file);
						this.showCropperModal = true
					} else {
						console.error('Sorry, FileReader API not supported');
					}
				}
			}
		},
		onClick() {
			if(["INITIATED", "COMPLETE"].includes(this.image.status)){
				this.$refs.fileInput.click()
			}
		},
		onDrop(e) {
			if(["INITIATED", "COMPLETE"].includes(this.image.status)){
				let {uid} = this,
				droppedFiles = e.dataTransfer.files;
				this.dragover = false;
				if (!droppedFiles) return;
				if (droppedFiles.length > 1) {
					this.$store.commit('Covers/UPDATE', {
						uid,
						processing_errors: {too_many_files: ['Select please only one file']}
					})
				} else {
					this.$refs.fileInput.files = droppedFiles
					this.$refs.fileInput.dispatchEvent(new Event("change"))
				}
			}
		},
		changeImage(){
			this.$refs.fileInput.click()
		},
		async done(){
			let {uid} = this
			this.showCropperModal = false

			let croppedImageUrl = this.$refs.cropper.getCroppedCanvas().toDataURL()
			this._initImageUrl = false
			let croppData 	    = this.$refs.cropper.getData()
			this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
				blob.lastModifiedDate = new Date()
				blob.name = this.image.origin_name
				this.$store.commit('Covers/UPDATE', { uid, croppedImageUrl, croppData, croppedImageFile: blob})
				this.$store.commit('Covers/PARSE_FILE', {uid, file: blob})
				await this.$store.dispatch('Covers/create', {uid})
				await this.$store.dispatch('Covers/upload', {uid})
				await this.$store.dispatch('Covers/update', {uid})
				$("input[name='cover_image']").val(this.image.id)
			})
		},
		closeCropperModal(){
			this.showCropperModal = false
			this.$store.commit('Covers/UPDATE', {
				uid: this.uid,
				status: "INITIATED"
			})
		},
		switchParentModal(flag){
			if (device.ios() || this.$browser.name == 'Safari'){
				let el = $('.card-create-form .upload-cover-component').parents('.upload-image-field')
				el.toggleClass('expanded-for-modal', flag)
			}
		},
		onModalClose(){
			let {uid} = this
			this.$store.commit('Covers/CLEAR', {uid})
		}
	},
	created() {
		let {uid} = this
		this.$store.commit('Covers/ADD', {uid})
		this._initImageUrl = this.initImageUrl

		window.addEventListener('upload-cover-set-img-url', (e) => {
			if (e.detail.to_crop && e.detail.image_url) {
				const xhr = new XMLHttpRequest();
				xhr.open('GET', e.detail.image_url);
				xhr.responseType = 'arraybuffer';
				xhr.onload = ()  => {
					this.$store.commit('Covers/UPDATE', { uid, url: e.detail.image_url });
					this.showCropperModal = true;
				};
				xhr.onerror = (e) => { console.log('image blocked by error loading (CORS policy?)', e) };
				xhr.send();
			}
		})
	},
    mounted(){
		$('#modal-create-card, .modal-create-post-card, .modal-create-comment-card')
            .on('hidden.bs.modal', this.onModalClose)
            .on('show.bs.modal', this.onModalClose)
	}
}
</script>

<style lang="scss">
.upload-cover-component {
	width: 100%;
	.displaying-image{

		.cropped-image{
			width: 320px;
    		height: 214px;
		}

		.change-btn-wrap{
			margin-top: 20px;

			>button{
				width: 170px;
			}
		}
	}

	.vue-cropper {
		overflow: hidden;
		min-height: 180px;
	}

	.upload-dropzone{
			display: flex;
			align-items: center;
			justify-content: center;

			position: relative;
			padding: 5px;
			min-height: 90px;
			width: 100%;
			border: 1px #d1d3d4 dashed;
			background-color: #f8f8f9;
			cursor: pointer;
			z-index: 1;

			&.in-progress{
				cursor: progress;
			}

			&:hover {
					border-color: #8f969d;
					.upload-image-title {
							color: #808285;
					}
			}
			// dragover hover
			&.dragover {
					cursor: copy;
					border-color: #8f969d;
					.upload-image-title {
							color: #808285;
					}
			}

			.upload-image-title {
					font-family: "montserrat-bold";
					font-size: 14px;
					color: #a7a9ac;
					text-align: center;
			}

			input[type='file'] {
					display: none;
			}
	}

	.vue2-cropper{
		z-index: 99;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		cursor: default;
		position: fixed;

		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.46);

		.modal-dialog {
			html.mobile & {
				margin-top: 50px;
			}
			max-width: 400px;
			font-family: "montserrat-bold";

			.modal-content{
				max-width: 400px;

				.modal-header{
					.title{
						margin: 20px auto;
						font-size: 14px;
					}
				}

				.modal-body{
					.crop-wrapper {
						position: relative;
						padding-top: 66.66%;
						background-color: lightgray;
						.vue-cropper {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							overflow: hidden;
							border: none;
							.cropper-view-box {
								outline: none;
							}
						}
					}
					.change-btn-wrap{
						margin-top: 50px;

						>button{
							width: 140px;
						}
					}

					.done-btn-wrap{
						float: right;
						margin-top: 75px;

						>button{
							padding: 10px 20px;
						}
					}
				}
			}
		}
	}

  .errors {
    color: #5a5a5a;
    margin-top: 10px;
    text-align: left;
    p {
      margin: 0;
      color: #4b419a;
    }
    .space_limited_msg {
      padding: 5px 10px;
    }

  }
}
</style>