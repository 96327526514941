<template>
<div class="parsed-link-component">
    <LinkInfoShort
        v-for="link in filtered_links"
        :key="link.$index"
        :link="link"
        :with_delete="editable"
        @delete="deleteLink(link)" />
</div>
</template>

<script>
import LinkInfoShort from "@kit/LinkInfoShort";

export default {
    name: 'ParsedLinks',
    components: { LinkInfoShort },
    props: {
        links:    {type: Array, default: () => []},
        editable: {type: Boolean, default: false},
    },
    computed: {
        filtered_links() {
            return this.links.filter(link => link.url)
        },
    },
    methods: {
        deleteLink(link) {
            this.$emit('deleteLink', link)
        },
    }
}
</script>

<style lang="scss" scoped>
  .parsed-link-component {
  }
</style>