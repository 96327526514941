<template>
<div class="input__field" :class="{error: error}">
    <!-- Input field from parent -->
    <slot>
    <!-- `<input v-model="form.confirm_password" type="password" autocomplete="off" name="confirm_password" id="confirm_password" placeholder=" " required="required">` -->
    </slot>

    <span
        class="input__message"
        :class="{show: error}"
        v-html="$t(`${error}`)" />
</div>
</template>

<script>
    import {eventBus} from '@utils/event_bus'
    import validationUtil from '@utils/validate'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'InputField',
        props: {
            // @vuese
            // Array with names of validation functions that placed in validation.js, pass like array of strings
            validation: {type: Array, default: () => []},
            // @vuese
            // Validation function that passed from parent. Use if need to relations between parent components data
            validationFunc: {type: Function},
            // @vuese
            // Meta data with [form: String, form name related with error.js store], [events: Array of strings, list of event that trigger validation]
            meta: {type: Object, default: () => {}}
        },
        data() {
            return {
                error: '',
                field: null
            }
        },
        computed: {
            ...mapGetters('errors', {
                getError: 'search'
            }),
        },
        mounted() {
            const field = this.$slots.default[0].elm
            const validationArray = this.validation

            this.field = field
            const _this = this
            const events = this.meta.events

            events.forEach(function (event) {
                field.addEventListener(event, function (e) {
                    const errorsArray = []
                    if (validationArray) {
                        validationArray.forEach(validateFunc => {
                            const validationResult = validationUtil[validateFunc](field.value)
                            validationResult !== true ? errorsArray.push(validationResult) : null
                        })
                        if (!errorsArray.length) {
                            _this.clearError()
                        }
                        if (errorsArray.length) {
                            _this.setError({
                                form: _this.meta.form,
                                field: _this.field.name,
                                message: errorsArray[0]
                            })
                            _this.error = _this.getError(_this.meta.form, _this.field.name)
                        } else if (_this.validationFunc) {
                            let isIterationDone = false
                            const generator = _this.validationFunc()

                            while (!isIterationDone) {
                                let {value, done} = generator.next()

                                if (typeof value === 'string') {
                                    _this.setError({
                                        form: _this.meta.form,
                                        field: _this.field.name,
                                        message: value
                                    })
                                    _this.error = _this.getError(_this.meta.form, _this.field.name)
                                }
                                break

                                isIterationDone = done
                            }
                        }
                    }
                })
            })

            field.addEventListener('focus', function () {
                _this.clearError()
            })
            // listen the update error state event
            eventBus.$on('error-updated', () => this.update())
        },
         beforeDestroy() {
            this.field.removeEventListener('focus', () => {
            }, false)
            this.field.removeEventListener('blur', () => {
            }, false)
        },
        methods: {
            ...mapActions('errors', {
                setError: 'SET_ERROR'
            }),
            // @vuese
            // method to load new error state
            update() {
                this.error = this.getError(this.meta.form, this.field.name)
            },
            // @vuese
            // clear error state for this field in store
            clearError() {
                this.setError({
                    form: this.meta.form,
                    field: this.field.name,
                    message: ''
                })
                this.error = ''
            }
        },
    }
</script>

<style lang="scss">

    .input__field {
        .input__message {
            display: block;
            position: relative;
            font-size: 0.8em;
            line-height: 1.2em;
            left: 0;
            width: 100%;
            margin-top: -20px;
            opacity: 0;
            transition: all 0.3s ease;
            z-index: -1;
            color: red;

            &.show {
                margin-top: 0;
                opacity: 1;
                animation: showError 0.3s ease;
                animation-iteration-count: 1;
                z-index: 10;
            }
        }
    }

    @keyframes showError {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
</style>