<template>
    <div class="posters-nav-component" :class="className">
        <div class="row m-b-10">
            <div class="col-md-4" v-for="i in sortedPostersListKeys" :key="i">
                <upload-image :uid="own_image_uid"
                              :holder="holder"
                              @onComplete="setOwnPoster"
                              v-if="i === 'own' && !posters_list[i].sm"></upload-image>

                <div :class="['poster-img-container', video.status.toLowerCase()]" v-else>

                    <div class="fa fa-spin fa-spinner" v-if="i !== 'own' && isAutoGenPosterProcessing"></div>

                    <div class="poster-img"
                         @click="$refs[`poster_index_${i}`][0].click()"
                         v-if="i === 'own' || isAutoGenPosterComplete">
                            <input type="radio" class="poster-index-input"
                                   :ref="`poster_index_${i}`"
                                   v-model="poster_index"
                                   :value="i"/>
                            <i class="fa fa-check poster-mark" v-if="poster_index === i"></i>
                            <div class="fa fa-trash poster-remove" title="Remove this thumbnail"
                                 @click.stop="removeOwnPoster(i)"
                                 v-if="i === 'own'"></div>
                            <img v-if="posters_list[i].sm" :src="posters_list[i].sm" alt="thumbnail"/>
                            <img v-else-if="i === 'own'" :src="ownImage.url" class="temp-img-preview"/>
                    </div>
                </div>
            </div>
        </div>
        <input class="styled-checkbox" id="stream_plus" type="checkbox"
               :disabled="!mainValidForVideoTag"
               v-model="selectTimelineMode"/>
        <label class="purchase-label" for="stream_plus">Pick thumbnail from timeline</label>
    </div>
</template>

<script>
    import {mapDynamicFields} from "./helpers/mapDynamicFields"
    import UploadImage from "./UploadImage"

    export default {
        props: ['kind', 'className'],
        components: {UploadImage},
        data: function () {
            return {
                spath: `Videos.uploads.main`,
                own_image_uid: 'own_image'
            }
        },
        computed: {
            ...mapDynamicFields('spath', {
                video: 'api_state',
                poster_index: 'api_state.poster_index',
                posters_list: 'api_state.posters_list',
                mainValidForVideoTag: 'validForVideoTag',
                selectTimelineMode: 'selectTimelineMode',
            }),
            sortedPostersListKeys: function () {
                return Object.keys(this.posters_list || []).sort((a, b) => (a === 'own' ? -1 : a - b))
            },
            holder: function(){
                return this.$store.state.Videos.uploads.main
            },
            ownImage: function () {
                return this.$store.getters['Images/getImageByUid'](this.own_image_uid)
            },
            isAutoGenPosterProcessing: function(){
                return ['SUBMITTED', 'PROGRESSING'].includes(this.video.status)
            },
            isAutoGenPosterComplete: function(){
                return ['COMPLETE'].includes(this.video.status)
            }
        },
        methods: {
            setOwnPoster(){
                let {kind} = this
                this.$store.commit('Videos/SET_OWN_POSTER', {kind, paths: this.ownImage.paths})
            },
            async removeOwnPoster() {
                this.$store.commit('Videos/REMOVE_OWN_POSTER', {kind: 'main'})
                await this.$store.dispatch('Images/delete', this.ownImage); // fix auto restore img by fetch video, todo need wait to save form?
            },
            async uploadNewImage(file) {
                let {kind} = this;
                this.selectTimelineMode = false
                if (this.ownImage.status !== 'INITIATED') {
                    await this.removeOwnPoster()
                }
                const uid = this.own_image_uid;
                this.$store.commit('Images/PARSE_FILE', {uid, file});
                await this.$store.dispatch('Images/create', {uid});
                await this.$store.dispatch('Images/upload', {uid});
                await this.$store.dispatch('Images/update', {uid});
                this.$store.commit('Videos/SET_OWN_POSTER', {kind, paths: this.ownImage.paths})
            },
        },
        created() {
            const vm = this;
            this.$root.$on('setTimelineThumbnail', (file) => {
                vm.uploadNewImage(file)
            });
        }
    }
</script>

<style lang="scss" scoped>
    .posters-nav-component {
        .poster-img-container {
            border: 1px #d1d3d4 dashed;
            height: 60px;
            position: relative;
            background-color: #f8f8f9;
            display: flex;
            align-items: center;
            &.complete{
                background-color: black;
            }
            &:hover .poster-remove {
                opacity: 1;
            }
        }
        .poster-img{
            cursor: pointer;
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
        }
        .fa-spinner{
            margin: 0 auto;
        }
        .poster-index-input {
            display: none;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            vertical-align: middle;
            margin: 0 auto;
        }

        .poster-mark {
            color: white;
            position: absolute;
            top: 0;
            right: 0;
            background-color: black;
            padding-left: 2px;
            margin: 0;
        }

        .poster-remove {
            position: absolute;
            top: 0;
            left: 0;
            color: white;
            background: none;
            font-size: 1.5em;
            padding: 5px;
            cursor: pointer;
            opacity: 0;
            transition: opacity linear .3s;
            text-shadow: 1px 1px 5px black;
        }
        .styled-checkbox[disabled] + label {
            opacity: 0.5;
            cursor: not-allowed;
        }
        &.cardForm {
            .row {
                margin-left: -10px;
                margin-right: -10px;
            }
            .poster-img-container, .upload-image-component {
                height: 75px
            }
        }
        .temp-img-preview {
            opacity: 0.5;
        }
    }
</style>