<template>
    <div class="attachments-section m-b-30">
        <h6 class="title">Attachments</h6>
        <div class="row">
            <div class="modal-attach-preview" v-show="show_attach_modal">
                <div class="content" v-bind:class="{ 'by_width': attach_by_width, 'hideIn': attach_hideIn }">
                    <div @click="close_modal" class="fa fa-times"></div>
                    <div v-if="media_attachments.length > 1" @click="switch_media_attach('prew')" class="fa fa-chevron-left"></div>
                    <div v-if="media_attachments.length > 1" @click="switch_media_attach()" class="fa fa-chevron-right"></div>
                    <img v-if="selectedAttach.type === 'image'" class="file-preview" v-bind:src="selectedAttach.url"/>
                    <video v-else-if="selectedAttach.type === 'video'" class="file-preview" v-bind:src="selectedAttach.url" controls></video>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 m-b-10" v-for="attach in attachments">
                <div class="attach-file-wrap" :id="`file-${attach.id}`">
                    <div class="file-thumbnail available">
                        <img v-if="attach.type === 'image'" @click="open_in_modal(attach)" class="file-preview" :src="attach.url"/>
                        <div v-else-if="attach.type === 'video'" @click="open_in_modal(attach)" class="file-preview video-attach">
                            <i class="fa fa-play-circle"></i>
                            <video class="file-preview" :src="attach.url"></video>
                        </div>
                        <a v-else-if="has_access" class="file-preview" :href="attach.url" target="_blank">{{ fileExtensionUp(attach) }}</a>
                        <a v-else class="file-preview">{{ fileExtensionUp(attach) }}</a>
                    </div>
                    <div class="file-name">{{attach.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['product', 'current_user_id'],
        data() {
            return {
                selectedAttach: {},
                show_attach_modal: false,
                attach_by_width: false,
                attach_hideIn: false,
            }
        },
        computed: {
            has_access: function () {
                return (this.product.purchased || this.product.rented || this.product.owner)
            },
            attachments: function () {
                return this.product.attachment.map(f => {
                    f.type = 'file';
                    if (f.url.match(/(jpg|jpeg|png|bmp|gif|ico)$/i)) {
                        f.type = 'image';
                    } else if (f.url.match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) { //
                        f.type = 'video';
                    }
                    return f
                });
            },
            media_attachments: function () {
                return this.attachments.filter(f => (f.type === 'image' || f.type === 'video'));
            },

        },
        created() {
            document.onkeydown = this.keyHandle;
        },
        watch: {
            videoSource: function (nv, pv) {
                this.setPlayer()
            },
        },

        methods: {
            moment(date, format) {
                return moment.utc(date, 'YYYY-MM-DD hh:mm A ').local().format(format || 'D MMM YYYY')
            },
            close_modal() {
                this.show_attach_modal = false
                this.selectedAttach = {}
            },
            open_in_modal(attach) {
                if (this.has_access) {
                    this.selectedAttach = attach;
                    this.show_attach_modal = true;
                    setTimeout(() => {
                        this.attach_by_width = $('.modal-attach-preview .content').width() > 0.9 * window.innerWidth;
                    }, 100);
                }
            },
            switch_media_attach(next = 'next') {
                const vm = this;
                const ind = this.media_attachments.indexOf(this.selectedAttach);
                let nextAtt;
                if (next === 'next') {
                    nextAtt = ind + 2 > this.media_attachments.length ? this.media_attachments[0] : this.media_attachments[ind + 1];
                } else {
                    nextAtt = ind === 0 ? this.media_attachments[this.media_attachments.length - 1] : this.media_attachments[ind - 1];
                }
                this.selectedAttach = nextAtt;
                if (nextAtt.type === 'video') {
                    vm.attach_hideIn = true;
                    setTimeout(() => {
                        vm.attach_hideIn = false
                    }, 100);
                }
            },
            keyHandle(e) {
                if (this.show_attach_modal && this.media_attachments.length > 1) {
                    if (e.code === 'ArrowRight') {
                        this.switch_media_attach();
                        e.preventDefault();
                    } else if (e.code === 'ArrowLeft') {
                        this.switch_media_attach('prew');
                        e.preventDefault();
                    } else if (e.code === 'Escape') {
                        this.show_attach_modal = false;
                        e.preventDefault();
                    }
                }
            },
            fileExtensionUp: function (attach) {
                return attach.url.match(/[^.]*$/)[0].toUpperCase()
            },
        }
    }
</script>

<style scoped>

</style>