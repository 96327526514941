import request from '@utils/request'

export function getProduct (_params) {
    let { id, ...params } = typeof(_params) === 'object' ? _params : {}
    return request({
        url: `/products/${id || _params}`,
        method: 'get',
        params //{post_on_sphir}
    })
}
