<template>
<div class="item-preview-component ItemPreview">
    <CoverImg :src="imgURL" :height_ratio="1">
        <div class="item-content">
            <div class="text-right">
                <DeleteBtn @click="$emit('delete')" />
            </div>
            <div class="title">
                {{ item.title || item.name }}
            </div>
        </div>
    </CoverImg>
</div>
</template>

<script>
import CoverImg from "@kit/CoverImg";
import DeleteBtn from "./DeleteBtn";
    export default {
        components: { CoverImg, DeleteBtn },
        props: {
            item: { type: Object }
        },
        computed: {
            imgURL() {
                return this.item.embed_url_info?.img_src || this.item.cover_photo || this.item.logo_image || (this.item.four_cards_logo && this.item.four_cards_logo[0]?.logo_image)
            },
        },
    }
</script>


