import {
    createEmbedUrlInfo,
    getEmbedUrlInfo,
} from "../../api/api.embed_url_info"

const embed_url_info = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        PARSE_LINK ({commit}, payload) {
            return new Promise(async (resolve, reject) => {
                try {
                    let embed_url_info = (await createEmbedUrlInfo(payload)).data.response
                    if (embed_url_info.status === 'parsed') {
                        resolve(embed_url_info);
                    } else {
                        const interval = setInterval(async function () {
                            try {
                                embed_url_info = (await getEmbedUrlInfo(embed_url_info.id)).data.response
                                if (embed_url_info.status === 'parsed') {
                                    clearInterval(interval);
                                    resolve(embed_url_info);
                                } else if (embed_url_info.status === 'fail') {
                                    clearInterval(interval);
                                    // reject('The specified URL does not have a valid source')
                                    resolve({message: 'The specified URL does not have a valid source', ...payload});
                                } else {
                                    // console.log('PARSE_URL await until the url is parsed', embed_url_info)
                                }
                            } catch (e) {
                                clearInterval(interval);
                                throw e
                            }
                        }, 700)
                    }
                } catch (e) {
                    console.error('PARSE_LINK err: ', e)
                    // reject('The specified URL does not have a valid source')
                    resolve({message: 'The specified URL does not have a valid source', ...payload});
                }
            })
        }
    }
}

export default embed_url_info