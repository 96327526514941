<template>
<div class="upload-profile-banner">
  <div class="control-panel">
    <div v-show="['UPLOADING', 'UPLOADED'].includes(banner.status)">
      <div class="btn upload-percentage">Uploading {{banner.upload_percentage}}%</div>
    </div>
    <div v-show="['INITIATED', 'CHECKING', 'PROCESSING', 'COMPLETE'].includes(banner.status)">
      <div v-show="display == 'cropper'" @click.prevent="cropper.relativeZoom(0.1)" class="btn"><i class="fa fa-search-plus"></i></div>
      <div v-show="display == 'cropper'" @click.prevent="cropper.zoomTo(1)" class="btn"><i class="fa fa-arrows-alt"></i></div>
      <div v-show="display == 'cropper'" @click.prevent="cropper.relativeZoom(-0.1)" class="btn"><i class="fa fa-search-minus"></i></div>
      <div v-show="display == 'banner' || display == 'cropper'" @click.stop.prevent="uploadNewBanner()" class="btn" title="Click me to upload new banner"><i class="fa fa-upload"></i></div>
      <div v-show="isShowCrossBtn" @click.prevent="closeEditing()" class="btn"><i class="fa fa-times"></i></div>
      <div v-show="display == 'cropper'" @click.stop.prevent="save()" class="btn save"><i class="fa fa-check"></i> <span class="save-title">Save</span></div>
    </div>
  </div>

  <div v-show="display == 'banner'" ref="banner" class="banner-display">
  </div>

  <div v-show="display == 'dropzone'"
      :class="['upload-dropzone', {'in-progress': !['INITIATED', 'COMPLETE'].includes(status), 'dragover': dragover}]"
      @click="onClick"
      @drop.prevent="onDrop"
      @dragover.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      v-cloak>
    <input ref="fileInput"
          @change="selectionProcessing($event)"
          type="file"
          accept=".jpeg, .jpg, .png">

    <div v-if="status === 'INITIATED'">
      <div class="upload-image-title">
        <div class="big-text bold-text">Drag your image here or click to add one</div>
        <div class="small-text">The max file size: {{ maxSize/1024/1024 }} MB</div>
      </div>
    </div>
    <div v-else-if="status==='CHECKING'">Checking...</div>
    <!-- <div v-else-if="status==='UPLOADING'">{{image.upload_percentage}}%</div>
    <div v-else-if="['COMPLETE'].includes(status)">Complete</div> -->
  </div>

  <vue-cropper
      v-show="display == 'cropper'"
      class="vue-cropper"
      ref="cropper"
      :src="banner.url"
      alt="Source Image"
      :viewMode="3"
      :autoCropArea="1"
      :cropBoxResizable="false"
      :cropBoxMovable="false"
      :guides="false"
      :minCropBoxHeight="300"
      dragMode="move"
  >
  </vue-cropper>
</div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  props: ['id', 'initImageUrl'],
  components: { VueCropper },
  data() {
    return {
      dragover: false,
      display: 'banner', // banner || dropzone || cropper
      maxSize: 10 * 1024 *1024
    }
  },
  computed: {
    banner: function () {
      return this.$store.getters['Users/getUserById'](this.id).banner
    },
    status: function() {    
      return this.banner.status
    },
    cropper: function() { return this.$refs.cropper },
    isShowCrossBtn(){
      return (
        (this.display == 'cropper' || this.display == 'dropzone') && 
        (this.$refs.banner && this.$refs.banner.style.backgroundImage.includes('url'))
      )
    }
  },
  methods: {
    onClick(){
      if(["INITIATED", "COMPLETE"].includes(this.status)){
        this.$refs.fileInput.click()
      }
    },
    onDrop(e){
      if(["INITIATED", "COMPLETE"].includes(this.status)){
        let {id} = this,
            droppedFiles = e.dataTransfer.files;
            
        this.dragover = false;
        if (!droppedFiles) return;
        if (droppedFiles.length > 1) {
          this.$store.commit('Users/UPDATE_BANNER', {
            id,
            data: { processing_errors: {too_many_files: ['Select please only one file']} }
          })
        } else {
          this.$refs.fileInput.files = droppedFiles
          this.$refs.fileInput.dispatchEvent(new Event("change"))
        }
      }
    },
    async selectionProcessing(event) {
      let file = event.target.files[0];
      if (typeof FileReader !== 'function') {
        console.error('Sorry, FileReader API not supported');
      } else if (file) {
        const {id, maxSize} = this

        if (maxSize && file.size > maxSize) {
          this.$store.commit('Users/UPDATE_BANNER', 
          { 
            id,
            data: { processing_errors: {too_big_file: ['Please select a file not exceeding 10 Mb']} }
          })
        } else {
          this.$store.commit('Users/PARSE_IMAGE', {id, file, imgType: 'banner'})
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            this.$refs.cropper.replace(event.target.result);
            this.display = 'cropper'
          };
          reader.readAsDataURL(file);
        }
      }
    },
    uploadNewBanner(){
      this.display = 'dropzone'
      this.$refs.fileInput.value = ''
      this.$store.commit('Users/UPDATE_BANNER', { 
        id: this.id,
        data: { status: 'INITIATED' }
      })
    },
    closeEditing(){
      this.display = 'banner'
      this.$refs.fileInput.value = ''
      this.$store.commit('Users/UPDATE_BANNER', { 
        id: this.id,
        data: { status: 'INITIATED' }
      })
    },
    save(){
      let {id} = this
      if(this.status !== 'PROCESSING'){
        this.$store.commit('Users/UPDATE_BANNER', { 
          id: this.id,
          data: { status: 'PROCESSING' }
        })
        let croppedImageUrl = this.$refs.cropper.getCroppedCanvas().toDataURL()
        let croppData 	    = this.$refs.cropper.getData()
        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          try {
            blob.lastModifiedDate = new Date()
            blob.name = this.banner.origin_name
            this.$store.commit('Users/UPDATE_BANNER', { 
              id,
              data:{
                croppedImageUrl,
                croppedImageFile: blob
              }
            })
            this.$store.commit('Users/PARSE_IMAGE', {id, file: blob, imgType: 'banner', status: 'PROCESSING'})
            await this.$store.dispatch('Users/getS3Urls', {id, imgType: 'banner'})
            await this.$store.dispatch('Users/upload', {id, imgType: 'banner'})
            await this.$store.dispatch('Users/updateBanner', {id})

            this.display = 'banner'
            this.$refs.fileInput.value = ''
            this.$refs.banner.style.backgroundImage = `url('${croppedImageUrl}')`
            this.$store.commit('Users/UPDATE_BANNER', { 
              id: this.id,
              data: { upload_percentage: 0 }
            })
          } catch (error) {
            console.error(error)
            this.$store.commit('Users/UPDATE_BANNER', { 
              id: this.id,
              data: { status: 'ERROR' }
            })
          }
        })
      }
    }
  },
  created(){
    let { id } = this
    this.$store.commit('Users/ADD', {id})
  },
  mounted(){
    if(this.initImageUrl){
      this.$refs.banner.style.backgroundImage = `url('${this.initImageUrl}')`
    }else{
      this.display = 'dropzone'
    }
  }
}
</script>

<style lang="scss">
.upload-profile-banner{
  height: var(--banner-height, 300px);

  .control-panel{
    right: 18px;
    z-index: 1;
    position: absolute;
    background-color: white;

    .btn{
      width: 42px;
      color: #4c419a;
      box-sizing: border-box;
      border-radius: 0 !important;
      font-family: "montserrat-bold";      

      > i{
        margin-right: 0;
      }

      &:hover{
        color: white;
        background-color: #4c419a;
      }

      &.save{
        width: 90px;
      }

      &.upload-percentage{
        width: 160px;
        font-family: "montserrat-bold";
      }
    }
  }
  
  .banner-display{
    height: 100%;
    background-color: #fff;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center center;

    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
  }

  .upload-dropzone{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    border: 1px #d1d3d4 dashed;
    background-color: #f8f8f9;

    &.in-progress{
      cursor: progress;
    }

    &:hover{
      border-color: #717171;

      .upload-image-title{
        color: #4a4a4a;
      }
    }

    &.dragover{
      cursor: copy !important;
      border-color: #717171;

      .upload-image-title{
        color: #4a4a4a;
      }
    }

    input{
      display: none;
    }

    .upload-image-title{
      color: gray;

      .big-text{
        font-size: 19px;
        margin-bottom: 10px;
      }
      .small-text{
        font-size: 16px;
      }
    }
  }

  .vue-cropper{
    height: 100%;   
  }
}

@media (max-width: 990px) {
  .upload-profile-banner .control-panel{
    right: 0;
  }
}

@media (max-width: 318px) {
  .upload-profile-banner{
    .control-panel{
      right: 0;
      .save{
        width: 42px !important;
        .save-title{
          display: none;
        }
      }
    }
  }
}
</style>