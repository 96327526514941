<template>
    <div class="product-podcast-section vue-inited" id="product-upload-podcast-section" ref="audioSourceSection">
        <input v-if="isProductForm" type="hidden" name="product[audio_id]" :value="audio.id">
        <input v-else type="hidden" name="card[audio_id]" :value="audio.id">
        <div class="m-b-10">
            <div class="row">
                <div class="col-md-8">
                    <input class="radio-custom" id="src-file" type="radio" v-model="audio.source_type" value="file"/>
                    <label class="radio-custom-label" for="src-file">Audio File</label>
                </div>
                <div class="col-md-4">
                    <input class="radio-custom" id="src-url" type="radio" v-model="audio.source_type" value="url"/>
                    <label class="radio-custom-label" for="src-url">URL</label>
                </div>
            </div>
        </div>

        <div class="m-b-40">
            <div v-show="audio.source_type === 'file'">
                <podcast-dropzone max_size="500"
                                  :api="Api"
                                  :uploaded-podcast="audio"
                                  v-on:update:id="audio.id = $event"
                                  v-on:update:original_url="audio.original_url = $event"
                ></podcast-dropzone>


                <Player
                        v-if="audio.original_url"
                        :source="player_source"
                        :poster="player_poster"
                />
            </div>

            <div v-show="audio.source_type === 'url'">
                <div class="form-group form-group40 group-row" style="position: relative">
                    <label class="label-control">
                        <span class="label-text" style="top: 0">URL</span>
                    </label>
                    <input
                            @blur="onBlurSourceUrl"
                            @keyup.enter="onBlurSourceUrl"
                            class="form-control large_body inputs"
                            id="source_url"
                            placeholder="Paste your URL link here"
                            type="text"
                            v-model="temp_original_url"/>
                    <div class="text-right small" v-bind:class="{ 'error': hasError }">{{status}}</div>
                </div>
                <div class="card-image-section" v-if="embed_url_info.embed_content">
                    <div class="media-content">
                        <div @click="playPdYTiframe = true" class="embed-section" v-bind:class="{hideIframeVideoCrutch: playPdYTiframe}" v-bind:style="{ 'background-image': 'url(' + embed_url_info.img_src + ')'}" v-if="embed_url_info.provider_name === 'YouTube'">
                            <i class="fa fa-play-circle" v-if="!playPdYTiframe"></i>
                            <div class="crutch" v-if="playPdYTiframe" v-html="autoplay_embed_content"></div>
                        </div>
                        <div class="embed-section" v-bind:class="embed_url_info.provider_name" v-html="embed_url_info.embed_content">
<!--                            {{{ embed_url_info.embed_content }}}-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-b-40 thumbnails" v-show="audio.source_type === 'file' || !embed_url_info.embed_content">
            <h6>
                Cover Artwork <i class="small" v-show="isProductForm">(preview)</i>
            </h6>
            <p class="small">Supported formats: JPEG,PNG</p>
            <div class="thumb-uploaded">
                <div @click="$root.$emit('clearOwnThumbnail');" class="fa fa-trash" title="Remove this thumbnail" v-if="audio.poster_original_url"></div>
                <div style="height: 200px">
                    <thumbnail-dropzone2 elem_id="dropzone-audio-poster-file"
                                         max_size="10"
                                         :img_src="audio.poster_original_url"
                                         v-on:update:img_src="audio.poster_original_url = $event"
                                         :form_selector="form_selector"></thumbnail-dropzone2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PodcastDropzone from "../../audios/Dropzone";
    import Player from "../../Player";
    import ThumbnailDropzone2 from "./ThumbnailDropzone2";
    import axios from "axios";

    export default {
        components: {PodcastDropzone, Player, ThumbnailDropzone2},
        props: ['w_comp_var_name', '_card', '_audio', 'current_user_id', 'form_selector' , 'min_duration'],
        data() {
            return {
                Api: Api,
                status: '',
                hasError: false,
                temp_original_url: '',
                playPdYTiframe: false,
                audio: {
                    id: null,
                    reserved: null,
                    original_url: null,
                    embed_url_info_id: null,
                    poster_original_url: null,
                    source_type: 'file',
                    ...this._audio,
                },
                embed_url_info: { ...(this._audio || {}).embed_url_info },
            }
        },
        computed: {
            player_source: function () {
                return [ this.audio.original_url ]
            },
            player_poster: function () {
                return this.audio.poster_original_url
            },
            isProductForm: function () {
                return !this._card
            },
        },
        created() {
            if (this.w_comp_var_name) {
                window[this.w_comp_var_name] = this
            }
        },
        watch: {
            'audio.poster_original_url': function (nv, ov) {
                if (nv) {
                    this.createReservedPodcast()
                }
            },
            'audio.embed_url_info_id': function (nv, ov) {
                if (nv) {
                    this.createReservedPodcast()
                }
            },
        },

        methods: {
            checkSource(url) {
                const vm = this;
                return new Promise((resolve, reject) => {
                    // var objectUrl = URL.createObjectURL(file);
                    const audio = new Audio();
                    audio.oncanplay = function (e) {
                        console.log('checkSource audio.oncanplay', e)
                        if (audio.duration < (vm.min_duration || 10)) {
                            reject(`The audio is too short (< ${vm.min_duration || 10}s)`);
                        } else {
                            resolve({duration: audio.duration});
                        }
                    };
                    audio.onerror = async function (e) {
                        console.log('checkSource audio.onerror', e)
                        if (!vm.isProductForm) {
                            try {
                                let embed_url_info = await vm.Api.embed_url_info.create({url});
                                if (embed_url_info.status !== 'parsed') {
                                    embed_url_info = await vm.Api.embed_url_info.fetch(embed_url_info.id);
                                }
                                if (!embed_url_info.embed_content) {
                                    reject('This source cannot be used')
                                } else {
                                    vm.embed_url_info = embed_url_info;
                                    vm.audio.embed_url_info_id = embed_url_info.id;
                                    vm.status = '';
                                    const formId = vm.$refs.audioSourceSection.closest('form').id
                                    fillFromEmbed({formSelector: `#${formId}`, embed_url_info });
                                    // vm.$root.$emit('embed_url_info_created', embed_url_info);
                                    resolve({duration: embed_url_info.duration});
                                }
                            } catch (e) {
                                reject(e)
                            }
                        } else {
                            reject('This source cannot be used')
                        }
                    };
                    audio.src = url
                });
            },
            onBlurSourceUrl() {
                const vm = this;
                this.hasError = false;
                this.status = '';
                if (!this.temp_original_url) {
                    return
                }
                this.status = 'Checking...';
                if (!this.temp_original_url.includes('//')) {
                    this.temp_original_url = `http://${this.temp_original_url}`
                }
                this.checkSource(this.temp_original_url)
                    .then(i => {
                        console.log('onBlurSourceUrl checkSource then', i)
                        if (i.duration) {
                            vm.audio.original_url = vm.temp_original_url;
                            vm.audio.source_type = 'file';
                        }
                        if (!vm.audio.id) {
                            vm.saveAudio({
                                source_type: vm.audio.source_type,
                                original_url: vm.audio.original_url
                            })
                        }
                    })
                    .catch(e => {
                        console.log('onBlurSourceUrl checkSource catch', e)
                        vm.embed_url_info = {};
                        vm.hasError = true;
                        vm.status = e;
                    })
            },
            async createReservedPodcast() {
                const {id, source_type, original_url, poster_original_url, embed_url_info_id} = this.audio;
                const params = {
                    source_type,
                    original_url,
                    poster_original_url,
                    embed_url_info_id
                };
                let audio;
                if (!id) {
                    if (!original_url && !embed_url_info_id) {
                        params.reserved = true
                    }
                    audio = await this.Api.audio.create(params)
                    this.$root.$emit('audio_created', audio.id)
                    this.audio.id = audio.id;
                }
                return id || (audio || {}).id
            },

            saveAudio(params) {
                const vm = this;
                return new Promise(async (resolve, reject) => {
                    try {
                        const {id, source_type, original_url, poster_original_url, embed_url_info_id} = vm.audio;
                        const params = {
                            source_type,
                            original_url,
                            poster_original_url,
                            embed_url_info_id
                        };
                        let newAudio;
                        if (this.audio.id) {
                            newAudio = await vm.Api.audio.update(id, params)
                        } else {
                            newAudio = await vm.Api.audio.create(params)
                        }
                        vm.status = '';
                        vm.audio.id = newAudio.id;
                    } catch (e) {
                        vm.hasError = true;
                        vm.status = e.responseText;
                    }
                });
            },
            checkAudio() {
                const {id, source_type, original_url, poster_original_url, embed_url_info_id} = this.audio;
                if (id) {
                  Api.audio.update(id, {source_type, original_url, poster_original_url, embed_url_info_id})
                }
                return id
            },
            autoplay_embed_content: function () {
                let { embed_content } = this.embed_url_info
                if (!embed_content) return embed_content;
                if (embed_content.includes('soundcloud.com')) {
                    return embed_content.replace('auto_play=false', 'auto_play=true');
                } else {
                    return embed_content
                        .replace(/><\/iframe>/, ' allow="autoplay;"></iframe>')
                        .replace(/(src="[^"]*)"/, function (match, p1) {
                            return (p1 + (p1.includes('?') ? '&' : '?') + 'autoplay=1"')
                        });
                }
            },
        }
    }
</script>

<style scoped>

</style>