<template>
<div v-click-outside="hideEmoji" class="emoji-btn-component">
    <picker v-if="initPicker"
            v-show="showPicker"
            :data="$emojiIndex"
            @select="addEmoji" />
    <button
        class="btn__reset"
        @click="showEmoji">
        <i class="icon-smile" />
    </button>
</div>
</template>

<script>
    import { Picker } from 'emoji-mart-vue-fast'

    export default {
        components: { Picker },
        props: {
            element_selector: {type: String, default: () => ''},
        },
        data() {
            return {
                initPicker: false,
                showPicker: false
            }
        },
        computed: {
          allowAddEmoji() {
            const el = document.querySelector(this.element_selector)
            return el && el.value.length < parseInt(el.getAttribute('maxlength') || 1000)
          },
        },
        beforeMount() {
        },
        methods: {
            addEmoji(emoji) {
                const textElement = document.querySelector(this.element_selector)
                const leftTextSpace = textElement?.value?.length < (parseInt(textElement?.getAttribute('maxlength')) || 1000)
                if (leftTextSpace) {
                    this.$emit('add_emoji', this.addEmojiToString(emoji.native))
                }
            },
            showEmoji() {
                this.showPicker = true
                this.initPicker = true
            },
            hideEmoji() {
                this.showPicker = false
            },
            addEmojiToString(emoji) {
              const el = document.querySelector(this.element_selector)
              return `${el.value.slice(0, el.selectionStart)} ${emoji} ${el.value.slice(el.selectionEnd)}`
            },
        }
    }
</script>

<style lang="scss" scoped>
    .emoji-btn-component {
        .emoji-mart {
            position: absolute;
            right: 40px;
            bottom: -420px;
            z-index: 20;
        }
    }
    @media (max-width: 678px) {
        .emoji-btn-component {
            .emoji-mart {
                z-index: 100;
                left: 0;
                width: 100% !important;
            }
        }
    }
</style>