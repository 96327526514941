import axios from 'axios'
import filters from '../../helpers/filters'

const state = {
  list: []
}

const actions = {
  create({commit, state, getters}, payload) {
    let {uid} = payload,
        image = getters.getImageByUid(uid)

    if (!image.api_request_processing) {
      commit('UPDATE', {uid, api_request_processing: true})
      return new Promise((resolve, reject) => {
        axios.post('/old_images', {image: {
            "file_name": image.origin_name,
            "file_size": image.origin_size,
            "file_type": image.origin_type,
                "width": image.width,
                "height": image.height,
          }
        })
        .then((response) => {
          commit('UPDATE', {uid, ...response.data.response})
          resolve()
        }).catch((error) => {
          if (error.response.data.status === 422) {
            const processing_errors = error.response.data.message
            commit('UPDATE', {uid, processing_errors })
          } else {
            let errors = { 'api_error': ["post request is failed"] }
            commit('UPDATE', {uid, processing_errors: errors})
          }
          reject("Failed to create image")
        }).then(() => {
          commit('UPDATE', {uid, api_request_processing: false})
        })
      })
    } else {
      console.error('POST /images is already processing')
    }
  },
  upload({commit, state, getters}, payload) {
    let {uid} = payload,
        image = getters.getImageByUid(uid),
        signedUrl = image.upload_links.put_url,
        publicUrl = image.upload_links.public_url,
        file = image.croppedImageFile
    commit('UPDATE', {uid, status: 'UPLOADING'})
    return new Promise((resolve, reject) => {
      axios.put(signedUrl, file,{
        headers: {'Content-Type': file.type},
        onUploadProgress: function (progressEvent) {
          let percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          commit('UPDATE', {uid, upload_percentage: percent})
        }
      }).then((response) => {
        commit('UPDATE', {uid, ...{status: 'UPLOADED', origin_s3_path: publicUrl}})
        resolve()
      }).catch((error) => {
        let errors = { 'Upload to storage error': ["Please try again later"] }
        commit('UPDATE', {uid, processing_errors: errors})
      })
    })
  },
  update({commit, getters}, payload) {
    let {uid} = payload,
        image = getters.getImageByUid(uid)
        
    if (!image.api_request_processing) {
      commit('UPDATE', {uid, api_request_processing: true})
      return new Promise((resolve, reject) => {
        axios.put(`/old_images/${image.id}`, {
          image: { remote_avatar_url: image.origin_s3_path }
        }).then((response) => {
          commit('UPDATE', {uid, ...{status: 'COMPLETE'}, ...response.data.response})
          resolve()
        }).catch((error) => {
          if (error.response.data.status === 422) {
            commit('UPDATE', {uid, processing_errors: error.response.data.message})
          } else {
            let errors = { 'api_error': ["put request is failed"] }
            commit('UPDATE', {uid, processing_errors: errors})
          }
          reject("Failed to create image")
        }).then(() => {
          commit('UPDATE', {uid, api_request_processing: false})
        })
      })
    } else {
      console.error('PUT /images/:id is already processing')
    }
  },
}

const new_image = (payload) => {
  return {
    uid: '_' + Math.random().toString(36).substr(2, 9),
    id: '',
    status: 'INITIATED', // 'INITIATED', 'CHECKING', 'PROCESSING', 'UPLOADING', 'UPLOADED', 'COMPLETE', 'ERROR'
    origin_name: '',
    origin_type: '',
    origin_size: '',
    paths: {},
    upload_links: {},
    file: undefined,
    url: '',
    api_request_processing: false,
    processing_errors: {},
    upload_percentage: 0,
    croppedImageUrl: '',
    croppedImageFile: '',
    initImageUrl: '',
    ...payload
  }
}

const mutations = {

  ADD(state, payload) {
    state.list.push(new_image(payload))
  },

  REMOVE(state, payload){
    let {id, uid} = payload
    let index = state.list.findIndex(image => image.id == id || image.uid == uid)
    state.list.splice(index, 1)
  },

  PARSE_FILE(state, payload){
    let {uid, file, status} = payload
    let img = state.list.find(img => img.uid === uid)
    Object.assign(img, {
      status: status || 'CHECKING',
      file: file,
      origin_name: file.name,
      origin_type: file.type,
      origin_size: file.size,
      url: URL.createObjectURL(file)
    })
  },

  UPDATE(state, payload) {
    let {uid} = payload
    let img = state.list.find(img => img.uid === uid)
    Object.assign(img, payload)
    if(payload.hasOwnProperty('processing_errors')){
      let message = ''
      for (let [key, errors] of Object.entries(payload.processing_errors)) {
        message += `<div><b>${filters.capitalize(filters.unSnakeCase(key))}</b>`
        message += `<ul style="margin: 0">`
        for(let error of errors){
          message += `<li>${error}</li>`
        }
        message += '</ul><div>'
      }
      iziToast.error({
        message: message,
        maxWidth: '700px'
      })
    }
  },

  CLEAR(state, payload) {
    let {uid} = payload;
    let img = state.list.find(img => img.uid === uid)
    Object.assign(img, new_image(payload))
  }
}

const getters = {
  getImageByUid: state => uid => {
    return state.list.find(img => img.uid === uid)
  }
}

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
}