import request from '@utils/request'

export function getProfile (id) {
    return request({
        url: `/users/${id}/profile`,
        method: 'get',
        params: {
            other_user_id: id
        }
    })
}

export function addFriend ({right_id, user_id}) {
    return request({
        url: `/friends?right_id=${right_id}&user_id=${user_id}`,
        method: 'post',
    })
}

export function getUserCollections (pagination, id, keyword = '') {
    return request({
        url: `/users/${id}/folders`,
        method: 'get',
        params: {
            key_word: keyword,
            ...pagination
        }
    })
}

export function getUserCards (pagination, id, keyword = '') {
    return request({
        url: `/users/${id}/cards`,
        method: 'get',
        params: {
            key_word: keyword,
            ...pagination
        }
    })
}

export function getUserProducts (pagination, id) {
    return request({
        url: `/users/${id}/products`,
        method: 'get',
        params: {
            ...pagination
        }
    })
}