import request from '@utils/request'

export function getUsersMentions(key_word = '', limit = 5, cancelTokenSource = null) {
    return request({
        url: `/users/mentions`,
        method: 'get',
        params: {
            key_word: key_word,
            limit: limit
        },
        cancelToken: cancelTokenSource.token
    })
}