import request from '@utils/request'

export function getGroup (id) {
    return request({
        url: `/groups/${id}`,
        method: 'get'
    })
}

export function getMembers(payload, id, keyword = '', sort_order = '') {
    return request({
        url: `/groups/${id}/members`,
        method: 'get',
        params: {
            ...payload,
            key_word: keyword,
            sort_order: sort_order
        }
    })
}

export function getCatalogCollections(payload, id, keyword = '', sort_order = '') {
    return request({
        url: `/groups/${id}/folders`,
        method: 'get',
        params: {
            ...payload,
            key_word: keyword,
            sort_order: sort_order
        }
    })
}

export function getCatalogCards(payload, id, keyword = '', sort_order = '') {
    return request({
        url: `/groups/${id}/cards`,
        method: 'get',
        params: {
            ...payload,
            key_word: keyword,
            sort_order: sort_order
        }
    })
}

export function getGroupActivity(payload, id) {
    return request({
        url: `/groups/${id}/activities`,
        method: 'get',
        params: {
            ...payload
        }
    })
}

export function updateGroupSettings(payload) {
    return request({
        url: `/groups/${payload.id}`,
        method: 'put',
        data: {
            ...payload
        }
    })
}

export function deleteGroup(id) {
    return request({
        url: `/groups/${id}`,
        method: 'delete',
    })
}