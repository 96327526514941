<template>
    <div style="height: 100%">
        <div class="fa fa-trash" v-if="uploadedThumbnail.url" @click="uploadedThumbnail.url = null" title="Remove this thumbnail"></div>
        <div style="height: 100%" class="dropzone dropzone-area show" :id="elem_id" title="Drop files here or click to upload." v-bind:class="{ 'dz-disabled': uploadedThumbnail.isDropped, 'dz-processed': uploadedThumbnail.url }">
            <div class="dz-message">
                <div v-if="!for_podcast" class="description-txt add-photo-icon c-dz-progress">{{ status || 'Upload' }}</div>
                <div v-if="for_podcast" class="btn-browse btn-sm c-dz-progress">{{ status || 'Upload' }}</div>
                <div v-if="for_podcast" class="description-txt sub-info">Drop files here or click to upload.</div>
                <div v-show="hasError" class="description-txt error show">
                    <div class="error-message">{{ errorMessage || 'Something went wrong' }}</div>
                </div>
            </div>
            <img v-if="uploadedThumbnail.url" :src="uploadedThumbnail.url"/>
        </div>
    </div>

</template>

<script>

    import axios from "axios";

    const tmpApi = {
        thumbnail: {
            new: function (data) {
                return new Promise((resolve, reject) => {
                    const params = {thumbnail: data};
                    axios.get(`/thumbnails/new`, { params })
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            console.error('Api.thumbnail.new catch', error);
                            reject(error);
                        })
                })
            },
        },
    }

    export default {
        props: ['elem_id', 'max_size', 'img_src', 'for_podcast', 'form_selector'],

        data() {
            return {
                fields: ['name', 'type', 'size', 'duration', 'width', 'height', 'public_url'],
                selector: `${this.form_selector || ''} #${this.elem_id}`,
                Api: tmpApi,
                status: '',
                hasError: false,
                errorMessage: '',
                uploadedThumbnail: {
                    isDropped: false,
                    isProcessed: false,
                    url: false,
                },
            }
        },

        watch: {
            'uploadedThumbnail.url': function (nv, ov) {
                this.status = '';
                // this.img_src = nv;
                this.$emit('update:img_src', nv)
            },
        },

        created() {
            const vm = this;
            if (vm.img_src) {
                // vm.uploadedThumbnail.isDropped = true;
                vm.uploadedThumbnail.isProcessed = true;
                vm.uploadedThumbnail.url = vm.img_src;
            }
            setTimeout(this.initThumbnail);
            this.$root.$on('afterThumbnailUploaded', this.afterUploaded);
            this.$root.$on('clearOwnThumbnail', this.clearOwnThumbnail);
        },

        methods: {
            initThumbnail() {
                const vm = this;
                initThumbnailDropzone({selector: vm.selector, vm}); //this.$el
            },
            afterUploaded(fileData) {
                const vm = this;
                return new Promise((resolve, reject) => {
                    const {public_url} = fileData;
                    vm.uploadedThumbnail.url = public_url;
                    vm.uploadedThumbnail.isDropped = false;
                    resolve()
                });
            },
            clearOwnThumbnail() {
                this.uploadedThumbnail.isDropped = false;
                this.uploadedThumbnail.isProcessed = false;
                this.uploadedThumbnail.url = '';
                this.status = '';
            },
        },
    }
</script>

<style scoped>

</style>