import Comment from '@models/Comment'
import Post from '@models/Post'
import Reply from '@models/Reply'
import Vue from 'vue/dist/vue.esm'
import {
    getCommentsById,
    addComment,
    deleteComment,
    createImage,
    uploadImageToS3,
    updateImage,
    getCommentAttachmentsById,
    updateComment,
    loadReplies,
    addNewReply,
    deleteReply,
    updateReply
} from "../../api/api.comments"
import {eventBus} from '@utils/event_bus'
import helper from '@utils/helper'

const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el.post_id === val ? [...acc, i] : acc), []);

const getDefaultState = () => {
    return {
        pagination: {
            page: 1,
            limit: 2
        },
        showCommentDropdown: null,
        showReplyDropdown: null,
        editableCommentId: null,
        editableComment: null,
        hideCommentEditBody: false,
        hideNewComment: null,
        showAttachCardById: null,
        showReplyList: [],
        showEditedReplyList: [],
        editAttachments: [],
        editReplyList: {},
        attachments: [],
        replyCommentText: {},
        replyPagination: {},
        attachedContent: {
            image_ids: [],
            my_card_ids: [],
            other_card_ids: [],
            my_folder_ids: [],
            other_folder_ids: [],
            my_product_ids: [],
            other_product_ids: [],
            external_url: [],
            url_hash: [],
            attachment_order_id: [],
            attachment_order_type: []
        }
    }
}

const comments = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getCommentAttachments: state => state.attachments,
        getShownCommentIndex: state => state.showCommentDropdown,
        getShownReplyIndex: state => state.showReplyDropdown,
        getEditableCommentId: state => state.editableCommentId,
        getEditableComment: state => state.editableComment,
        getEditAttachments: state => state.editAttachments,
        getHideCommentEditBody: state => state.hideCommentEditBody,
        getShowAttachCardById: state => state.showAttachCardById,
        getHideNewComment: state => state.hideNewComment,
        getReplyCommentText: state => state.replyCommentText,
        getShowReplyList: state => state.showReplyList,
        getEditReplyList: state => state.editReplyList,
        getShowEditedReplyList: state => state.showEditedReplyList
        // getEditReplyList: (state) => (id) => {
        //     return state.editReplyList[id] ? state.editReplyList[id] : false
        // }
    },
    mutations: {
        TOGGLE_SHOW_REPLY_LIST(state, id) {
            if (!state.showReplyList.includes(id)) {
                state.showReplyList.push(id)
            } else {
                const index = state.showReplyList.findIndex(item => item === id)
                state.showReplyList.splice(index, 1)
            }
        },
        UPDATE_REPLY_COMMENT_TEXT(state, {id, text}) {
            Vue.set(state.replyCommentText, id, text)
            // state.replyCommentText[id] = text
        },
        PUSH_ATTACHMENT(state, {data, type}) {
            if (type === 'new') {
                state.attachments.push(data)
            } else {
                state.editAttachments.push(data)
                if (data.selector !== 'photo') {
                    state.attachedContent[`${data.selector}_ids`].push(data.id)
                    state.attachedContent.attachment_order_type.push(data.selector)
                } else {
                    state.attachedContent.image_ids.push(data.id)
                    state.attachedContent.attachment_order_type.push('photo')
                }
                state.attachedContent.attachment_order_id.push(data.id)
            }
        },
        UPDATE_HIDE_NEW_COMMENT(state, id) {
            state.hideNewComment = id
        },  
        UPDATE_SHOW_ATTACH_CARD_BY_ID(state, id) {
            state.showAttachCardById = id
        },
        CLEAR_ATTACHED_CONTENT(state) {
            state.attachedContent = {
                image_ids: [],
                my_card_ids: [],
                other_card_ids: [],
                my_folder_ids: [],
                other_folder_ids: [],
                my_product_ids: [],
                other_product_ids: [],
                external_url: [],
                attachment_order_id: [],
                attachment_order_type: []
            }
        },
        REMOVE_ATTACHMENT(state, {data, isEdit}) {
            const newData = helper.removeObjectFromArray(state[`${isEdit ? 'editAttachments' : 'attachments'}`], 'id', data.id)
            state[`${isEdit ? 'editAttachments' : 'attachments'}`] = newData
            if (isEdit) {
                if (data.selector !== 'photo') {
                    let index = state.attachedContent[`${data.selector}_ids`].findIndex(item => item === data.id)
                    state.attachedContent[`${data.selector}_ids`].splice(index, 1)
                  } else {
                    let index = state.attachedContent.image_ids.findIndex(item => item === data.id)
                    state.attachedContent.image_ids.splice(index, 1)
                  }
                 
                  const indexInAttachedContent = state.attachedContent.attachment_order_id.findIndex(item => item === data.id)
                  state.attachedContent.attachment_order_id.splice(indexInAttachedContent, 1)
                  state.attachedContent.attachment_order_type.splice(indexInAttachedContent, 1)
            }
        },
        CLEAR_ATTACHMENTS_BY_POST_ID(state, id) {
            const indexes = indexOfAll(state.attachments, id).reverse()
            indexes.forEach((index) => {
                state.attachments.splice(index, 1)
            })

        },
        UPDATE_EDITABLE_COMMENT_ID(state, id) {
            state.editableCommentId = id
        },
        UPDATE_EDITABLE_TEXT(state, text) {
            state.editableComment.content = text
        },
        UPDATE_EDITABLE_MENTIONS(state, mentions) {
            state.editableComment.mentions = mentions
        },
        ADD_ATTACH_LINK(state, link) {
            state.editableComment.url_hash.push(link)
        },
        DELETE_ATTACH_LINK(state, link) {
            let index = state.editableComment.url_hash.findIndex(item => item.url === link.url)
            state.editableComment.url_hash.splice(index, 1)
        },
        SHOW_COMMENT_DROPDOWN(state, index) {
            state.showCommentDropdown = index
        },
        SHOW_REPLY_DROPDOWN(state, index) {
            state.showReplyDropdown = index
        },
        SET_EDITABLE_COMMENT(state, data) {
            const comment = Object.assign({}, data)
            state.editableComment = comment
        },
        CLEAR_EDIT_ATTACHMENT(state) {
            state.editAttachments = []
        },
        HIDE_COMMENT_DROPDOWN(state, index) {
            if (state.showCommentDropdown === index) {
                state.showCommentDropdown = null
            }
        },
        HIDE_REPLY_DROPDOWN(state, index) {
            if (state.showReplyDropdown === index) {
                state.showReplyDropdown = null
            }
        },
        UPDATE_HIDE_COMMENY_EDIT_BODY(state, value) {
            state.hideCommentEditBody = value
        },
        UPDATE_REPLY_PAGINATION(state, {id, pagination}) {
            state.replyPagination[id] = pagination
        },
        UPDATE_REPLY_PAGINATION_PAGE(state, id) {
            state.replyPagination[id].page += 1
        },
        REPLY_ALL_LOADED(state, id) {
            state.replyPagination[id].loaded = true
        },
        CLEAR_REPLY_COMMENT_TEXT(state, id) {
            state.replyCommentText[id] = ''
        },
        UPDATE_EDITABLE_REPLY(state, data) {
            Vue.set(state.editReplyList, data.id, data)
            // state.editReplyList[data.id] = data
            if (!state.showEditedReplyList.includes(data.id)) {
                state.showEditedReplyList.push(data.id)
            }
        },
        UPDATE_EDIT_REPLY_TEXT(state, {id, text}) {
            state.editReplyList[id].content = text
        },
        CANCEL_EDIT_REPLY(state, id) {
            const index = state.showEditedReplyList.findIndex(item => item === id)
            delete state.editReplyList[id]
            state.showEditedReplyList.splice(index, 1)
        }
    },
    actions: {
        CLEAR_REPLY_COMMENT_TEXT({commit}, id) {
            commit('CLEAR_REPLY_COMMENT_TEXT', id)
        },
        async UPDATE_REPLY({state, commit}, reply) {
            const data = {
                id: reply.id,
                is_reply: true,
                parent_comment_id: reply.parent_comment_id, 
                is_comment_update: true,
                content: state.editReplyList[reply.id].content.replace(/\n/g, '%0D%0A'),
                user_activity:{
                    activity:'update comment in group post',
                   action_name:'Post Comment Update'
                }
            }
            const response = await updateReply(data)
            if (response.data.status === 200) {
                Reply.update({
                    where: reply.id,
                    data: response.data
                })
                commit('CANCEL_EDIT_REPLY', reply.id)
            }
        },
        CANCEL_EDIT_REPLY({commit}, id) {
            commit('CANCEL_EDIT_REPLY', id)
        },
        UPDATE_EDIT_REPLY_TEXT({commit}, {id, text}) {
            commit('UPDATE_EDIT_REPLY_TEXT', {id, text})
        },
        async EDIT_REPLY({commit}, payload) {
            commit('UPDATE_EDITABLE_REPLY', payload.payload)
            commit('SHOW_REPLY_DROPDOWN', null)
        },
        async DELETE_REPLY({commit}, payload) {
            const data = {
                id: payload.payload.id,
                is_comment_delete: true,
                user_activity: {
                    activity: "delete comment in group post",
                    action_name: "Delete Comment"
                }
            }
            const response = await deleteReply(data)
            if (response.data.status === 200) {
                Reply.delete(payload.payload.id)
                const comment = Comment.find(payload.payload.parent_comment_id)
                Comment.update({
                    where: payload.payload.parent_comment_id,
                    data: {
                        reply_count: comment.reply_count - 1
                    }
                })
            }
        },
        async LOAD_REPLIES({state, commit}, id) {
            const response = await loadReplies(state.replyPagination[id], id)
            Reply.insertOrUpdate({data:response.data.response})
            if (response.data.total_pages === response.data.current_page) {
                commit('REPLY_ALL_LOADED', id)
            }
            commit('UPDATE_REPLY_PAGINATION_PAGE', id)
        },
        async TOGGLE_SHOW_REPLY_LIST({state, commit}, id) {
            if (!state.showReplyList.includes(id)) {
                if (!state.replyPagination[id]) {
                    const pagination = {
                        page: 1,
                        limit: 5
                    }
                    commit('UPDATE_REPLY_PAGINATION', {id, pagination})
                }
                
                const response = await loadReplies(state.replyPagination[id], id)
                if (response.data.total_pages === response.data.current_page) {
                    commit('REPLY_ALL_LOADED', id)
                }
                Reply.insertOrUpdate({data:response.data.response})
                commit('UPDATE_REPLY_PAGINATION_PAGE', id)
            }
            commit('TOGGLE_SHOW_REPLY_LIST', id)
        },
        UPDATE_REPLY_COMMENT_TEXT({commit}, {id, text}) {
            commit('UPDATE_REPLY_COMMENT_TEXT', {id, text})
        },
        UPDATE_HIDE_NEW_COMMENT({commit}, id) {
            commit('UPDATE_HIDE_NEW_COMMENT', id)
        },
        UPDATE_HIDE_COMMENY_EDIT_BODY({commit}, value) {
            commit('UPDATE_HIDE_COMMENY_EDIT_BODY', value)
        },
        UPDATE_SHOW_ATTACH_CARD_BY_ID({commit}, id) {
            commit('UPDATE_SHOW_ATTACH_CARD_BY_ID', id)
        },
        async fetch({commit, state}, data){
            const response = await getCommentsById({post_id: data.id, limit: data.limit, page: data.page})
            if (response.data.status === 200 && response.data.response) {
                Comment.insertOrUpdate({data: response.data.response})
            }
            return response.data
        },
        async PUSH_COMMENT({commit}, data) {
            const response = await addComment({...data})
            if (response.data.status === 200) {
                Comment.insert({data: response.data.response})
            }
            const post = Post.find(data.post_id)
            Post.update({
                where: data.post_id,
                data: {
                    comment_count: post.comment_count + 1
                }
            })
            return Comment.find(response.data.response.id)
        },
        async PUSH_REPLY_COMMENT({state, commit}, id) {
            const data = {
                is_reply: true,
                is_comment_update: true,
                parent_comment_id: id,
                content: state.replyCommentText[id].replace(/\n/g, '%0D%0A')
            }
            const response = await addNewReply(data)
            if (response.data.status === 200) {
                Reply.insertOrUpdate({data: response.data.response})
                const comment = Comment.find(id)
                Comment.update({
                    where: id,
                    data: {
                        reply_count: comment.reply_count + 1
                    }
                })
            }
            commit('CLEAR_REPLY_COMMENT_TEXT', id)
            
        },
        UPDATE_EDITABLE_TEXT({commit}, text) {
            commit('UPDATE_EDITABLE_TEXT', text)
        },
        UPDATE_EDITABLE_MENTIONS({commit}, mentions) {
            commit('UPDATE_EDITABLE_MENTIONS', mentions)
        },
        ADD_ATTACH_LINK({commit}, payload) {
            commit('ADD_ATTACH_LINK', payload)
        },
        DELETE_ATTACH_LINK({commit}, payload) {
            commit('DELETE_ATTACH_LINK', payload)
        },
        async UPLOAD_IMAGE({commit}, {data, file}) {
            try {
                const response = await createImage(data)
                const upload_links = response.data.response.upload_links
                const id = response.data.response.id
                let formData = new FormData()
                formData.append('image[remote_avatar_url]', upload_links.public_url)
                formData.append('image[remote_full_avatar_url]', upload_links.full_avatar_public_url)
                await uploadImageToS3({data: response.data.response, file: file}, {
                    onUploadProgress: function (progressEvent) {
                        console.log('UPLOAD_IMAGE onUploadProgress', progressEvent)
                        // console.log(`uploaded: ${parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))}%`)
                    },
                })
                const putResponse = await updateImage(formData, id)
                return putResponse
            } catch (error) {
                console.log('UPLOAD_IMAGE', error)
                const processing_errors = error.data.message
                Object.keys(processing_errors).forEach(function (k) {
                    let error = `${k}: ${processing_errors[k]}`;
                    if (k === 'space_limited') {
                        const user = JSON.parse(localStorage.getItem('user'));
                        const is_plus_user = user && user.is_plus_account
                        const no_plus_part = is_plus_user ? `<span>, or upgrade to <a href="/subscriptions" target="_blank" @click.stop="">Plus account</a></span>` : ''
                        error = `The file you are trying to upload exceeds the allotted storage. Please clean your <a href="/my_storage"  target="_blank" @click.stop="">Storage</a> first before attempting to re-upload it${ no_plus_part }.`
                    }
                    iziToast.error({ message: error, maxWidth: '700px' })
                });
            }
        },
        SHOW_COMMENT_DROPDOWN({commit}, index) {
            commit('SHOW_COMMENT_DROPDOWN', index)
        },
        SHOW_REPLY_DROPDOWN({commit}, index) {
            commit('SHOW_REPLY_DROPDOWN', index)
        },
        RESET_PAGINATION({commit}) {
            commit('RESET_PAGINATION')
        },
        HIDE_COMMENT_DROPDOWN({commit}, index) {
            commit('HIDE_COMMENT_DROPDOWN', index)
        },
        HIDE_REPLY_DROPDOWN({commit}, index) {
            commit('HIDE_REPLY_DROPDOWN', index)
        },
        PUSH_ATTACHMENT({commit}, {data, type}) {
            commit('PUSH_ATTACHMENT', {data, type})
        },
        REMOVE_ATTACHMENT({commit}, data) {
            commit('REMOVE_ATTACHMENT', data)
        },
        async PUSH_EDITED_COMMENT({state, commit}, {comment, post}) {
            let formatedProps = JSON.parse(JSON.stringify(state.attachedContent))
            Object.keys(formatedProps).forEach(key => {
                if (formatedProps[key].length === 0) {
                  formatedProps[key].push('')
              } 
            })
            
            const data = {
                post_id: post.id,
                content: state.editableComment.content.replace(/\n/g, '%0D%0A'),
                mentions: state.editableComment.mentions,
                is_reply: false,
                parent_comment_id: '',
                external_url: state.editableComment.external_url,
                url_hash: (state.editableComment.url_hash.length ? state.editableComment.url_hash : ['']),
                is_comment_created: true,
                user_activity: {
                  activity: 'update comment in group post',
                  action_name: 'Post Comment Update'
                },
                ...formatedProps
            }
            const response = await updateComment(comment.id, data)
            Comment.update({
                where: comment.id,
                data: response.data
            })
        },
        async CLEAR_EDITABLE_COMMENT ({commit}) {
            commit('UPDATE_EDITABLE_COMMENT_ID', null)
            commit('SET_EDITABLE_COMMENT', null)
        },
        async EDIT_COMMENT ({commit, rootGetters}, data) {
            commit('SHOW_COMMENT_DROPDOWN', null)
            commit('UPDATE_HIDE_NEW_COMMENT', null)
            commit('UPDATE_SHOW_ATTACH_CARD_BY_ID', null)
            commit('UPDATE_HIDE_COMMENY_EDIT_BODY', false)
            commit('CLEAR_ATTACHED_CONTENT')
            commit('CLEAR_EDIT_ATTACHMENT')
            commit('UPDATE_EDITABLE_COMMENT_ID', data.payload.id)
            commit('SET_EDITABLE_COMMENT', data.payload)
            const response = await getCommentAttachmentsById(data.payload.id)
            const userId = rootGetters['auth/getUserInfo'].id
            if (response.data.status === 200) {
                if (response.data.response.images) {
                    response.data.response.images.items.forEach(item => {
                        let image = {
                            type: 'Image',
                            selector: 'photo',
                            ...item
                        }
                        commit('PUSH_ATTACHMENT', {data: image, type: 'edit'})
                    })
                }
                if (response.data.response.cards) {
                    response.data.response.cards.items.forEach(item => {
                        let selector = userId === item.creator_id ? 'my_card' : 'other_card'
                        let coverimg = item.cover_photo ? item.cover_photo : process.env.DEFAULT_IMAGE
                        let card = {
                            ...item,
                            type: 'Card',
                            selector: selector,
                            cover_photo: coverimg,
                        }
                        commit('PUSH_ATTACHMENT', {data: card, type: 'edit'})
                    })
                }
                if (response.data.response.products) {
                    response.data.response.products.items.forEach(item => {
                        let selector = userId === item.creator.id ? 'my_product' : 'other_product'
                        let coverimg = item.cover_photo ? item.cover_photo : process.env.DEFAULT_IMAGE
                        let product = {
                            ...item,
                            type: 'Product',
                            selector: selector,
                            cover_photo: coverimg,
                        }
                        commit('PUSH_ATTACHMENT', {data: product, type: 'edit'})
                    })
                }
                if (response.data.response.folders) {
                    response.data.response.folders.items.forEach(item => {
                        let selector = userId === item.owner.id ? 'my_folder' : 'other_folder'
                        let folder = {
                            type: 'Folder',
                            selector: selector,
                            ...item
                        }
                        commit('PUSH_ATTACHMENT', {data: folder, type: 'edit'})
                    })
                }
            }
        },
        OPEN_DELETE_REPLY_MODAL({commit}, data) {
            Vue.prototype.$modal.show('delete-reply', {reply: data})
        },
        OPEN_DELETE_COMMENT_MODAL({commit}, data) {
            Vue.prototype.$modal.show('delete-comment', {comment: data})
        },
        CLEAR_ATTACHMENTS_BY_POST_ID({commit}, id) {
            commit('CLEAR_ATTACHMENTS_BY_POST_ID', id)
        },
        async DELETE_COMMENT ({commit}, data) {
            const response = await deleteComment(data.payload)
            if (response.data.status === 200) {
                Comment.delete(data.payload.id)
                commit('SHOW_COMMENT_DROPDOWN', null)
                const post = Post.find(data.payload.post_id)
                eventBus.$emit('delete-comment', {data: {id: data.payload.id}} )
                Post.update({
                    where: data.payload.post_id,
                    data: {
                        comment_count: post.comment_count - 1
                    }
                })
            }
        },
    }
}

export default comments