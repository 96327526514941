<template>
    <div class="poster-from-timeline-component" :class="className">
        <div class="timeline_thumbnail" v-if="video_file_url">
            <video ref="video" :src="video_file_url" type="video/mp4" controls></video>
            <div @click="setTimelineThumbnail()" class="btn-browse btn-sm">Select this frame</div>
            <canvas ref="canvas" class="hide"></canvas>
        </div>
    </div>
</template>

<script>
    import {mapDynamicFields} from "./helpers/mapDynamicFields"

    export default {
        props: ['kind', 'className'],
        data: function () {
            return {
                spath: `Videos.uploads.main`,
            }
        },
        computed: {
            ...mapDynamicFields('spath', {
                video_file: 'file',
                selectTimelineMode: 'selectTimelineMode',
            }),
            video_file_url: function () { return this.video_file ? URL.createObjectURL(this.video_file) : '' }
        },
        methods: {
            setTimelineThumbnail() {
                const vm = this;

                const video = this.$refs.video;
                const canvas = this.$refs.canvas;
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                const imgDataURL = canvas.toDataURL("image/jpeg");//.replace("image/jpeg", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.

                //return a promise that resolves with a File instance
                function urltoFile(url, filename, mimeType) {
                    return (fetch(url)
                            .then(function (res) { return res.arrayBuffer() })
                            .then(function (buf) { return new File([buf], filename, {type: mimeType}) })
                    );
                }
                urltoFile(imgDataURL, 'timeline.jpeg', 'image/jpeg').then(function (file) {
                    vm.$root.$emit('setTimelineThumbnail', file);
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .poster-from-timeline-component {
        position: relative;
        .btn-sm {
            position: absolute;
            opacity: 0.7;
            top: calc(50% - 25px);
            right: calc(50% - 75px);
            transition: all 0.2s linear;
        }
        video {
            width: 100%;
            height: 200px;
        }
        &.cardForm {
            video {
                height: 246px;
            }
        }
    }

</style>