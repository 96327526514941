<template>
<div class="add-card-collection-btn-component">
    <button v-tooltip="$t('FEED.ATTACH_CARD')" class="btn__reset" @click="handleClick">
        <i class="icon-card" />
    </button>
</div>
</template>

<script>
    export default {
        props: {
            showSearchCardView: {type: Function}, //default: null
        },
        mounted() {
            const that = this
            this.$root.$on('searchCardCollection:chosen', function (item) {
                that.$emit('chosen', item);
            })
            //window.dispatchEvent(new CustomEvent('card_created', { detail: card }));
            window.addEventListener('card_created', (e) => {
                if (e.detail?.id) {
                    that.$emit('chosen', { selector: 'my_card', ...e.detail });
                    this.$modal.hide('search-card-collection')
                }
            })
        },
        methods: {
            handleClick() {
                if (this.showSearchCardView) {
                    this.showSearchCardView()
                } else {
                    this.$modal.show('search-card-collection')
                }
            },
        }
    }
</script>