import Post from '@models/Post'
import Vue from 'vue/dist/vue.esm'
import { createPost, getPosts, updatePost, likePost, searchAttachments, deletePost, getPost} from "../../api/api.posts";

const getDefaultState = () => {
    return {
        pagination: {
            page: 1,
            per_page: 10,
            limit: 10,
            count: 10
        },
        comment: {
            attachments: []
        },
        searchedAttachments: {}
    }
}

const posts = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getSearchedAttachments: state => state.searchedAttachments
    },
    mutations: {
        UPDATE_PAGINATION(state) {
            state.pagination.page += 1
        },
        RESET_PAGINATION(state) {
            state.pagination = {
                page: 1,
                per_page: 10
            }
        },
        SET_SEARCH_ATTACH_RESULT(state, result) {
            result['my'] ? Object.assign(result['my'], {type: 'my'}) : null
            result['other'] ? Object.assign(result['other'], {type: 'other'}) : null
            state.searchedAttachments = result
        },
        CLEAR_POST_FORM(state, id) {
            // state.pagination.page += 1
        },
    },
    actions: {
        async fetch({commit, state}, data){
            const response = await getPosts(state.pagination, 'feed')
            if (response.data.response) {
                Post.dispatch('UPDATE_PAGINATION')
                Post.insert({data: response.data.response})

                let ms,
                    newDateForPost

                    ms = Date.parse(response.data.response[0].created_at)
                    newDateForPost = new Date(ms + 1)
                    Post.insert({data: {follow_frequency: true, created_at: newDateForPost.toISOString()}})
            }
            return response
        },
        UPDATE_PAGINATION({commit}) {
            commit('UPDATE_PAGINATION')
        },
        RESET_PAGINATION({commit}) {
            commit('RESET_PAGINATION')
        },
        async CREATE_POST({state, commit}, payload) {
            const response = await createPost(payload)
            if (response.data.status === 200) {
                // Post.delete(payload.id)
                Post.insertOrUpdate({data: response.data.response})
            }
        },
        async UPDATE_POST({state, commit}, payload) {
            const response = await updatePost(payload)
            if (response.data.status === 200) {
                Post.insertOrUpdate({data: response.data.response})
            }
        },
        EDIT_POST ({commit}, data) {
            Vue.prototype.$modal.show('post-form', {post: data.payload})
        },
        async DELETE_POST_BY_ID ({commit}, data) {
            const response = await deletePost(data.id)
            if (response.data.status === 200) {
                Post.delete(data.id)
            }
        },
        OPEN_DELETE_POST_MODAL({commit}, data) {
            Vue.prototype.$modal.show('delete-post', {post: data})
        },
        FLAG_POST ({commit}, data) {
            window.open(`/flags/Post/${data.payload.id}`, '_blank')
        },
        async SEARCH_ATTACHMENTS({commit}, keyword) {
            if (keyword) {
                const response = await searchAttachments(keyword)
                if (response.status === 200) {
                    if (response.data.response) {
                        commit('SET_SEARCH_ATTACH_RESULT', response.data.response)
                    }
                    if (response.data.message === 'No content available to attach') {
                        commit('SET_SEARCH_ATTACH_RESULT', [])
                    }
                } 
            } else {
                commit('SET_SEARCH_ATTACH_RESULT', {})
            }
            
        },
        async GET_POST({commit}, id) {
            const response = await getPost(id)
            if (response.data.status === 200 && response.data.response) {
                Post.insert({data: response.data.response})
            }
            return response
        },
        async LIKE_POST ({commit}, post_id) {
            const response = await likePost(post_id)
            const post = Post.query().whereId(post_id).first()
            if (response.data.message === 'Post successfully liked.') {
                Post.update({
                    where: post_id,
                    data: {
                        likes: post.likes +1,
                        liked: true
                    }
                })
            } else {
                Post.update({
                    where: post_id,
                    data: {
                        likes: post.likes -1,
                        liked: false
                    }
                })
            }

        }
    }
}

export default posts