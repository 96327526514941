<template>
<div class="post-form-component" :class="{'sharing-post': isSharingPost}">
    <div class="modal-header">
        <b>{{ $t('POST_FORM.' + (isSharingPost ? 'SHARE_POST' : (post.isNew ? 'NEW_POST' : 'EDIT_POST'))) }}</b>
    </div>

    <div>
        <div class="post-text" :class="{'with-text': !!post.description}">
            <PowerArea id="new-post-power-area"
                       :maxlength="25000"
                       :placeholder=" isSharingPost ? 'Add your comment here' : 'Add your post text here...'"
                       :value="{text: post.description, mentions: post.mentions}"
                       @paste="onPaste"
                       @change="changePost" />
        </div>

        <vue-scroll v-if="isSharingPost" class="share-post-preview" :ops="{scrollPanel: {maxHeight: 450}, rail: {opacity: 0.3}, bar: {keepShow: true}}">
            <PostPreview
                :shareable="true"
                :post="post.share_post"
                :user="currentUser" />
        </vue-scroll>

        <div v-if="!isSharingPost">
            <div class="auto-embed-div-append`">
                <TextEmbed v-if="textEmbed"
                           :embed_url_info="textEmbed"
                           :with_delete_btn="true"
                           @delete="deleteTextEmbed" />
                <div v-if="pasteLinkParsing" class="spinner-border" />
            </div>

            <AttachURLModal
                v-if="showAttachURL"
                @close="toggleShowAttachURL(false)"
                @addLink="addLink" />

            <ParsedLinks :links="post.url_hash" :editable="true" @deleteLink="deleteLink" />

            <EditAttachments ref="EditAttachments" :post="post" @delete="attachmentDelete" />
        </div>
    </div>

    <div v-if="post.privacy === 'friends_group'" class="modal-footer friends_group">
        <SelectListsOfFriends ref="SelectListsOfFriends" :friends_group="post.group_ids" @changed="post.group_ids = $event" />
        <AddFriendListBtn @created="friendsListCreated" />
    </div>

    <div class="modal-footer">
        <Privacy
            v-if="!groupFeed"
            :privacy="post.privacy"
            @change_privacy="post.privacy = $event" />

        <div class="footer-middle">
            <AddPhotoBtn v-if="!isSharingPost" @uploading="setAttachLoading" @addImage="addAttachment" />
            <AddCardCollectionBtn v-if="!isSharingPost" :show-search-card-view="showSearchCardView" @chosen="addAttachment" />
            <AddLinkBtn v-if="!isSharingPost"
                        :url_hash="post.url_hash"
                        :show-add-link-view="showAddLinkView"
                        @changed="post.url_hash = $event" />
            <EmojiBtn element_selector="#new-post-power-area textarea" @add_emoji="post.description = $event" />
        </div>

        <div class="footer-end">
            <a href="#"
               class="btn btn__round btn__bordered savePost"
               :class="{ disabled: submitFormDisabled }"
               @click.prevent.stop="savePost">
                {{ $t('BUTTON.' + (isSharingPost ? 'SHARE' : 'POST')) }}
                <div v-if="requestInProgress" class="spinner-border" />
            </a>
        </div>
    </div>
</div>
</template>


<script>
import Post from '@models/Post'
import Image from '@models/Image'
import Privacy from "./Privacy";
import AddPhotoBtn from "./AddPhotoBtn";
import AddCardCollectionBtn from "./AddCardCollectionBtn";
import AddLinkBtn from "./AddLinkBtn";
import EditAttachments from "./EditAttachments";
import AttachURLModal from '../feed/AttachURLModal'
import ParsedLinks from './ParsedLinks'
import {mapActions, mapGetters} from "vuex";
import TextEmbed from "../Preview/TextEmbed";
import PostPreview from "../feed/PostPreview";
import PowerArea from "@kit/forms/PowerArea";
import EmojiBtn from "@kit/forms/EmojiBtn";
import AddFriendListBtn from "./Privacy/AddFriendListBtn"
import SelectListsOfFriends from "./Privacy/SelectListsOfFriends"
import { getCard } from "@api/api.cards";
import { getProduct } from "@api/api.products";

    const newPostData = () => ({
        isNew: true,
        description: '',
        share_post_id: null,
        my_group_id: null,
        image_ids: [],
        attachment_order_id: [],
        attachment_order_type: [],
        url_hash: [], //[{url:'', title:'', image:''}]
        privacy: 'friends', //public|friends|friends_group
        friends_group: [],
        group_ids: [],
        mentions: []
    })

    export default {
        components: {
            EditAttachments, AttachURLModal, ParsedLinks,
            AddPhotoBtn, AddCardCollectionBtn, AddLinkBtn,
            TextEmbed, Privacy, PowerArea, EmojiBtn, PostPreview,
            AddFriendListBtn, SelectListsOfFriends
        },
        props: {
            post_init: {type: Object, default: () => null},
            showAddLinkView: {type: Function},
            showSearchCardView: {type: Function},
        },
        data() {
            return {
                post: {},
                showAttachURL: false,
                pasteLinkParsing: false,
                requestInProgress: false,
            }
        },
        computed: {
            ...mapGetters('auth', {
                currentUser: 'getCurrentUser'
            }),
            submitFormDisabled(){
                return this.requestInProgress ||
                    !(this.post.description || this.post.text_embed_id || this.post.attachment_order_id?.length || this.post.url_hash?.length || this.isSharingPost) ||
                    this.$refs.EditAttachments?.loading || !!this.uploadingImages.length
            },
            textEmbed () {
                return this.post.text_embed
            },
            isSharingPost () {
                return !!this.post.share_post
            },
            groupFeed () {
                return !!this.post.my_group_id
            },
            uploadingImages() {
                return Image.query().where((img) => this.post.attachment_order_id.includes(img.id) && img.processing_stage !== 'COMPLETED').get()
            },
        },
        beforeMount() {
            if (this.post_init) {
                this.post = {...newPostData(), isNew: !this.post_init.id, ...JSON.parse(JSON.stringify(this.post_init))}
            } else {
                this.post = new Post(newPostData())
            }
        },
        async mounted(){
            if (this.post.with_card_id) {
                this.setAttachLoading(true)
                const card = (await getCard({ id: this.post.with_card_id, post_on_sphir: true })).data.response
                this.addAttachment({
                    selector: card?.creator_id === this.currentUser?.id ? 'my_card' : 'other_card',
                    ...card
                })
                this.setAttachLoading(false)
            } else if (this.post.with_product_id) {
                this.setAttachLoading(true)
                const product = (await getProduct({ id: this.post.with_product_id, post_on_sphir: true })).data.response
                this.addAttachment({
                    selector: product?.user_id === this.currentUser?.id ? 'my_product' : 'other_product',
                    ...product
                })
                this.setAttachLoading(false)
            }
        },
        methods: {
            ...mapActions('embed_url_info', {
                parseLink: 'PARSE_LINK',
            }),
            ...mapActions('entities/posts', {
                createPost: 'CREATE_POST',
                updatePost: 'UPDATE_POST',
            }),
            changePost(key, val) {
              let field
              switch (key) {
                case 'text':
                  field = 'description'
                  break
                case 'mentions':
                  field = 'mentions'
                  break
              }
              this.post[field] = val
            },
            toggleShowAttachURL(state) {
                this.showAttachURL = state
            },
            addLink(link) {
                const {url, title, image} = link
                this.post.url_hash.push({url, image, title})
            },
            deleteLink(link) {
                let index = this.post.url_hash.findIndex(item => item.url === link.url)
                this.post.url_hash.splice(index, 1)
            },
            savePost: async function (e) {
                try {
                    this.requestInProgress = true
                    if (this.post.isNew) {
                        await this.createPost(this.post)
                    } else {
                        await this.updatePost(this.post)
                    }
                    this.$modal.hide('post-form')
                    if (!this.post.my_group_id && !["/users/home", "/users/profile"].includes(this.$router.currentRoute.path)) {
                        this.$router.push({ path: '/users/home' })
                    }
                } catch (e) {
                    console.error('PostForm savePost err:', e)
                    this.$toast.error(this.$t('ERROR_MESSAGE.SOMETHING_WRONG'), 'Error', {position: 'topRight'})
                } finally {
                    this.requestInProgress = false
                }
            },

            async addAttachment(payload) {
                this.$refs.EditAttachments.addAttachment(payload);
                const arr_field = `${payload.selector === 'photo' ? 'image' : payload.selector}_ids` // arr_field = image_ids|my_card_ids|other_card_ids|my_folder_ids|other_folder_ids|my_product_ids|other_product_ids
                if (!this.post[arr_field]) {
                    this.post[arr_field] = []
                }
                this.post[arr_field].push(payload.id)
                this.post.attachment_order_id.push(payload.id)
                this.post.attachment_order_type.push(payload.selector)
            },
            setAttachLoading(state) {
                this.$refs.EditAttachments.setLoading(state);
            },

            attachmentDelete(data) {
                const that = this;
                const arr_fields = ['image_ids', 'my_card_ids', 'other_card_ids', 'my_folder_ids', 'other_folder_ids', 'my_product_ids', 'other_product_ids', 'attachment_order_id'];
                arr_fields.forEach(async function (field) {
                    const index = that.post[field].findIndex(id => id === data.id);
                    if (index > -1) {
                        // if (!that.post.id || that.post.isNew) {
                        that.post[field].splice(index, 1)
                        if (field === 'attachment_order_id') {
                            that.post.attachment_order_type.splice(index, 1)
                        }
                    }
                })
            },
            async onPaste(event) {
                const text = event.clipboardData.getData('text');
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                const urlMatches = text.match(urlRegex);
                if (urlMatches && urlMatches[0]) {
                    this.pasteLinkParsing = true
                    try {
                        const embed_url_info = await this.parseLink({url: urlMatches[0]})
                        if (embed_url_info) {
                            this.post.text_embed_id = embed_url_info.id
                            this.post.text_embed = embed_url_info
                        }
                    } catch (e) {
                        console.warn('onPaste parseResult err', e)
                    } finally {
                        this.pasteLinkParsing = false
                    }
                }
            },
            deleteTextEmbed() {
                this.post.text_embed_id = null
                this.post.text_embed = null
            },
            postUpdate(payload) {
                const that = this
                Object.keys(payload).forEach(key => {
                    that.post[key] = payload[key]
                })
            },
            friendsListCreated(newList) {
                this.post.group_ids.push(newList.id)
                this.$refs.SelectListsOfFriends.addNewFriendsList(newList)
            }
        }
    }
</script>

<style lang="scss"> //unscoped
.post-form-component {
    &.sharing-post {
        textarea {
            height: 24px;
            min-height: 24px;
            border-bottom: 1px solid #D1D3D4;
            transition: height .5s ease-in-out;
        }
        .post-text.with-text textarea {
            height: 100px;
        }
    }
    .footer-middle {
        button {
            font-size: 2rem;
        }
    }
    .share-post-preview {
        .__view {
            max-width: 100%;
        }
    }
}
#new-post-power-area textarea {
  padding: 0;
  width: 100%;
  min-height: 100px;
  border: none;
  resize: none;
}
</style>

<style lang="scss" scoped>
    .post-form-component {
        .btn_submit {
            float: right;
        }
        .post-text {
            margin: 20px 0;
        }
        .modal-footer {
            justify-content: space-between;
            .post-privacy {
                margin-bottom: 0;
            }
            &.friends_group {
                margin-top: 0;
                & > * {
                    margin-top: 20px;
                }
            }
        }
        .footer-middle {
            display: flex;
            justify-content: space-between;
            position: relative;
            > * {
                margin: 0 2px;
            }
        }
        .footer-end {
            text-align: right;
        }
        .spinner-border {
            opacity: 0.4;
            margin-left: 10px;
        }
        &.sharing-post {
            .footer-middle {
                display: block;
            }
            .footer-end {
                flex: auto;
            }
        }
    }
</style>