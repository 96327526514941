<template>
    <div id="video-form-component">
        <input type="hidden" :name="input_name" :id="input_id" :value="video.id">
        <input type="hidden" name="media_video_status" :value="video.status">
        <input type="hidden" name="media_video_available" id="product_media_video_available" :value="video.status === 'COMPLETE'">
        <div class="m-b-40">
            <h6 v-if="!is_card_form">File Upload</h6>
            <p class="small m-b-0">Supported formats: MP4, WEBM, MOV, AVI, MKV, FLV</p>
            <p class="small m-b-0">Minimum duration = 10 seconds</p>
            <p class="small" v-if="is_card_form">Maximum file size 3GB</p>
            <p class="small" v-else>Maximum file size 10GB or 3 hours run-time</p>

            <div class="edit-video" v-if="hasErrors || (video.status === 'COMPLETE' && video_editable)"><a @click="uploadNewVideo()">Upload new Video</a></div>
            <poster-from-timeline v-if="selectTimelineMode"
                                  :class-name="is_card_form ? 'cardForm' : ''"></poster-from-timeline>
            <upload-video v-if="video.status !== 'COMPLETE'" v-show="!selectTimelineMode" :kind="'main'"
                          :for_product="for_product"></upload-video>
            <theo-player v-else v-show="!selectTimelineMode" :kind=" 'main'"></theo-player>

            <div class="set-drm-content" v-if="for_product" :title="video.status === 'COMPLETE' ? 'video already processed' : videoStartTranscode ? 'video in progress' : ''">
                <input class="styled-checkbox" id="drm_content" type="checkbox" :disabled="videoStartTranscode"
                       v-model="digital_access" true-value="private" false-value="protected"/>
                <label class="purchase-label" for="drm_content">DRM content</label>
            </div>

        </div>

        <div class="m-b-40" v-if="can_edit_video">
            <h6>Thumbnail</h6>
            <posters-nav :kind="'main'" :class-name="is_card_form ? 'cardForm' : ''"></posters-nav>
        </div>

        <div v-if="!is_card_form">
            <div class="m-b-40">
                <select-preview></select-preview>
            </div>
            <modal-preview></modal-preview>
        </div>

        <div class="m-b-40" v-if="video.id && can_edit_video">
            <subtitles-nav></subtitles-nav>
        </div>

        <hr v-if="!is_card_form">

        <modal-dont-leave v-if="for_product" :for_product="for_product"></modal-dont-leave>
    </div>
</template>

<script>
    import {mapDynamicFields} from "./helpers/mapDynamicFields"
    import UploadVideo from './UploadVideo'
    import TheoPlayer from './TheoPlayer'
    import PostersNav from './PostersNav'
    import SelectPreview from "./SelectPreview"
    import ModalPreview from "./ModalPreview"
    import ModalDontLeave from "./ModalDontLeave"
    import PosterFromTimeline from "./PosterFromTimeline"
    import SubtitlesNav from "./SubtitlesNav";

    export default {
        props: ['current_user_id', 'holder', 'id', 'video_editable'],
        components: {
            SubtitlesNav, PosterFromTimeline, ModalPreview, ModalDontLeave, UploadVideo, TheoPlayer, PostersNav, SelectPreview},
        data: function() {
            return {
                isValid: false,
                spath: `Videos.uploads.main`,
                on_save_form: false,
            }
        },
        watch: {
            'video.status': function(val){
                if (val === 'UPLOADING') {
                    const vm = this;
                    window.addEventListener('beforeunload', function(event) {
                        if (vm.uploadingProcess && !vm.stay_on_form && (this.for_product && !vm.on_save_form)) {
                            const msg = "Your video is uploading now, so please don't leave yet.";
                            event.preventDefault();  // Cancel the event as stated by the standard.
                            event.returnValue = msg; // Chrome requires returnValue to be set.
                            return msg
                        }
                    });
                } else if (val === 'UPLOADED') {
                    window.addEventListener('beforeunload', function(event) {});
                } else if (val === 'COMPLETE') {
                    if (this.for_product) { setTimeout(window.Create_Products.check_active_pre_sale) }
                }
            },
            'video.id': function (nv, ov) {
                this.$emit('update:media_video_id', nv)
            },
        },
        computed: {
            ...mapDynamicFields('spath', {
                digital_access: 'api_state.digital_access',
                leave_without_confirm: 'ui_state.leave_without_confirm',
                processingErrors: 'processingErrors',
            }),
            videoStartTranscode: function() {
                return !['INITIATED', 'PARSING', 'CREATED', 'UPLOADING'].includes(this.video.status)
            },
            input_name: function () {
                return `${this.holder}[media_video_id]`
            },
            input_id: function () {
                return `${this.holder}_media_video_id`
            },
            video: function () {
                return this.$store.state.Videos.uploads['main'].api_state
            },
            can_edit_video: function () {
                return this.video.can_edit_video || !this.video.id
            },
            preview_id: function(){
                return this.$store.getters['Videos/preview_id']('main')
            },
            preview_type: function(){
                return this.$store.getters['Videos/preview_type']('main')
            },
            preview_status: function () {
                return ((this.$store.state.Videos.uploads[this.preview_type] || {}).api_state || {}).status
            },
            selectTimelineMode: function () {
                return this.$store.state.Videos.uploads['main'].selectTimelineMode
            },
            for_product: function () {
                return this.holder === 'product'
            },
            is_card_form: function () {
                return this.holder === 'card'
            },
            is_reserved: function () {
                return (this.video.status === 'CREATED' && this.video.reserved) || (this.video.status === 'UPLOADING' && this.leave_without_done_uploading)
            },
            is_video_uploaded: function () {
                return ['UPLOADED', 'SUBMITTED', 'PROGRESSING', 'COMPLETE'].includes(this.video.status)
            },
            uploadingProcess: function () {
                return !!this.video.id && !this.leave_without_confirm && ((!this.is_reserved && this.video.status === 'UPLOADING') || this.is_video_uploaded)
            },
            stay_on_form: function () {
                return this.$store.state.Videos.uploads['main'].ui_state.stay_on_form
            },
            leave_without_done_uploading: function () {
                return this.$store.state.Videos.uploads['main'].ui_state.leave_without_done_uploading
            },
            poster_exists: function() {
                return !!(this.video.posters_list && this.video.poster_index && Object.keys(this.video.posters_list[this.video.poster_index]).length)
            },
            hasErrors: function () {
                return Object.keys(this.processingErrors).length > 0
            },
        },
        methods: {
            // coming_soon(event) {
            //     event.preventDefault();
            //     event.stopPropagation();
            //     iziToast.info({ message: 'DRM coming soon!' })
            // },
            async updateVideo(payload = {}) {
                const {card_type} = payload;
                if (this.for_product || !card_type || card_type === "Video"){
                    if (this.isValid){
                        await this.$store.dispatch('Videos/updateVideo', {kind: 'main', ui_state: true})
                    }
                }
                return this.video
            },
            dismissForm(payload) {
                this.$store.dispatch('Videos/deleteVideo', {kind: 'main'})
            },
            closeForm(payload) {
                if (this.video.status === 'INITIATED') { return }
                try {
                    this.leave_without_confirm = false;
                    this.$store.commit('Images/CLEAR', { holder_id: this.video.id });
                    this.$store.commit('Videos/CLEAR', { kind: 'generated' });
                    this.$store.commit('Videos/CLEAR', { kind: 'trailer' });
                } catch (e) {
                    console.log(e)
                }
                this.$store.commit('Videos/CLEAR', { kind: 'main' });
            },
            validate() {
                let is_video_selected = !!this.video.id,
                    is_video_uploaded = ['UPLOADED', 'SUBMITTED', 'PROGRESSING', 'COMPLETE'].includes(this.video.status),
                    is_preview_selected = this.preview_id && this.preview_id !== 'none',
                    is_preview_uploaded = ['UPLOADED', 'SUBMITTED', 'PROGRESSING', 'COMPLETE'].includes(this.preview_status),
                    is_product_pre_sale = window.Create_Products && window.Create_Products.is_active_pre_sale(),
                    valid_for_pre_sale = is_video_selected && (is_preview_uploaded || this.poster_exists);
                // video
                if (!is_video_selected && !this.for_product) {
                    iziToast.error({title: 'Error', message: 'Please upload video.', position: 'topRight'})
                } else if (this.for_product && is_product_pre_sale && !valid_for_pre_sale) {
                    iziToast.error({title: 'Error', message: 'Please upload at least a Thumbnail or Trailer', position: 'topRight'})
                } else if (this.uploadingProcess && this.video.status === 'UPLOADING') {
                    iziToast.error({title: 'Error', message: 'Please finish video uploading.', position: 'topRight'})
                    // this.$modal.show('dont-leave-modal')
                }
                // preview
                if (this.for_product && !is_product_pre_sale) {
                    const message = !is_preview_selected ? 'Please upload preview.' : !is_preview_uploaded ? 'Please finish preview uploading.' : null;
                    if (message) { iziToast.error({title: 'Error', message, position: 'topRight'}) }
                }
                this.isValid = is_video_selected && (is_video_uploaded || this.is_reserved) &&
                    (!this.for_product || (is_preview_selected && is_preview_uploaded) || (is_product_pre_sale && valid_for_pre_sale));
                return this.isValid
            },
            prepareToEdit() {
                this.$store.commit('Videos/PREPARE_TO_EDIT', {kind: 'main'});
            },
            uploadNewVideo() {
                this.prepareToEdit()
                this.$root.$emit('upload_new_video')
            }
        },
        created() {
            if (this.for_product) {
                window.Vue2ProductVideoForm = this
            } else {
                window.Vue2VideoForm = this
                if ($) {
                    const form_modals = '.modal-create-card, .modal-edit-card, .modal-create-post-card, .modal-create-comment-card'
                    $(form_modals).on('show.bs.modal', this.closeForm)
                }
            }
            if (this.current_user_id){
                this.$store.commit('updateField', {path: 'current_user_id', value: this.current_user_id})
            }
            if (this.id) {
                this.$store.dispatch('Videos/fetch', {kind: 'main', id: this.id}).then(() => {
                    if (!this.is_card_form && this.preview_id && this.preview_id !== 'none') {
                        this.$store.dispatch('Videos/fetch', {kind: this.preview_type, id: this.preview_id})
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #video-form-component {
        .set-drm-content {
            margin-top: 10px;
            display: inline-block;
        }
        .styled-checkbox[disabled] + label {
            opacity: 0.5;
            cursor: not-allowed;
        }
        .edit-video {
            text-align: right;
            a {
                cursor: pointer;
            }
        }
    }
</style>