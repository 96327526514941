<template>
<div
    class="search-item-component"
    @click="$emit('click')">
    <div class="item-cover" :style="`background-image: url(${ img_src })`" />
    <div class="textContent">
        <div v-html="sub_title" />
        <div class="title" v-html="title" />
    </div>
</div>
</template>

<script>
    export default {
        name: 'SearchItem',
        props: {
            img_src:    {type: String, default: ''},
            title:      {type: String, default: ''},
            sub_title:  {type: String, default: ''},
        },
    }
</script>

<style lang="scss" scoped>
.search-item-component {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .item-cover {
        width: 50px;
        height: 40px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        margin-right: 20px
    }
    .textContent {
        line-height: 18px;
        font-size: 12px;
        max-width: calc(100% - 70px);
        overflow: hidden;
        overflow-wrap: break-word;
        .title {
            line-height: 21px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}
</style>