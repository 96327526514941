<template>
<modal name="please-login" :width="0" :click-to-close="false">
    <div class="modal show modal__please_login modal__new_design">
        <section>
            <a class="modal__close">
                <i class="icon-close" @click="hide" />
            </a>
            <div class="modal__head text__bold text__center">
                {{ $t('MODAL.PLEASE_LOGIN_TITLE') }}
            </div>
            <div class="modal-footer" v-if="showAuthBtn">
                <button
                    class="btn btn__l btn__round btn__bordered"
                    @click="showJoin()">
                    {{ $t('BUTTON.JOIN') }}
                </button>
                <button
                    class="btn btn__l btn__round"
                    @click="showLogin()">
                    {{ $t('BUTTON.LOGIN') }}
                </button>
            </div>
        </section>
    </div>
</modal>
</template>

<script>
export default {
    name: 'PleaseLogin',
    methods: {
        hide() {
            this.$modal.hide('please-login')
        },
        showLogin: function () {
            this.hide()
            this.$modal.show('auth-form');
        },
        showJoin: function () {
            this.hide()
            this.$modal.show('join-form');
        },
    },
}
</script>
