<template>
<div class="edit-attachments-component">
    <div v-if="collectAttachments.length" class="label-text">
        {{ collectAttachments.length }} Attachment{{ collectAttachments.length > 1 ? 's' : '' }}
    </div>
    <div class="attachments-wrap">
        <EditAttachItem
            v-for="item in collectAttachments"
            :key="item.$index"
            :item="item"
            @delete="handleRemoveAttach(item)" />
        <div v-if="loading" class="spinner-border" />
    </div>
</div>
</template>

<script>
import { getPostAttachments } from "@api/api.posts";
import EditAttachItem from "./EditAttachItem";

    export default {
        components: { EditAttachItem },
        props: {
            post: {type: Object}
        },
        data() {
            return {
                attachments: {
                    cards:    { items: [] },
                    products: { items: [] },
                    images:   { items: [] },
                    folders:  { items: [] }
                },
                loading: false,
            }
        },
        computed: {
            cards() {
                return this.attachments.cards.items
            },
            products() {
                return this.attachments.products.items
            },
            images() {
                return this.attachments.images.items
            },
            folders() {
                return this.attachments.folders.items
            },
            collectAttachments() {
                return [ ...this.cards, ...this.products, ...this.images, ...this.folders, ]
            },
        },
        mounted(){
            if (this.post.id && !this.post.isNew) {
                this.loadAttachments()
            }
        },
        methods: {
            async loadAttachments() {
                try {
                    this.loading = true
                    this.attachments = { ...this.attachments, ...(await getPostAttachments(this.post.id)).data.response}
                } catch (e) {
                    console.error('EditAttachments loadAttachments err:', e)
                }
                this.loading = false
            },
            addAttachment(payload) {
                if (payload.selector === "photo") {
                    this.attachments.images.items.push(payload.response)
                } else if (['my_card', 'other_card'].includes(payload.selector)) {
                    this.attachments.cards.items.push(payload)
                } else if (['my_folder', 'other_folder'].includes(payload.selector)) {
                    this.attachments.folders.items.push(payload)
                } else if (['my_product', 'other_product'].includes(payload.selector)) {
                    this.attachments.products.items.push(payload)
                }
            },
            handleRemoveAttach(data) {
                this.$emit('delete', data);
                const that = this;
                ['images', 'cards', 'folders', 'products'].forEach(function (path) {
                    let index = that[path].findIndex(item => item.id === data.id)
                    if (index > -1) {
                        that.attachments[path].items.splice(index, 1)
                    }
                })
            },
            setLoading(state) {
                this.loading = state
            }
        },
    }
</script>

<style lang="scss" scoped>
    .edit-attachments-component {
        .spinner-border {
            opacity: 0.4;
            margin: 20px;
        }
    }
</style>