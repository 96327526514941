import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import en from './locales/en.json'

const messages = {
    en: en
}

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages
})

export default i18n