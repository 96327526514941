import {
    SignIn,
    SignUp,
    getUser,
    getUserInfo,
    ForgotPass,
    ResendEmail,
    CheckConfirmToken,
    UpdatePassword,
    Logout
} from '../../api/api.auth'
import router from '../../routes'
import {setToken, removeTokens, getToken} from '@utils/token'

import i18n from '../../i18n'

const getDefaultState = () => {
    return {
        user: null,
        userInfo: null,
        isLogedIn: null,
        errors: {
            login: '',
            forgot: ''
        },
        showForm: 'login',
        redirect_link: ''
    }
}

const auth = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getCurrentUser: state => state.user,
        getError: state => error => state.errors[error],
        getShownForm: state => state.showForm,
        isLogedIn: state => state.isLogedIn,
        getUserInfo: state => state.userInfo
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
            state.isLogedIn = true
            localStorage.setItem('user', JSON.stringify(user))
            if (user.access_token) {
                setToken(user.access_token)
            }

        },
        SET_REDIRECT_LINK (state, link) {
            state.redirect_link = link
        },
        SET_ERROR(state, {way, error}) {
            state.errors[way] = error
        },
        SHOW_FORM(state, form) {
            state.showForm = form
        },
        CLEAR_ERROR(state, way) {
            state.errors[way] = ''
        },
        CLEAR_REDIRECT_LINK(state) {
            state.redirect_link = ''
        },
        SET_LOGIN_STATUS(state, status) {
            state.isLogedIn = status
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
            localStorage.removeItem('user')
            removeTokens()
            state.isLogedIn = false
        },
        SET_USER_INFO (state, data) {
            state.userInfo = data
        }
    },
    actions: {
        async LOGIN({commit, state}, formData) {
            {
                try {
                    //show spinner
                    localStorage.setItem('sphir_user_email', formData.email)
                    const response = (await SignIn(formData)).data;
                    const {status, data, type, message} = response;
                    let error = ''
                    if (status === "success" && data) {
                        commit('SET_USER', data);
                        commit('SET_LOGIN_STATUS', true)
                        let redirect_path = ''; // before_login_url
                        if (data.super_admin) {  // If user is super_admin, set URL to Transactions table in Admin Dashboard.
                            redirect_path = '/admin/transactions'
                        }
                        if (!data.update_follow) { // If user hasn't followed at least 1 mandatory topic, set URL to follow_users_path, so they may follow a topic.
                            redirect_path = '/users/follows'
                        }
                        if (data.mobile_device) { // If user is logging from mobile device, set URL to discover_index, so they can skip the tutorial screens.
                            redirect_path = '/discover'
                        }
                        if (data.super_admin) { // If user hasn't viewed tutorial yet, set URL to tutorial_follows_path, so they may view the tutorial once.
                            redirect_path = '/follows/tutorial'
                        }
                        if (data.account_type === 'merchant') {
                            if (!data.setup_bio) {
                                redirect_path = '/setup/bio'
                            } else if (!data.setup_billing) {
                                redirect_path = '/setup/billing'
                            }
                        }
                        if (!redirect_path) {
                            redirect_path = '/users/home'
                        }
                        if (state.redirect_link) {
                            redirect_path = state.redirect_link
                            commit('CLEAR_REDIRECT_LINK')
                        }
                        if (!window.location.pathname.includes(redirect_path)) {
                            console.log(`redirect to ${redirect_path}`);
                            window.location.href = redirect_path
                        }
                    } else if (!data) {
                        if (type === 'business_blockage') {
                            error = message[0]
                        } else {
                            error = i18n.t('VALIDATION.LOGIN')
                        }
                        commit('SET_ERROR', {way: 'login', error})
                    } else if (data.locked) { //User has exhausted 5 login attempts. Account has been locked and needs to reset password
                        commit('SET_ERROR', {way: 'login', error: i18n.t('VALIDATION.BLOCKED')});
                        // setTimeout(function() { commit('SHOW_FORM', 'forgot') }, 5000)
                    } else if (data.is_active === false) {
                        error = i18n.t('VALIDATION.DISABLED-ACC');
                        commit('SET_ERROR', {way: 'login', error})
                    } else {
                        commit('SET_ERROR', {way: 'login', error: i18n.t('VALIDATION.CONFIRM_EMAIL')}) //temp msg instead show modal
                        // commit('SHOW_FORM', 'resend_confirm'); //http://joxi.ru/823O95GIDYeMn2.jpg
                    }
                    return response
                } catch (e) {
                    commit('RESET_STATE')
                    if (router.history.current.meta.auth_user) {
                        window.location = '/'
                    }
                    return e
                }
            }
        },
        async SET_REDIRECT_LINK ({commit}, link) {
            commit('SET_REDIRECT_LINK', link)
        },
        async LOGOUT({commit}) {
            try {
                const response = await Logout()
                eval(response.data)
                commit('SET_LOGIN_STATUS', false)
                return response
            } catch (e) {
                return e
            }
        },
        async SIGN_UP({commit, dispatch}, data) {
            try {
                localStorage.setItem('sphir_user_email', data.uid.trim())
                const formData = {domain: process.env.API_CLIENT, account_type: 'personal', email: data.uid.trim(), ...data}
                const response = await SignUp(formData)
                return response
            } catch (e) {
                dispatch('errors/SET_ERROR', {form: 'sign_up', fields: e.data.message}, {root: true})
                return e
            }
        },

        async RESEND_EMAIL({state, commit}) {
            try {
                const email = localStorage.getItem('sphir_user_email')
                const formData = {confirm_url: `${window.location.origin}/users/confirmation`, email: email, uid: email}
                const response = await ResendEmail(formData)
                return response
            } catch (e) {
                return e
            }
        },
        async FORGOT_PASSWORD({commit}, email) {
            {
                try {
                    const payload = {
                        email: email,
                        uid: email,
                        redirect_url: `${window.location.origin}/users/update_password`,
                    };
                    let response = await ForgotPass(payload);
                    if (!response.status) {
                        commit('SET_ERROR', {way: 'forgot', error: i18n.t('VALIDATION.EMAIL-REGISTERED')})

                    }
                    return response
                } catch (e) {
                    commit('SET_ERROR', {way: 'forgot', error: i18n.t('VALIDATION.EMAIL-REGISTERED')})
                    return e
                }
            }
        },
        async LOAD_USER_INFO({commit}) {
            let {data} = await getUserInfo()
            commit('SET_USER_INFO', data.response)
        },
        async LOAD_USER({commit, dispatch}, id) {
            {
                try {
                    let response = await getUser(id) || {}
                    let {data, status} = response.data || {}
                    if (status === 'success') {
                        dispatch('LOAD_USER_INFO')
                        commit('SET_USER', data)
                        commit('SET_LOGIN_STATUS', true)
                    }else{
                        throw("un-logged")
                    }
                } catch (e) {
                    // logout
                    commit('RESET_STATE')
                    // redirect if page only for auth users
                    if (router.history.current.meta.auth_user) {
                        window.location = '/'
                    }
                }
            }
        },
        async CHECK_CONFIRM_TOKEN({commit}, token) {
            {
                try {
                    let response = await CheckConfirmToken(token)
                    if (response) {
                        commit('SET_USER', response.data.response)
                    }
                    return response
                } catch (e) {
                    return e
                }
            }
        },
        async UPDATE_PASSWORD({state, commit}, data) {
            {
                try {
                    const uid = state.user.uid
                    const payload = {
                        password: data.password,
                        password_confirmation: data.password_confirmation,
                        uid: uid
                    }
                    let response = await UpdatePassword(payload)
                    return response
                } catch (e) {
                    return e
                }
            }
        }
    }
}

export default auth
