const TokenKey = 'token'

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    localStorage.setItem(TokenKey, `${token}`)
}

export function removeTokens() {
    localStorage.removeItem(TokenKey)
}
