<template>
<div
    class="AttachURL">
    <div class="AttachURL__block">
        <div class="AttachURL__head">
            Attach URL Link
            <button
                class="btn__reset"
                @click="$emit('close')">
                <i class="icon-close" />
            </button>
        </div>
        <div class="AttachURL__body">
            <img
                v-if="attachURLImage"
                :src="attachURLImage">
            <input
                v-model="attachURL"
                type="text"
                class="full__width"
                :placeholder="$t('PLACEHOLDER.ADD_URL')"
                @blur="getInfoFromUrl">
        </div>
        <div class="AttachURL__footer text__right">
            <button
                class="btn btn__m btn__round btn__bordered"
                :disabled="attachURLDisabled"
                @click.stop.prevent="handleAddLink">
                Add
                <div
                    v-if="parsingProgress"
                    class="spinner-border" />
            </button>
        </div>
    </div>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AttachURLModal",
  props: {
    comment_id: {type: String, default: () => ''},
  },
  data() {
    return {
      attachURL: '',
      attachURLInfio: { },
      parsingProgress: false,
      awaitParsingForAdd: false,
    }
  },

  computed: {
    attachURLImage() {
      return this.attachURLInfio.s3_img_src || this.attachURLInfio.img_src
    },
    isAttachURLValid() {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/g.test(this.attachURL)
    },
    attachURLDisabled () {
      return !this.attachURL
    },
  },
  methods: {
    ...mapActions('embed_url_info', {
      parseLink: 'PARSE_LINK',
    }),
    toggleShowAttachURL() {
      this.showAttachURL = !this.showAttachURL
    },
    async getInfoFromUrl() {
      if (this.attachURL && this.isAttachURLValid && !this.parsingProgress) {
        this.parsingProgress = true
        try {
          this.attachURLInfio = await this.parseLink({url: this.attachURL})
        } catch (e) {
          console.log('getInfoFromUrl', e)
          this.attachURLInfio = {}
        }
        this.parsingProgress = false
        if (this.awaitParsingForAdd) {
          this.addAttachLink()
        }
        // console.log('getInfoFromUrl', this.attachURLInfio)
      } else {
        this.attachURLInfio = {}
      }
      return true
    },
    addAttachLink() {
      this.$emit('addLink', {
        url: this.attachURLInfio.url || this.attachURL,
        title: this.attachURLInfio.title,
        image: this.attachURLImage,
        comment_id: this.comment_id,
      });
      this.$emit('close')
    },
    async handleAddLink() {
      if (this.isAttachURLValid) {
        if (this.parsingProgress) {
          this.awaitParsingForAdd = true
        } else {
          this.addAttachLink()
        }
      } else {
        iziToast.error({
          title: 'Error',
          message: 'Enter a valid URL',
        });
      }
    }
  }
}
</script>

<style  lang="scss">
  .AttachURL {
    .spinner-border {
      color: #cfcbe5 !important;
      width: 1.5rem;
      height: 1.5rem;
      border-width: .15em;
    }
  }


</style>