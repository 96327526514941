<template>

    <div class="select-preview-component">
        <div class="m-b-10 text-right clear-fix">
            <h6 class="pull-left">Preview</h6>
            <div v-show="preview_type" @click="clear_preview()" class="btn-browse btn-sm" title="Clear preview">
                <div class="fa fa-trash"></div>
            </div>
        </div>

        <div class="row m-b-20">
            <div class="col-md-6">
                <input class="radio-custom" type="radio" v-model="preview_type" value="generated" style="width: 140px"/>
                <label class="radio-custom-label">Auto-generated</label>
            </div>
            <div class="col-md-6">
                <input class="radio-custom" type="radio" v-model="preview_type" value="trailer"title="upload your trailer"/>
                <label class="radio-custom-label">Trailer</label>
            </div>
        </div>

        <div v-if="preview_type === 'trailer' && trailer_status !== 'COMPLETE'">
            <p class="small m-b-0">Supported formats: MP4, WEBM, MOV, AVI, MKV, FLV</p>
            <p class="small m-b-0">Minimum duration = 10 seconds</p>
            <p class="small">Maximum File Size = 50MB.</p>

            <upload-video :kind="'trailer'"></upload-video>
        </div>

        <div v-if="preview_type === 'generated'">
            <div class="m-b-10">Allow customers to preview your video before purchase</div>
            <div class="row m-b-10">
                <div class="col-md-6">
                    <label class="text-secondary">
                        Start Time(s)
                        <input class="form-control" min="0" type="number" v-model="generated_start_time" :disabled="generated_status !== 'INITIATED'"/>
                    </label>
                </div>
                <div class="col-md-6">
                    <label class="text-secondary">
                        Length(s)
                        <input class="form-control" min="0" type="number" v-model="generated_duration" :disabled="generated_status !== 'INITIATED'"/>
                    </label>
                </div>
            </div>
            <div class="btn-browse btn-sm"
                 :class="{ disabled: generated_status !== 'INITIATED' }"
                 :title="generate_await_main_video ? 'Await uploading main video' : 'Generate preview'"
                 v-if="generated_status !== 'COMPLETE'"
                 @click="generate_preview()">
                <span v-if="['INITIATED'].includes(generated_status)">Generate preview</span>
                <span v-else-if="['SUBMITTED', 'PROGRESSING'].includes(generated_status)">
                  Processing
                  <span v-if="generated_job_percent_complete">{{generated_job_percent_complete}}%</span>
                  <span v-else class="fa fa-spin fa-spinner"></span>
                </span>
                <span v-else-if="['COMPLETE'].includes(generated_status)">Complete</span>
            </div>
        </div>

        <theo-player v-if="preview_status === 'COMPLETE'" :kind="preview_type"></theo-player>
    </div>
</template>

<script>
    import UploadVideo from './UploadVideo'
    import TheoPlayer from './TheoPlayer'
    import {mapDynamicFields} from "./helpers/mapDynamicFields";

    export default {
        components: { UploadVideo, TheoPlayer },
        data: function () {
            return {
                spath: `Videos.uploads`,
                player: null,
            }
        },
        computed: {
            ...mapDynamicFields('spath', {
                main_video_id: 'main.api_state.id',
                main_video_origin_s3_path: 'main.api_state.origin_s3_path',
                main_video_status: 'main.api_state.status',
                trailer_status: 'trailer.api_state.status',
                generated_status: 'generated.api_state.status',
                generated_start_time: 'generated.api_state.start_time',
                generated_duration: 'generated.api_state.duration',
                generated_job_percent_complete: 'generated.api_state.job_percent_complete',
                generate_await_main_video: 'generated.await_main_video',
            }),
            preview_type: {
                get(){
                    return this.$store.getters['Videos/preview_type']('main')
                },
                set(value){
                    this.$store.commit('Videos/updateField', {
                        path: 'uploads.main.ui_state.preview_type', value: value
                    })
                }
            },
            preview_id: function(){
                if (this.is_preview_uploaded){
                    return this.$store.state.Videos.uploads[this.preview_type].api_state.id
                }
            },
            preview_status: function () {
                if (this.preview_type) {
                    return this.$store.state.Videos.uploads[this.preview_type].api_state.status
                }
            },
            is_main_video_uploaded: function () {
                return ['UPLOADED', 'SUBMITTED', 'PROGRESSING', 'COMPLETE'].includes(this.main_video_status)
            },
            is_preview_uploaded: function() {
                return (['UPLOADED', 'SUBMITTED', 'PROGRESSING', 'COMPLETE'].includes(this.preview_status))
            }
        },
        watch: {
            'preview_id': function(newVal){
                this.$store.commit('Videos/updateField', {
                    path: 'uploads.main.ui_state.preview_id', value: newVal
                })
            }
        },
        methods: {
            async generate_preview() {
                this.generated_status = 'SUBMITTED';
                if (this.is_main_video_uploaded) {
                    this.generate_await_main_video = false;
                    await this.$store.dispatch('Videos/createVideo', { kind: 'generated' });
                    await this.$store.dispatch('Videos/syncStatus', { kind: 'generated' });
                } else {
                    this.generate_await_main_video = true
                }
            },
            clear_preview() {
                this.$store.commit('Videos/CLEAR', { kind: 'generated' });
                this.$store.commit('Videos/CLEAR', { kind: 'trailer' });
                this.preview_type = null;
            }
        },
        created() {
            const vm = this;
            this.$root.$on('generate_preview', this.generate_preview);
            this.$root.$on('main_video_uploaded', () => {
                console.log('SP main_video_uploaded');
                if (vm.generate_await_main_video) { vm.generate_preview() }
            });
        },
    }
</script>

<style lang="scss" scoped>
    .select-preview-component {
        input.form-control {
            border-bottom: 1px solid #d1d3d4;
            margin-top: 0;
            padding: 0;
            height: 30px;
        }
        .disabled, .radio-custom[disabled] + .radio-custom-label {
            opacity: 0.6;
            cursor: not-allowed;
            pointer-events: auto;
            &.btn-sm:hover {
                border: 1px solid #d1d3d4;
                background-color: #f8f8f9;
                color: #a7a9ac;
            }
        }
    }
</style>