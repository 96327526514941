import { Model } from '@vuex-orm/core';
import Post from "@models/Post";
import Reply from "@models/Reply";
// import User from '@models/User';

class Comment extends Model {
    static entity = 'comments'

    static fields () {
        return {
            id: this.attr(),
            class: this.attr(null),
            post_id: this.attr(null),
            user_id: this.attr(null),
            content: this.attr(''),
            first_attachment: this.attr(null),
            attachment_order_type: this.attr(null),
            user: this.attr(null),
            image_ids: this.attr(null),
            my_card_ids: this.attr(null),
            attachment_order_id: this.attr(null),
            my_folder_ids: this.attr(null),
            other_card_ids: this.attr(null),
            card: this.attr(null),
            folder: this.attr(null),
            product: this.attr(null),
            logo_image: this.attr(null),
            updated_at: this.attr(null),
            category_name: this.attr(null),
            card_type: this.attr(null),
            created_at: this.attr(null),
            reply_count: this.attr(null),
            external_url: this.attr(null),
            url_hash: this.attr(null),
            mentions: this.attr([]),
            reply: this.hasMany(Reply, 'parent_comment_id'),
            // relationships
            post: this.belongsTo(Post, 'post_id'),
            // user: this.belongsTo(User, 'user_id')
        }
    }
}

export default Comment