export const mapDynamicFields = (namespaceProp, fields) => {
    return Object.keys(fields).reduce((prev, key) => {
        const relativePath = fields[key];
        const field = {
            get() {
                const namespace = this[namespaceProp]
                const path = `${namespace}.${relativePath}`
                return this.$store.getters['getField'](path)
            },
            set(value) {
                const namespace = this[namespaceProp];
                const path = `${namespace}.${relativePath}`
                this.$store.commit('updateField', {path, value});
            }
        };
        prev[key] = field;
        return prev;
    }, {});
};