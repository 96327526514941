import { Model } from '@vuex-orm/core';
import Comment from '@models/Comment';

class Reply extends Model {
    static entity = 'replies'

    static fields () {
        return {
            id: this.attr(null),
            parent_comment_id: this.string(""),
            content: this.attr(''),
            user: this.attr(null),
            comment: this.belongsTo(Comment, 'id'),
            updated_at: this.attr(null),
            created_at: this.attr(null),
        }
    }
}

export default Reply