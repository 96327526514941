<template>
    <div class="product-preview-video-wrapper">
        <div v-if="is_frozen" class="frozen-overlay" :title="frozen_title">
          <div>Deactivated</div>
        </div>
        <div class="product-preview product-video-container" id="product-video-container">
            <div class="product-video" id="product-video">
                <div v-if="playable" v-show="videoSource === 'video' && !rent_activated" class="start-to-rental" :style="{'background-image': `url(${poster})`}" >
                    <div class="btn btn-browse btn-sm" @click="startToRental()">
                        Start to rental
                        <i class="fa" v-bind:class="!reqRentActivate ? 'fa-play-circle' : 'fa-spinner fa-pulse'"></i>
                    </div>
                </div>
                <div v-if="playable" v-show="(rent_activated || videoSource !== 'video')" class="theoplayer-container video-js theoplayer-skin" ref="theoplayer"></div>
                <div class="theo-unable" v-else>{{browserName}} play will be available soon. Please use Safari to play this content.</div>
            </div>
        </div>
        <div class="v-tabs" v-if="!is_frozen && has_access && main_source_available && !is_audio">
            <a @click="play('preview')" v-bind:class="{ 'active': videoSource === 'preview' }">preview
            </a><a @click="play('video')" v-bind:class="{ 'active': videoSource === 'video' }">{{is_audio ? 'podcast' : 'video'}}</a>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['product', 'current_user_id'],
        data() {
            return {
                player: null,
                videoSource: null,
                autoPlay: false,
                theatrical: false,
                playPdYTiframe: false,
                browser: window.browser,
                reqRentActivate: false
            }
        },
        computed: {
            has_access: function () {
                return (this.product.purchased || this.product.rented || this.product.owner)
            },
            rent_activated: function () {
                return this.product.owner || this.product.purchased || this.product.rent_status === 'active'
            },


            video: function () {
                return !this.is_audio ? this.product.video : this.audio
            },
            video_preview: function () {
                return this.product.video_preview
            },
            is_audio: function () {
                return this.product.category_name === "Podcast"
            },
            audio: function () {
                return this.product.audio
            },
            main_source_available: function () {
                if (this.is_audio) {
                    const {audio} = this;
                    const source_exists = (audio.source_type === 'file' && !!audio.sources[0]) ||
                        (audio.source_type === 'url' && (!!audio.original_url || !!(audio.embed_url_info || {}).embed_content))
                    return source_exists && (this.product.owner || this.product.premiere_passed)
                } else {
                    return (!!this.video.dash_sources[0] || !!this.video.hls_sources[0]) && (this.product.owner || this.product.premiere_passed)
                }
            },
            poster: function () {
                return (this.video && this.video.poster.lg) || this.product.cover_photo
            },
            browserName: function () {
                return (this.browser || {}).name
            },
            osName: function() {
                return (this.browser || {}).osname
            },
            playable: function () {
                // reverse condition when not playable - only video on iOS except Safari - other cases are playable
                return !(this.videoSource === 'video' && this.osName === 'iOS' && this.browserName !== 'Safari')
            },

            is_frozen: function () {
                return this.product.is_frozen && !this.product.purchased && !this.product.rented
            },
            frozen_title: function () {
                return `This product is not currently available. ${ this.product.owner ? 'Please, re-activate Plus level subscription' : '' }`
            },
        },
        created() {
            setTimeout(() => {
                if (this.has_access && this.main_source_available) {
                    this.videoSource = 'video'
                }
                if ((window.location.search || '').includes('auto_play')) {
                    this.autoPlay = true;
                    this.theatrical = true;
                    // setTimeout(this.setTheatrical())
                }
                this.setPlayer()
            });
            document.addEventListener('init-browser', (e) => {
                this.browser = window.browser
            })
        },
        watch: {
            videoSource: function (nv, pv) {
                this.setPlayer()
            },
        },

        methods: {
            setTheatrical() {
                var theatrParent = document.getElementById('player-theater-container');
                var videoParent = document.getElementById('product-video-container');
                var video = document.getElementById('product-video');
                if (document.body.classList.contains('theater-preview')) {
                    videoParent.appendChild(video);
                    document.body.classList.remove('theater-preview');
                } else {
                    theatrParent.appendChild(video);
                    document.body.classList.add('theater-preview');
                }
            },
            setPlayer() {
                if (this.videoSource === 'video' && !this.rent_activated) {
                    return
                }
                const vm = this;
                const element = this.$refs.theoplayer;

                let sources = (this.video_preview || {}).hls_sources;
                let poster;

                if (this.is_audio) {
                    poster = this.video.poster;
                    if (vm.videoSource === 'video') {
                        sources = this.video.sources
                    }
                } else {
                    poster = this.video.poster.md
                    let env = $('body').data('env'),
                        licence_url_prefix = (env !== 'production' ? "staging." : ""),
                        merchant = (env !== 'production' ? 'principlesoft' : 'supirb')
                    if (vm.videoSource === 'video') {
                        if ((this.video || {}).digital_access == 'private' && this.browserName !== 'Safari' && this.osName !== 'iOS'){
                            sources = [{
                                "src": this.video.dash_sources[0],
                                "type": "application/dash+xml",
                                "contentProtection": {
                                    "integration": "drmtoday",
                                    "merchant": merchant,
                                    "sessionId": this.video.token,
                                    "userId": this.current_user_id,
                                    "playready": {
                                        "licenseAcquisitionURL": `https://lic.${licence_url_prefix}drmtoday.com/license-proxy-headerauth/drmtoday/RightsManager.asmx`
                                    },
                                    "widevine": {
                                        "licenseAcquisitionURL": `https://lic.${licence_url_prefix}drmtoday.com/license-proxy-widevine/cenc/`
                                    }
                                }
                            }]
                        } else {
                            sources = this.video.hls_sources
                        }
                    }
                }

                const textTracks = this.video.text_tracks;

                if (!vm.player && element) {
                    vm.player = setTheoPlayer({
                        element: element,
                        set_theatrical_btn: vm.setTheatrical,
                        media_id: this.video.id,
                        sources, poster, textTracks,
                        holder: (vm.videoSource !== 'video' || vm.product.owner) ? '' : JSON.stringify({
                            class: 'products',
                            id: vm.product.id,
                            type: vm.product.category_name,
                            title: vm.product.title
                        }),
                    })
                } else {
                    vm.player.source = {sources, poster, textTracks};
                }

                if (sources && sources.length > 0) {
                    element.classList.remove('sources-unavailable');
                } else {
                    element.classList.add('sources-unavailable');
                }

                if (!poster) {
                    element.classList.add('poster-unavailable');
                } else {
                    element.classList.remove('poster-unavailable');
                }

                if (vm.is_audio) {
                    element.classList.add('audioplayer');
                    $(element).find('.vjs-control-bar').addClass('audiocontrol');
                }

                if (sources) {
                    if (vm.autoPlay === 'by_click') {
                        vm.player.play()
                    } else if (vm.autoPlay) {
                        vm.player.muted = true;
                        vm.player.autoplay = true;
                    }
                    if (vm.theatrical) {
                        vm.theatrical = false;
                        vm.setTheatrical()
                    }
                }
            },
            play(v) {
                this.autoPlay = 'by_click';
                if (this.videoSource === v) {
                    this.setPlayer()
                }
                this.videoSource = v;
            },
            moment(date, format) {
                return moment.utc(date, 'YYYY-MM-DD hh:mm A ').local().format(format || 'MMM DD, YYYY')
            },
            startToRental() {
                if (this.videoSource === 'video' && this.product.rent_status === 'pending') {
                    this.reqRentActivate = true
                    const vm = this;
                    Api.order_item.update(vm.product.order_item_id, {rent_status: 'activate'}).then((resp) => {
                        vm.product.rented_to = resp.rented_to;
                        vm.product.rent_status = resp.rent_status;
                        vm.reqRentActivate = false
                        if (resp.rent_status === 'active') {
                            vm.play('video')
                            let txt = (vm.product.pre_sale_rented ? "Pre-sale rented: " : "Rented: ") + vm.moment(vm.product.rented_at, 'MMM DD, YYYY')
                            txt = `${txt} &nbsp;|&nbsp; <b>Available until ${ vm.moment(vm.product.rented_to, 'MMM DD [at] h:mma') }</b>`
                            document.querySelector(`.general_pricings`).innerHTML = txt;
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
  .product-preview-video-wrapper {
    position: relative;
    //.frozen {
    //  position: absolute;
    //  color: black;
    //  top: 4px;
    //  left: 4px;
    //  z-index: 1;
    //  padding: 2px 7px;
    //  background: #ffc107;
    //}
    .frozen-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      z-index: 9;
      div {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 26px;
        text-shadow: 0px 0px 2px #000000b5;
      }
    }
  }
</style>