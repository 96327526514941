import request from '@utils/request'

export function FetchTrendingFrequencies() {
    return request({
        url: '/trending_frequencies',
        method: 'get',
    })
}

export function GetFollowersList(data) {
    return request({
        url: `/trending_frequencies/${data["type"]}?keywords=${data["keywords"]}`,
        method: 'get',
        data: {}
    })
}

export function FollowUnfollow(data) {
  return request({
    url: `/friends/${data["type"]}`,
    method: 'post',
    data: {
      user_id: data["user_id"]
    }
  })
}

export function GetFollowCount(){
  return request({
    url: '/friends/follow_count',
    method: 'get'
  })
}