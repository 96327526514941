import Vue from 'vue/dist/vue.esm'

import {
    getGroup,
    getMembers,
    getCatalogCollections,
    getCatalogCards,
    getGroupActivity,
    updateGroupSettings,
    deleteGroup
} from '../../api/api.group'
import {
    groupJoin,
    groupUnjoin,
    groupFollow,
    userLike,
    contentLike, userUnfollow, userFollow
} from '../../api/api.discover'
import {
    getPosts
} from '../../api/api.posts'
import Group from '@models/Group'
import Post from '@models/Post'

const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el.id === val ? [...acc, i] : acc), []);

const getDefaultState = () => {
    return {
        posts_pagination: {
            page: 1,
            limit: 3
        },
        members_pagination: {
            page: 1,
            limit: 12
        },
        collections_pagination: {
            page: 1,
            limit: 12
        },
        cards_pagination: {
            page: 1,
            limit: 12
        },
        activity_pagination: {
            page: 1,
            limit: 10
        },
        members: [],
        collections: [],
        cards: [],
        activity: [],
    }
}

const pro = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getGroupMembers: state => state.members,
        getGroupCollections: state => state.collections,
        getGroupCards: state => state.cards,
        getActivity: state => state.activity
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        UPDATE_POST_PAGINATION(state) {
            state.posts_pagination.page += 1
        },
        UPDATE_MEMBER_PAGINATION(state) {
            state.members_pagination.page +=1
        },
        UPDATE_CARDS_PAGINATION(state) {
            state.cards_pagination.page +=1
        },
        UPDATE_COLLECTIONS_PAGINATION(state) {
            state.collections_pagination +=1
        },
        PUSH_MEMBERS(state, members) {
            state.members = [...state.members, ...members]
        },
        PUSH_COLLECTIONS(state, collections) {
            state.collections = [...state.collections, ...collections]
        },
        PUSH_CARDS(state, cards) {
            state.cards = [...state.cards, ...cards]
        },
        PUSH_ACTIVITY(state, activity) {
            state.activity = [...state.activity, ...activity]
        },
        SET_USER_LIKE (state, data) {
            state.members[data.index].trusteds_count += data.value
            if (data.value === 1) {
                state.members[data.index].trusted = true
            } else {
                state.members[data.index].trusted = false
            }
        },
        UPDATE_USER_FOLLOW_STATUS(state, data) {
            state.members[data.index].followed = data.status
        },
        UPDATE_FOLLOW_COUNT(state, data) {
            state.members[data.index].followed_count += data.value
        },
        CLEAR_TAB_CONTENT(state, tab) {
            state[tab] = []
        },
        RESET_TAB_PAGINATION(state, tab) {
            state[`${tab}_pagination`].page = 1
        },
        SET_CONTENT_LIKE (state, data) {
            state.cards[data.index].trusteds_count += data.value
            state.cards[data.index].card_tally_info.trusted = (data.value === 1)
        },
        UPDATE_ACTIVITY_PAGINATION(state) {
            state.activity_pagination.page +=1
        },
        RESET_PAGINATION() {
            state.posts_pagination.page = 1
            state.members_pagination.page = 1
            state.collections_pagination.page = 1
            state.cards_pagination.page = 1
        }
    },
    actions: {
        async GET_GROUP ({ commit }, id) {
            const response = await getGroup(id)
            Group.insertOrUpdate({data: response.data.response})
        },
        async GET_ACTIVITY ({ state, commit}, id) {
            const response = await getGroupActivity(state.activity_pagination, id)
            if (response.data.status === 200 && response.data.response) {
                commit('PUSH_ACTIVITY', response.data.response)
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_ACTIVITY_PAGINATION')
            }
            return response
        },
        async RESET_PAGINATION({commit}) {
            commit('RESET_PAGINATION')
        },
        async DELETE_GROUP({commit}, id) {
            const response = await deleteGroup(id)
            return response
        },
        async GET_MEMBERS({commit, state}, payload) {
            const { id, keyword, sort_order } = payload
            const response = await getMembers(state.members_pagination, id, keyword, sort_order)
            if (response.data.status === 200 && response.data.response) {
                commit('PUSH_MEMBERS', response.data.response)
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_MEMBER_PAGINATION')
            }
            return response
        },
        async LIKE_CONTENT ({commit, state}, payload) {
            const response = await contentLike(payload)
            const indexes = indexOfAll(state.cards, response.data.response.id)
            if (response.data.status === 200) {
                indexes.forEach((index) => {
                    if ((state.cards[index].card_tally_info || {}).trusted === true) {
                        commit ('SET_CONTENT_LIKE', {index: index, value: -1})
                    } else {
                        commit ('SET_CONTENT_LIKE', {index: index, value: 1})
                    }
                })
            }
            return response
        },
        async GET_CATALOG_CARDS({commit, state}, payload) {
            const { id, keyword, sort_order } = payload
            const response = await getCatalogCards(state.cards_pagination, id, keyword, sort_order)
            if (response.data.status === 200 && response.data.response) {
                commit('PUSH_CARDS', response.data.response)
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_CARDS_PAGINATION')
            }
            return response
        },
        async UPDATE_GROUP({commit}, payload) {
            const response = await updateGroupSettings(payload)
        },
        async LIKE_USER ( {commit, state}, payload) {
            await userLike(payload)
            const indexes = indexOfAll(state.members, payload.user_id)
            indexes.forEach((index) => {
                if (state.members[index].trusted) {
                    commit ('SET_USER_LIKE', {index: index, value: -1})
                } else {
                    commit ('SET_USER_LIKE', {index: index, value: 1})
                }
            })
        },
        async JOIN_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                group_id: payload.group_id,
                group_member: {
                    user_id: payload.user_id,
                    group_id: payload.group_id,
                    is_joined: true,
                    is_approved: true,
                    user_activity: {
                        activity: 'joined',
                        action_name: 'Joined'
                    }
                }
            }
            const response = await groupJoin(data)
            if (response.data.status === 200) {
                Group.update({
                    where: payload.group_id,
                    data: {
                        is_joined: true,
                        is_following: true,
                        followers_count: response.data.response.group.followers_count,
                        members_count: response.data.response.group.members_count
                    }
                })
            }
        },
        async UNJOIN_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                user_id: payload.user_id,
                group_id: payload.group_id,
                is_following: false,
                is_joined: false,
                user_activity: {
                    activity: 'un joined',
                    action_name: 'Un Join'
                }
            }
            const group = Group.find(payload.group_id)
            const response = await groupUnjoin(data)
            if (response.data.status === 200) {
                Group.update({
                    where: payload.group_id,
                    data: {
                        is_joined: false,
                        is_following: false,
                        followers_count: group.is_following ? group.followers_count - 1 : group.followers_count,
                        members_count: group.members_count - 1
                    }
                })
            }
        },
        async FOLLOW_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                group_id: payload.group_id,
                group_member: {
                    user_id: payload.user_id,
                    group_id: payload.group_id,
                    is_following: true,
                    user_activity: {
                        activity: 'follow',
                        action_name: 'Follow'
                    }
                }
            }
            const group = Group.find(payload.group_id)
            const response = await groupFollow(data)
            if (response.data.status === 200) {
                Group.update({
                    where: payload.group_id,
                    data: {
                        is_following: true,
                        followers_count: group.followers_count + 1,
                    }
                })
            }
        },
        async UNFOLLOW_GROUP({commit, state}, payload) {
            const data = {
                domain: process.env.API_CLIENT,
                group_id: payload.group_id,
                group_member: {
                    user_id: payload.user_id,
                    group_id: payload.group_id,
                    is_following: false,
                    user_activity: {
                        activity: 'unfollow',
                        action_name: 'Un Follow'
                    }
                }
            }
            const group = Group.find(payload.group_id)
            const response = await groupFollow(data)
            if (response.data.status === 200) {
                Group.update({
                    where: payload.group_id,
                    data: {
                        is_following: false,
                        followers_count: group.followers_count - 1,
                    }
                })
            }
        },
        async GET_CATALOG_COLLECTIONS({commit, state}, payload) {
            const { id, keyword, sort_order } = payload
            const response = await getCatalogCollections(state.collections_pagination, id, keyword, sort_order)
            if (response.data.status === 200 && response.data.response) {
                commit('PUSH_COLLECTIONS', response.data.response)
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_COLLECTIONS_PAGINATION')
            }
            return response
        },
        FLAG_GROUP({commit}, data) {
            window.location = `/flags/Group/${data.payload}`
        },
        LEAVE_GROUP({commit}, data) {
            Vue.prototype.$modal.show('leave-group', {group: data})
        },
        async GET_POSTS({commit, state}, payload) {
            const response = await getPosts(state.posts_pagination, 'groups', payload.group_id)
            if (response.data.status === 200 && response.data.response) {
                Post.insertOrUpdate({data: response.data.response})
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_POST_PAGINATION')
            }
            return response
        },
        async SEARCH_CONTENT({commit, dispatch}, payload) {
            const { query, tab, id, sort_order } = payload
            commit('CLEAR_TAB_CONTENT', tab)
            commit('RESET_TAB_PAGINATION', tab)
            const formData = { id: id, keyword: query, sort_order: sort_order }
            let response = {}
            switch (tab) {
                case 'cards':
                    response = dispatch('GET_CATALOG_CARDS', formData)
                    break;
                case 'collections':
                    response = dispatch('GET_CATALOG_COLLECTIONS', formData)
                    break;
                case 'members':
                    response = dispatch('GET_MEMBERS', formData)
            }
            return response
        },
        async UNFOLLOW_USER({commit, state}, payload) {
            const response = await userUnfollow(payload)
            if (response.data.status === 200) {
                const index = state.members.findIndex(item => item.id === payload.user_id);
                commit('UPDATE_FOLLOW_COUNT', {index: index, value: -1})
                commit('UPDATE_USER_FOLLOW_STATUS', {index: index, status: false})
            }
        },
        async FOLLOW_USER({commit, state}, payload) {
            const response = await userFollow(payload)
            if (response.data.status === 200) {
                const index = state.members.findIndex(item => item.id === payload.user_id);
                commit('UPDATE_FOLLOW_COUNT', {index: index, value: 1})
                commit('UPDATE_USER_FOLLOW_STATUS', {index: index, status: true})
            }
        },
    }
}

export default pro