import {Model} from '@vuex-orm/core';
import Comment from '@models/Comment';
import User from '@models/User';

class Post extends Model {
    static entity = 'posts'

    static fields() {
        return {
            id: this.attr(null),
            title: this.attr(''),
            follow_frequency: this.attr(false),
            body: this.attr(null),
            user_id: this.attr(null),
            description: this.attr(null),
            owner: this.attr(null),
            owner_id: this.attr(null),
            my_group_id: this.attr(null),
            comment_count: this.attr(null),
            attachment_order_type: this.attr([]),
            attachment_order_id: this.attr([]),
            image_ids: this.attr([]),
            my_card_ids: this.attr([]),
            other_card_ids: this.attr([]),
            my_folder_ids: this.attr([]),
            other_folder_ids: this.attr([]),
            my_product_ids: this.attr([]),
            other_product_ids: this.attr([]),
            external_url: this.attr([]),
            first_attachment: this.attr(null),
            text_embed_id: this.attr(null),
            text_embed: this.attr(null),
            url_hash: this.attr([]), //[{url:'', title:'', image:''}]
            likes: this.attr(0),
            liked: this.attr(null),
            card: this.attr(null),
            created_at: this.attr(null),
            group: this.attr(null),
            folder: this.attr(null),
            product: this.attr(null),
            type: this.attr(null),
            four_cards_logo: this.attr(null),
            card_type: this.attr(null),
            privacy: this.attr('friends'), //public|friends|friends_group
            friends_group: this.attr([]),
            group_ids: this.attr([]),
            share_post_id: this.attr(null),
            share_post: this.attr(null),
            mentions: this.attr([]),
            // relationships
            // comments: this.hasMany(Comment, 'post_id'),
            user: this.belongsTo(User, 'user_id'),

            // ui fields
            isNew: this.attr(false),
        }
    }
}

export default Post