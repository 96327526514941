<template>
<modal
    name="auth-form"
    :width="0"
    @before-open="beforeOpen">
    <div
        id="login_modal"
        class="modal modal__400 modal__login show outside-click-exclude">
        <section>
            <a
                class="modal__close"
                @click="hide">
                <i class="icon-close" />
            </a>
            <Login v-if="show === 'login'" />
            <ForgotPass
                v-if="show === 'forgot'"
                :expired-message="expiredMessage" />
        </section>
    </div>
</modal>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import Login from './Login'
    import ForgotPass from './ForgotPass'

    export default {
        name: "AuthForm",
        components: {
            Login,
            ForgotPass
        },
        data() {
            return {
                expiredMessage: ''
            }
        },
        computed: {
            ...mapGetters('auth', {
                show: 'getShownForm'
            }),
        },
        methods: {
            ...mapMutations('auth', {
                showForm: 'SHOW_FORM'
            }),
            hide() {
                this.$modal.hide('auth-form')
                this.showForm('login')
                this.expiredMessage = ''
            },
            beforeOpen(event) {
                if (event && event.params && event.params.text) {
                    this.expiredMessage = event.params.text
                }
            }
        }
    }
</script>