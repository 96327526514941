<template>
<div class="upload-profile-pfoto">
  <div v-show="display=='avatar'" @click="onClick()" :class="['avatar-displaying', {'business': business_id}]">
    <img ref="avatarImg" :src="initImageUrl" alt="Avatar">
  </div>

  <div v-show="display=='cropper-modal'" class="cm-wrap">
    <div class="cm">
      <div class="cm-modal-header">
        <div @click="closeCropprModal()" class="close-btn"></div>
      </div>
      <div class="cm-modal-body">
        <p class="bold-txt">Create Profile Picture</p>
        <p>To crop this image, drag the region below and then click "Set as Profile Photo"</p>
        <div v-show="['UPLOADING', 'UPLOADED', 'COMPLETE'].includes(avatar.status)" class="progress">
          Uploading {{avatar.upload_percentage}}%
        </div>
        <vue-cropper
          v-show="!['UPLOADING', 'UPLOADED', 'COMPLETE'].includes(avatar.status)"
          class="vue-cropper"
          ref="cropper"
          :src="avatar.url"
          alt="Avatar"
          :viewMode="2"
          :aspectRatio="1/1"
          :minCropBoxWidth="50"
          :minCropBoxHeight="50">
        </vue-cropper>
        <div @click="uploadAnotherImage()" class="bold-txt upload-another"> 
          <i class="fa fa-upload"></i> 
          Upload another image?
        </div>
      </div>
      <div class="cm-modal-footer">
        <div @click="save()" class="save-btn bold-txt">Set as Profile Photo</div>
      </div>
    </div>
  </div>

  <input ref="fileInput"
      class="avatar-input"
      @change="selectionProcessing($event)"
      type="file"
      accept=".jpeg, .jpg, .png">
</div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  props: ['id', 'initImageUrl', 'business_id'],
  components: { VueCropper },
  data() {
    return {
      display: 'avatar', // avatar || cropper-modal
      maxSize: 10 * 1024 * 1024
    }
  },
  computed: {
    avatar: function () {
      return this.$store.getters['Users/getUserById'](this.id).avatar
    },
    status: function() {    
      return this.avatar.status
    },
    cropper: function() { return this.$refs.cropper }
  },
  methods: {
    onClick(){
      if(["INITIATED", "COMPLETE"].includes(this.status)){
        this.$refs.fileInput.click()
      }
    },
    uploadAnotherImage(){
      if(!["UPLOADING"].includes(this.status)){
        this.$refs.fileInput.click()
      }
    },
    async selectionProcessing(event) {
      let file = event.target.files[0];
      if (typeof FileReader !== 'function') {
        console.error('Sorry, FileReader API not supported');
      } else if (file) {
        const {id, maxSize} = this

        if (maxSize && file.size > maxSize) {
          this.$refs.fileInput.value = ''
          this.$store.commit('Users/UPDATE_AVATAR', 
          { 
            id,
            data: { processing_errors: {too_big_file: ['Please select a file not exceeding 10 Mb']} }
          })
        } else {
          this.$store.commit('Users/PARSE_IMAGE', {id, file, imgType: 'avatar'})
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            this.$refs.cropper.replace(event.target.result);
            this.display = 'cropper-modal'
          };
          reader.readAsDataURL(file);
        }
      }
    },
    closeCropprModal(){
      if(!["UPLOADING"].includes(this.status)){
        this.display = 'avatar'
        this.$store.commit('Users/UPDATE_AVATAR', { 
          id: this.id,
          data: { status: 'INITIATED' }
        })
      }
    },
    save(){
      let {id} = this
      if(this.status !== 'PROCESSING'){
        this.$store.commit('Users/UPDATE_AVATAR', { 
          id: this.id,
          data: { status: 'PROCESSING' }
        })
        let croppedImageUrl = this.$refs.cropper.getCroppedCanvas().toDataURL()
        let croppData 	    = this.$refs.cropper.getData()
        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          try {
            blob.lastModifiedDate = new Date()
            blob.name = this.avatar.origin_name
            this.$store.commit('Users/UPDATE_AVATAR', { 
              id,
              data:{
                croppedImageUrl,
                croppedImageFile: blob
              }
            })
            this.$store.commit('Users/PARSE_IMAGE', {id, file: blob, imgType: 'avatar', status: 'PROCESSING'})
            await this.$store.dispatch('Users/getS3Urls', {id, imgType: 'avatar'})
            await this.$store.dispatch('Users/upload', {id, imgType: 'avatar'})
            if(this.business_id){
              await this.$store.dispatch('Users/updateBusinessAvatar', {id, business_id: this.business_id})
            }else{
              await this.$store.dispatch('Users/updateAvatar', {id})
            }

            this.display = 'avatar'
            this.$refs.avatarImg.src = this.avatar.croppedImageUrl
            this.$store.commit('Users/UPDATE_AVATAR', { 
              id: this.id,
              data: { upload_percentage: 0 }
            })
            iziToast.success({
              message: "Profile photo updated successfully"
            })
          } catch (error) {
            console.error(error)
            this.$store.commit('Users/UPDATE_AVATAR', { 
              id: this.id,
              data: { status: 'ERROR' }
            })
          }
        })
      }
    }
  },
  created(){
    let { id } = this
    this.$store.commit('Users/ADD', {id})
  }
}
</script>

<style lang="scss">
.upload-profile-pfoto{
  .avatar-displaying{
    cursor: pointer;
    margin-top: 20px;

    &.business{
      margin-top: 0;
      margin-bottom: 20px;
    }

    > img{
      width: 120px;
      height: 120px;
      border-radius: 100%;
    }
  }

  .cm-wrap{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 999;
    background-color: #00000042;

    display: flex;
    align-items: center;
    justify-content: center;

    .cm{
      padding: 15px;
      width: 95%;
      max-width: 400px;
      background-color: white;

      .cm-modal-header{
        position: relative;
        height: 15px;

        .close-btn{
          width: 28px;
          height: 28px;
          opacity: 0.5;
          position: absolute;
          right: 0;

          &:hover {
            opacity: 1;
          }

          &:before, &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 28px;
            width: 2px;
            background-color: #333;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }

      .cm-modal-body{
        text-align: center;

        .progress{
          display: flex;
          align-items: center;
          justify-content: center;
          
          height: 100px;
          border: 1px #d1d3d4 solid;
          background-color: #f8f8f9;
          border-radius: 0;
          font-size: 14px;
          color: #4a4a4a;
        }

        .cropper-view-box,
        .cropper-face {
          border-radius: 50% !important;
        }

        .upload-another{
          cursor: pointer;
          margin-top: 15px;
        }
      }

      .cm-modal-footer{
        margin-top: 15px;
        
        .save-btn{
          cursor: pointer;
          text-align: center;
          padding: 10px;
          color: white;
          background-color: #4c419a;

          &:hover{
            background-color: #26004b !important;
          }
        }
      }

    } 
  }

  .avatar-input{ display: none; }
}
</style>