import request from '@utils/request'

export function createEmbedUrlInfo(payload) {
    return request({
        url: '/embed_url_info',
        method: 'post',
        params: {
            embed_url_info: {
                // url: '',
                // load_img: true, // upload img to our S3 for cropper
                ...payload
            }
        },
    })
}

export function getEmbedUrlInfo(id) {
    return request({
        url: `/embed_url_info/${id}`,
        method: 'get'
    })
}
