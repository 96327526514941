<template lang="html">
    <component :is="data.component" v-bind="data.props"></component>
</template>

<script>
    import VideoForm from "./VideoForm"
    import UploadCover from "./UploadCover"
    import UploadCoverScribe from "./UploadCoverScribe"
    import UploadCardDetail from "./UploadCardDetail"
    import UploadProfileBanner from "./UploadProfileBanner"
    import UploadProfilePhoto from "./UploadProfilePhoto"
    import UploadOldImage from "./UploadOldImage"
    import IncompleteViewingMsg from "../app/components/IncompleteViewingMsg"
    import HomeFeed from "../app/pages/TrendingFeeds/HomeFeed"
    import ProductPreviewVideo from "./components/products/preview/Video"
    import ProductPreviewAttachments from "./components/products/preview/Attachments"
    import CardFormSelectVideoSource from "./components/cards/form/SelectVideoSource"
    import SubscriptionsSettings from "./SubscriptionsSettings"
    import CardFormSelectAudioSource from "./components/cards/form/SelectAudioSource"

    export default {
        name: 'comp-wrapper',
        components: { 
            VideoForm,
            UploadCover,
            UploadCoverScribe,
            UploadCardDetail,
            UploadProfileBanner,
            UploadProfilePhoto,
            UploadOldImage,
            IncompleteViewingMsg,
            HomeFeed,
            ProductPreviewVideo,
            ProductPreviewAttachments,
            CardFormSelectVideoSource,
            SubscriptionsSettings,
            CardFormSelectAudioSource,
        },
        data() {
            return {data: {}}
        },
        created() {
            this.data = JSON.parse(this.$attrs.data)
        }
    }
</script>

<style lang="scss">
    .expanded-for-modal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 !important;
    }
</style>