import { Model } from '@vuex-orm/core';

class Profile extends Model {
    static entity = 'profile'

    static fields () {
        return {
            id: this.attr(),
            first_name: this.attr(),
            last_name: this.attr(),
            phone: this.attr(),
            description: this.attr(),
            profile_email: this.attr(),
            profile_website: this.attr(),
            profile_attachment: this.attr(),
            gender: this.attr(),
            link_facebook: this.attr(),
            link_twitter: this.attr(),
            link_instagram: this.attr(),
            link_linkedin: this.attr(),
            link_blog: this.attr(),
            link_other: this.attr(),
            avatar: this.attr(),
            social_avatar: this.attr(),
            email: this.attr(),
            age_range: this.attr(),
            only_friend_can_see: this.attr(),
            location_address: this.attr(),
            friend_count: this.attr(),
            followers_count: this.attr(),
            public_email: this.attr(),
            use_email_as_public_email: this.attr(),
            folders_count: this.attr(),
            is_plus_account: this.attr(),
            banner: this.attr(),
            banner_image_url: this.attr(),
            products_count: this.attr(),
            trusted: this.attr(),
            trusteds_count: this.attr(),
            followed: this.attr(),
            is_friend: this.attr(),
            sent_friend_status: this.attr(),
            bio: this.attr()
        }
    }
}

export default Profile