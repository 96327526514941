<template>
<div class="CoverImg cover-img-component">
    <div class="cover-img" :style="{'background-image': `url(${ src })`, 'padding-top': `${height_ratio * 100}%` }">
        <slot />
    </div>
</div>
</template>

<script>
    export default {
        name: 'CoverImg',
        props: {
            src:            {type: String, default: ''},
            height_ratio:   { type: Number, default: 2/3 }
        },
    }
</script>

<style lang="scss" scoped>
.cover-img-component {
    position: relative;
    width: 100%;
    .cover-img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        //padding-top: 66.6666%; // height_ratio: 2/3
    }
}
</style>