<template>
    <div :class="['upload-image-component', {'u-disabled': !isAllowToSelectFile}]"
         :title="unableUploadTitle"
         @click="onClick"
         @drop.prevent="onDrop"
         @dragover.prevent="dragover = true"
         @dragleave.prevent="dragover = false"
         v-cloak>
        <input ref="fileInput"
               @change="selectionProcessing($event)"
               type="file"
               accept=".jpeg, .jpg, .png">

        <div v-if="Object.keys(processing_errors).length > 0 || image.status === 'INITIATED'">
            <div class="upload-image-title">
                <b>Upload</b>
            </div>
        </div>

        <div v-else-if="image.status==='CHECKING'">Checking...</div>
        <div v-else-if="image.status==='UPLOADING'">{{image.upload_percentage}}%</div>
        <div v-else-if="['COMPLETE'].includes(image.status)">Complete</div>
    </div>
</template>

<script>
    export default {
        props: ['uid', 'holder'],
        data() {
            return {
                dragover: false,
                canReserveHolder: true,
            }
        },
        computed: {
            image: function () {
                return this.$store.getters['Images/getImageByUid'](this.uid) || {}
            },
            processing_errors: function () {
                return this.image.processing_errors || {}
            },
            holder_type() {
                return this.holder.class_name
            },
            holder_id() {
                return this.holder.api_state.id
            },
            isAllowToSelectFile() {
                let errorsCount = Object.keys(this.processing_errors).length,
                    isInitiated = this.image.status == 'INITIATED'
                return (isInitiated || errorsCount > 0) && (this.holder_id || this.canReserveHolder)
            },
            unableUploadTitle() {
                return !this.isAllowToSelectFile ? "Please, select video before upload thumbnail" : ""
            },
            is_plus_user(){
                const user = JSON.parse(localStorage.getItem('user'));
                return user && user.is_plus_account
            },
        },
        watch: {
            'image.status': function (val) {
                if (val === 'COMPLETE') { this.$emit('onComplete') }
            },
            'holder_type': function (holder_type) {
                let {uid} = this
                this.$store.commit('Images/UPDATE', {uid, holder_type})
            },
            'holder_id': function (holder_id) {
                let {uid, holder_type} = this
                if (!this.image.status) {
                    this.$store.commit('Images/ADD', {uid, holder_type, holder_id})
                }
                this.$store.commit('Images/UPDATE', {uid, holder_id})
            },
            'processing_errors': function() {
                this.displayValidationErrors()
            }
        },
        methods: {
            async selectionProcessing(event) {
                let file = event.target.files[0];
                const {uid} = this

                if (this.canReserveHolder && !this.holder_id && this.holder_type === 'Media::Video') {
                    this.$store.commit('Videos/updateField', { path: 'uploads.main.api_state.reserved', value: true });
                    await this.$store.dispatch('Videos/createVideo', { kind: 'main' });
                }
                this.$store.commit('Images/PARSE_FILE', {uid, file})
                await this.$store.dispatch('Images/create', {uid})
                await this.$store.dispatch('Images/upload', {uid})
                await this.$store.dispatch('Images/update', {uid})
            },
            onClick() {
                if (!this.isAllowToSelectFile) return;
                this.$refs.fileInput.click()
            },
            onDrop() {
                let {uid, isAllowToSelectFile} = this,
                    droppedFiles = e.dataTransfer.files;
                this.dragover = false;
                if (!isAllowToSelectFile) return;
                if (!droppedFiles) return;
                if (droppedFiles.length > 1) {
                    this.$store.commit('Images/UPDATE', {
                        uid,
                        processing_errors: {too_many_files: ['Select please only one file']}
                    })
                } else {
                    this.$refs.fileInput.files = droppedFiles
                    this.$refs.fileInput.dispatchEvent(new Event("change"))
                }
            },
            displayValidationErrors() {
                let { processing_errors, is_plus_user } = this
                Object.keys(processing_errors).forEach(function (k) {
                    let error = `${k}: ${processing_errors[k]}`;
                    if (k === 'space_limited') {
                        const no_plus_part = is_plus_user ? `<span>, or upgrade to <a href="/subscriptions" target="_blank" @click.stop="">Plus account</a></span>` : ''
                        error = `The file you are trying to upload exceeds the allotted storage.
                              Please clean your <a href="/my_storage"  target="_blank" @click.stop="">Storage</a> first before attempting to re-upload it${ no_plus_part }.`
                    }
                    iziToast.error({ message: error, maxWidth: '700px' })
                });
            }
        },
        created() {
            let {uid, holder_type, holder_id} = this
            if (!this.image.status) {
                this.$store.commit('Images/ADD', {uid, holder_type, holder_id})
            }
        }
    }
</script>

<style lang="scss">
    .upload-image-component {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        padding: 5px;
        height: 60px;
        width: 100%;
        border: 1px #d1d3d4 dashed;
        background-color: #f8f8f9;
        cursor: pointer;
        z-index: 1;
        &:not(.u-disabled){
            &:hover {
                border-color: #8f969d;
                .upload-video-title {
                    color: #212529;
                }
            }
            // dragover hover
            &.dragover {
                border-color: #8f969d;
                .upload-video-title {
                    color: #212529;
                }
            }
        }
        &.u-disabled{
            cursor: not-allowed;
        }
        .upload-image-title {
            font-family: "montserrat-reg";
            font-size: 14px;
            color: #a7a9ac;
            text-align: center;
        }
        input[type='file'] {
            display: none;
        }
    }
</style>