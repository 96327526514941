<template>
<modal
    name="become-pro"
    :width="0"
    @before-open="beforeOpen">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <div class="text__center margin-b__20">
                <b>{{ $t('BECOME_PRO_MODAL.TITLE') }}</b>
            </div>
            <p class="text__center margin-b__20">
                {{ $t('BECOME_PRO_MODAL.P_1') }}
            </p>
            <p
                class="text__center"
                v-html="$t('BECOME_PRO_MODAL.P_2', {email: email})" />
        </section>
    </div>
</modal>
</template>

<script>
    export default {
        name: 'BecomeProModal',
        data() {
            return {
                email: ''
            }
        },
        methods: {
            // @vuese
            // Method to hide BecomePro modal
            hide() {
                this.$modal.hide('become-pro')
            },
            // @vuese
            // Method that call before modal open and set to local data email
            // @arg The argument is modal-open event with param email [string]
            beforeOpen(event) {
                if (event && event.params && event.params.email) {
                    this.email = event.params.email
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal__sphir_Online_Access_Agreement {
        section {
            width: 50rem;
        }
    }
</style>