<template>
<div class="upload-old-image">
  <div v-show="display == 'image'" class="display-image">
    <img :src="imageSrc" alt="Group image">
    <div @click="uploadAnotherImage()" class="gi-change-image">Change Image</div>
  </div>
  <div v-show="display == 'dropzone'" 
      @click="onClick()" 
      :class="['gi-dropzone', in_progress, {'dragover': dragover}]"
      @drop.prevent="onDrop"
		  @dragover.prevent="dragover = true"
		  @dragleave.prevent="dragover = false"> 
    <input ref="fileInput"
          @change="selectionProcessing($event)"
          type="file"
          accept=".jpeg, .jpg, .png">
    
    <div v-if="Object.keys(processing_errors).length > 0 || image.status === 'INITIATED'">

      <div class="upload-image-title">
        <span class="bold-text">Upload cover Image</span>
      </div>

      <div v-if="Object.keys(processing_errors).length > 0" v-for="(messages, field) in processing_errors" class="errors">
        <p>{{field | capitalize | unSnakeCase }}</p>
        <div v-if="field === 'space_limited'" class="space_limited_msg">
          The file you are trying to upload exceeds the allotted storage.
          Please clean your <a href="/my_storage"  target="_blank" @click.stop="">Storage</a> first before attempting to re-upload
          it<span v-if="!is_plus_user">, or upgrade to <a href="/subscriptions" target="_blank" @click.stop="">Plus account</a>.</span>
        </div>
        <ul v-else>
          <li v-for="message in messages">
            {{message | capitalize}}
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="image.status==='CHECKING'">Checking...</div>
    <div v-else-if="image.status==='UPLOADING'">{{image.upload_percentage}}%</div>
    <div v-else-if="['COMPLETE'].includes(image.status)">Complete</div>
  </div>
  <div v-show="display == 'cropper'" class="gi-cropper-wrap">
    <div class="gim">
      <div class="gim-header">
        <div @click="closeCropprModal()" class="close-btn"></div>
      </div>
      <div class="gim-body">
        <p class="bold-txt" >Create Group Picture</p>
        <p>To crop this image, drag the region below and then click "Set as Group Picture"</p>

        <vue-cropper
          class="vue-cropper"
          ref="cropper"
          :src="image.url"
          alt="Group pfoto"
          :viewMode="2"
          :aspectRatio="1/1"
          :minCropBoxWidth="50"
          :minCropBoxHeight="50">
        </vue-cropper>

        <div @click="uploadAnotherImage()" class="bold-txt upload-another"> 
          <i class="fa fa-upload"></i> 
          Upload another image?
        </div>
      </div>
      <div class="gim-footer">
        <div @click="save()" class="save-btn bold-txt">Set as Group Picture</div>
      </div>
    </div>
  </div>
  <input v-model="image.id" type="hidden" name="group[group_image_id]">
</div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import device from 'current-device'

export default {
  components: { VueCropper },
  props: ['uid', 'initImageUrl'],
  data() {
    return {
			display: 'dropzone', // image || dropzone || cropper 
			dragover: false,
			maxFileSize: 1024 * 1024 * 10
    }
  },
  watch: {
    status: function(ov, nv){
      let closeGroupModalBtn, doneGroupModal
      
      if($("#remote_bs_model .modal-header .body-black:contains(Create Group)").is(":visible")){
        closeGroupModalBtn = $("#remote_bs_model .modal-header .body-black:contains(Create Group)").siblings('.close')
        doneGroupModal = $("#remote_bs_model .group-form .create-group-continue")
      }else{
        closeGroupModalBtn = $("#remote_bs_model .modal-header .body-black:contains(Update Group)").siblings('.close')
        doneGroupModal = $("#remote_bs_model #group-update-form .update-group-submit")
      }

      if(nv == 'CHECKING' || nv == 'PROCESSING' || nv == 'UPLOADING'){
        closeGroupModalBtn.attr("disabled", true)
        doneGroupModal.attr("disabled", true)
      }else{
        closeGroupModalBtn.attr("disabled", false)
        doneGroupModal.attr("disabled", false)
      }
      let create_group_modal_close_icon = $("#remote_bs_model .group-section .update-card-design .close") 
      if(create_group_modal_close_icon.length > 0){
        create_group_modal_close_icon.attr('disabled',false)
      }
    }
  },
	computed: {
		image: function () {
			return this.$store.getters['Old_images/getImageByUid'](this.uid)
		},
		processing_errors: function () {
			return this.image.processing_errors
		},
    imageSrc(){
      if(this.image && this.image.croppedImageUrl){
        return this.image.croppedImageUrl
      }else{
        return this.initImageUrl
      }
    },
    status: function() {
      return (this.image || {}).status
    },
    in_progress: function () {
			return (['INITIATED', 'COMPLETE'].includes(this.image.status) ? '' : 'in-progress');
		}
	},
  methods: {
    onClick() {
			if(["INITIATED", "COMPLETE"].includes(this.image.status)){
				this.$refs.fileInput.click()
			}
    },
    onDrop(e) {
			if(["INITIATED", "COMPLETE"].includes(this.image.status)){
				let {uid} = this,
				droppedFiles = e.dataTransfer.files;
				this.dragover = false;
				if (!droppedFiles) return;
				if (droppedFiles.length > 1) {
					this.$store.commit('Old_images/UPDATE', {
						uid,
						processing_errors: {too_many_files: ['Select please only one file']}
					})
				} else {
					this.$refs.fileInput.files = droppedFiles
					this.$refs.fileInput.dispatchEvent(new Event("change"))
				}
			}
		},
    async selectionProcessing(event){
      let file = event.target.files[0];
			const {uid} = this
			if(file){
				if(file.size > this.maxFileSize){
					this.$store.commit('Old_images/UPDATE', {
						uid,
						processing_errors: {too_big_file: ['Please select a file not exceeding 10 Mb']}
					})
				}else{
					this.$store.commit('Old_images/PARSE_FILE', {uid, file})

					if (typeof FileReader === 'function') {
						const reader = new FileReader();
						reader.onload = (event) => {
							this.imgSrc = event.target.result;
							this.$refs.cropper.replace(event.target.result);
						};
            reader.readAsDataURL(file);
						this.display = 'cropper'
					} else {
						console.error('Sorry, FileReader API not supported');
					}
				}
			}
    },
    closeCropprModal(){
      if(!["UPLOADING", "PROCESSING"].includes(this.status)){
        if(this.image && this.image.croppedImageUrl){
          this.display = 'image'
        }else{
          this.display = 'dropzone'
          this.$store.commit('Old_images/UPDATE', { uid: this.uid, status: "INITIATED"})
        }
      }
    },
    uploadAnotherImage(){
       if(!["UPLOADING"].includes(this.status)){
         this.$refs.fileInput.click()
       }
    },
    async save(){
      let {uid} = this
			let croppedImageUrl = this.$refs.cropper.getCroppedCanvas().toDataURL()
      let croppData 	    = this.$refs.cropper.getData()

      this.display = 'dropzone'
      
      this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
				blob.lastModifiedDate = new Date()
				blob.name = this.image.origin_name
				this.$store.commit('Old_images/UPDATE', { uid, croppedImageUrl, croppData, croppedImageFile: blob})
				this.$store.commit('Old_images/PARSE_FILE', {uid, file: blob})
        
        await this.$store.dispatch('Old_images/create', {uid})
				await this.$store.dispatch('Old_images/upload', {uid})
        await this.$store.dispatch('Old_images/update', {uid})

				this.$store.commit('Old_images/UPDATE', { uid, upload_percentage: 0 })
        
        this.display = 'image'
        this.$refs.fileInput.value = ''
			})
    }
  },
  created(){
    let {uid} = this
    this.$store.commit('Old_images/ADD', {uid})

    if(this.initImageUrl){ this.display = 'image' }

    $("#remote_bs_model:contains(Create Group)").on("hide.bs.modal", () => {
      this.$store.commit('Old_images/CLEAR', {uid})
    });
  }  
}
</script>

<style lang="scss">
.upload-old-image{
  .display-image{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    >img{
      width: 200px;
      height: 200px;
      border-radius: 100%;
    }

    .gi-change-image{
      height: 40px;
      margin-top: 10px;
      cursor: pointer;
      color: #a7a9ac;
      background: #f8f8f9;
      text-align: center;
      border-radius: 20px;
      padding: 10px 15px;
      font-size: 14px;
      font-family: "montserrat-bold";
      border: 1px solid #d1d3d4;

      &:hover{
        background: #4b419a;
        border: 1px solid #4b419a;
        color: white;
        text-decoration: none;
      }
    }
  }

  .gi-dropzone{
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    padding: 5px;
    min-height: 90px;
    width: 100%;
    border: 1px #d1d3d4 dashed;
    background-color: #f8f8f9;
    cursor: pointer;
    z-index: 1;

    &.in-progress{
      cursor: progress;
    }

    &:hover {
      border-color: #8f969d;
      .upload-image-title {
          color: #808285;
      }
    }
    // dragover hover
    &.dragover {
      cursor: copy;
      border-color: #8f969d;
      .upload-image-title {
        color: #808285;
      }
    }

    .upload-image-title {
      font-family: "montserrat-bold";
      font-size: 14px;
      color: #a7a9ac;
      text-align: center;
    }

    input[type='file'] {
      display: none;
    }
  }

  .gi-cropper-wrap{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    padding-top: 29px;
    padding-bottom: 20px;

    display: block;
		align-items: center;
		justify-content: center;
    background: #00000075;
    overflow-y: auto;

    .gim{
      padding: 15px;
      width: 95%;
      max-width: 400px;
      background-color: white;
      margin: 0 auto;
      border-radius: 5px;

      .gim-header{
        position: relative;
        height: 15px;

        .close-btn{
          width: 28px;
          height: 28px;
          opacity: 0.5;
          position: absolute;
          right: 0;

          &:hover {
            opacity: 1;
          }

          &:before, &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 28px;
            width: 2px;
            background-color: #333;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }

      .gim-body{
        text-align: center;

        .cropper-view-box,
        .cropper-face {
          border-radius: 50% !important;
        }

        .upload-another{
          cursor: pointer;
          margin-top: 15px;
        }
      }

      .gim-footer{
        margin-top: 15px;
        
        .save-btn{
          cursor: pointer;
          text-align: center;
          padding: 10px;
          color: white;
          background-color: #4c419a;

          &:hover{
            background-color: #26004b !important;
          }
        }
      }
    }
  }

  .errors {
    color: #5a5a5a;
    margin-top: 10px;
    text-align: left;
    p {
      margin: 0;
      color: #4b419a;
    }
    .space_limited_msg {
      padding: 5px 10px;
    }

  }
}
</style>