<template>
<div>
    <a class="post-embed" :href="url" target="_blank">
        <div class="embed-img" :style="{'background-image': `url(${img_src})`}" />
        <div class="embed-cont">
            <div class="embed-provider">{{ provider }}</div>
            <div class="embed-title">{{ title }}</div>
            <div class="embed-description">{{ description }}</div>
            <div v-if="with_delete_btn" class="embed-clear" @click.stop.prevent="$emit('delete')">
                <img src="https://enklav-production.s3.amazonaws.com/uploads/image/circle-x-black.svg">
            </div>
        </div>
    </a>
</div>
</template>

<script>

export default {
    name: 'TextEmbed',
    props: {
        embed_url_info: {type: Object, default: () => {}},
        with_delete_btn: {type: Boolean, default: () => false},
    },
    computed: {
      url () {
        return this.embed_url_info.url
      },
      provider () {
        return this.embed_url_info.provider_url.replace(/https?:\/\/(www\.)*/, '')
      },
      img_src () {
        return this.embed_url_info.s3_img_src || this.embed_url_info.img_src
      },
      title () {
        return this.embed_url_info.title
      },
      description () {
        return this.embed_url_info.description
      },
    },
}
</script>

<style lang="scss" scoped>
  .post-embed {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      margin-bottom: 2rem;
  }
</style>