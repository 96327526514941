import { Model } from '@vuex-orm/core';
// import User from '@models/User';

class UserFile extends Model {
    static entity = 'userfile'

    static fields () {
        return {
            id: this.attr(),
            original_file_name: this.attr(null),
            created_at: this.attr(null),
            original_file_size: this.attr(null),
            total_files_size: this.attr(null),
            type: this.attr(null)
        }
    }
}

export default UserFile