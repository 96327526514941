import { render, staticRenderFns } from "./AddFriendListBtn.vue?vue&type=template&id=479931df&scoped=true&"
import script from "./AddFriendListBtn.vue?vue&type=script&lang=js&"
export * from "./AddFriendListBtn.vue?vue&type=script&lang=js&"
import style0 from "./AddFriendListBtn.vue?vue&type=style&index=0&id=479931df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479931df",
  null
  
)

export default component.exports