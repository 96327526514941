const getDefaultState = () => {
    return {
        loading: false
    }
}

const loader = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getLoaderState: state => state.loading
    },
    mutations: {
        SET_LOADER(state, data) {
            state.loading = data
        }
    },

    actions: {
        LOADER({ commit }, status) {
            commit('SET_LOADER', status)
        }
    }
}

export default loader;