<template>
<form @submit.prevent="reset">
    <div class="modal__head text__bold text__center">
        {{ $t('FORGOT.TITLE') }}
    </div>
    <div class="modal__body">
        <p class="text__center">
            {{ $t(expiredMessage) || $t('FORGOT.P_1') }}
        </p>
        <div class="input__field">
            <input
                id="reset_email"
                v-model="email"
                type="email"
                name="email"
                placeholder=" "
                @blur="validateEmail"
                @focus="clearError('forgot')">
            <label
                class="label"
                for="reset_email">{{ $t('INPUT.EMAIL-AD') }}</label>
        </div>
    </div>
    <div class="modal__footer margin-t__60">
        <div
            v-show="error('forgot') || localError"
            class="error-message"
            v-html="error('forgot') || localError" />
        <button
            class="btn full__width"
            :disabled="isBtnDisabled"
            @click="reset">
            {{ message || $t('LINK.RESET') }}
        </button>
    </div>
</form>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'

    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

    export default {
        name: 'ForgotPass',
        props: {
            expiredMessage: {type: String, default: ''}
        },
        data() {
            return {
                email: '',
                localError: '',
                disabled: false,
                message: ''
            }
        },
        computed: {
            ...mapGetters('auth', {
                error: 'getError'
            }),
            isBtnDisabled() {
                return !this.email || this.localError !== '' || this.disabled || this.error('forgot') !== ''
            }
        },
        methods: {
            ...mapActions('auth', {
                send: 'FORGOT_PASSWORD'
            }),
            ...mapMutations('auth', {
                clearError: 'CLEAR_ERROR',
                showForm: 'SHOW_FORM'
            }),
            async reset() {
                event.preventDefault();
                event.stopPropagation();
                let response = await this.send(this.email)
                if (response && response.status === 200) {
                    const {data} = response
                    this.message = data.message
                    this.disabled = true
                    let that = this
                    setTimeout(function () {
                        that.$modal.hide('auth-form')
                        that.showForm('login')
                    }, 5000)
                }
            },
            validateEmail() {
                (this.email == '') ? '' : (reg.test(this.email)) ? this.localError = '' : this.localError = this.$t('VALIDATION.EMAIL');
            },
        }
    }
</script>