import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import store from './store/store'

Vue.use(VueRouter)

// components
const Landing = () => import('./pages/static/Landing.vue')
const AboutUs = () => import('./pages/static/AboutUs.vue')
const Styleguide = () => import('./pages/dev/Styleguide.vue')
const Terms = () => import('./pages/static/Terms.vue')
const Privacy = () => import('./pages/static/Privacy.vue')
const ContentGuideline = () => import('./pages/static/ContentGuideline.vue')
const CardGuideline = () => import('./pages/static/CardGuideline.vue')
const Legal = () => import('./pages/static/Legal.vue')
const Copyright = () => import('./pages/static/Copyright.vue')
const SignUp = () => import('./pages/SignUp.vue')
const SignIn = () => import('./pages/SignIn.vue')
const UpdatePass = () => import('./pages/UpdatePass.vue')
const Trademarks = () => import('./pages/static/Trademarks.vue')
const CommerceGuideline = () => import('./pages/static/CommerceGuideline.vue')
const CopyrightInfringement = () => import('./pages/static/CopyrightInfringement.vue')
const TrademarkInfringement = () => import('./pages/static/TrademarkInfringement.vue')
const PatentInfringement = () => import('./pages/static/PatentInfringement.vue')
const TradeSecret = () => import('./pages/static/TradeSecret.vue')
const Help = () => import('./pages/static/Help.vue')
const Discover = () => import('./pages/Discover/index.vue')
const ErrorPage = () => import('./pages/static/ErrorPage.vue')
const SearchFeed = () => import('./pages/TrendingFeeds')
const BecomePro = () => import ('./pages/BecomePro.vue')
const FeedPage = () => import('./pages/FeedPage.vue')
const Storage = () => import('./pages/Storage.vue')
const Group = () => import('./pages/Group/index.vue')
const Profile = () => import ('./pages/Profile/index.vue')
const Post = () => import('./pages/Post.vue')

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '*',
            name: 'not-found',
            component: ErrorPage
        },
        {
            path: '/users/profile',
            name: 'my-profile',
            component: Profile
        },
        {
            path: '/',
            component: Landing,
            name: "landing",
            meta: { auth_guest: true }
        },
        {
            path: '/help',
            component: Help,
            name: 'help'
        },
        {
            path: '/groups/:id',
            component: Group,
            name: 'group'
        },
        {
            path: '/posts/:id',
            component: Post,
            name: 'post'
        },
        {
            path: '/users/:id/other_profile',
            component: Profile,
            name: 'other_profile'
        },
        {
            path: '/trademarks_patents_licenses',
            component: Trademarks,
            name: 'trademarks'
        },
        {
            path: '/trade_secrets',
            component: TradeSecret,
            name: 'trade-secrets'
        },
        {
            path: '/commerce_guidelines',
            component: CommerceGuideline,
            name: 'commerce-guideline'
        },
        {
            path: '/patent_infringement',
            component: PatentInfringement,
            name: 'patent-infringement'
        },
        {
            path: '/copyright_infringement',
            component: CopyrightInfringement,
            name: 'copyright-infringement'
        },
        {
            path: '/trademark_infringement',
            component: TrademarkInfringement,
            name: 'trademark-infringement'
        },
        {
            path: '/users/sign_up',
            component: SignUp,
            name: 'sign-up',
            meta: { auth_guest: true }
        },
        {
            path: '/users/sign_in',
            component: SignIn,
            name: 'sign-in',
            meta: { auth_guest: true }
        },
        {
            path: '/users/update_password',
            component: UpdatePass,
            name: 'update-password',
            beforeEnter: (to, from, next) => {
                let token = to.query.reset_password_token
                if (token) {
                    store.dispatch('auth/CHECK_CONFIRM_TOKEN', token).then((response) => {
                            response.status === 200 ? next() : next({path: '/#forgot_error'})
                        }
                    )
                } else {
                    next({path: '/'})
                }
            }
        },
        {
            path: '/about_us',
            component: AboutUs,
            name: 'about-us',
        },
        {
            path: '/assets/about_us',
            component: AboutUs,
            name: 'about-us',
        },
        {
            path: '/discover',
            component: Discover,
            name: 'discover',
        },
        {
            path: '/become_pro',
            component: BecomePro,
            name: 'become-pro',
            meta: { auth_user: true }
        },
        {
            path: '/terms_of_use',
            component: Terms,
            name: "terms-of-use"
        },
        {
            path: '/privacy_policy',
            component: Privacy,
            name: 'privacy-policy'
        },
        {
            path: '/content_and_behavior_guidelines',
            component: ContentGuideline,
            name: 'content-and-behavior-guidelines'
        },
        {
            path: '/card_guidelines',
            component: CardGuideline,
            name: 'card-guidelines'
        },
        {
            path: '/legal',
            component: Legal,
            name: 'legal'
        },
        {
            path: '/copyright_and_dmca_policy',
            component: Copyright,
            name: 'copyright-and-dmca-policy'
        },
        {
            path: '/styleguide',
            component: Styleguide,
            name: 'styleguide',
            beforeEnter: (to, from, next) => {
                process.env.NODE_ENV === 'development' || window.location.host === 'uat.sphir.io' ? next() : next({path: '/'})
            }
        },
        {
            path: '/trending_feeds',
            component: SearchFeed,
            name: 'search-feed',
            meta: { auth_user: true }
        },
        {
            path: '/my_storage',
            component: Storage,
            name: 'storage-page',
            meta: { auth_user: true }
        },
        {
            path: '/users/home',
            component: FeedPage,
            name: 'feed',
            meta: { auth_user: true }
        }
    ]
});

router.afterEach(function (to, from) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (Vue.prototype.showAuthBtn) {
        if (to.hash.includes('login') && !user) {
            Vue.prototype.$modal.show('auth-form')
        }
        if (to.hash.includes('forgot_error')) {
            Vue.prototype.$modal.show('auth-form', {text: 'VALIDATION.TOKEN:EXPIRED'})
            store.commit('auth/SHOW_FORM', 'forgot')
        }
        if (to.hash.includes('forgot')) {
            Vue.prototype.$modal.show('auth-form')
            store.commit('auth/SHOW_FORM', 'forgot')
        }
        if (to.hash.includes('join')) {
            Vue.prototype.$modal.show('join-form')
        }
        if (to.hash.includes('resend_confirmation')) {
            Vue.prototype.$modal.show('thanks-form')
        }
    }
    if (to.hash.includes('post-form-with')) {
        const [_, type, id] = to.hash.split('_') //type => card|product
        const post = {}
        post[`with_${type}_id`] = id
        Vue.prototype.$modal.show('post-form', { post })
        router.push({hash: ``})
    }
    if (to.hash.includes('post-form')) {
        Vue.prototype.$modal.show('post-form')
    }
})

router.beforeEach(function (to, from, next) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (to.matched.some(record => record.meta.auth_guest) && user) {
        next({ path: '/users/home' })
    } else if (to.matched.some(record => record.meta.auth_user) && !user) {
        next({path: '/#login'})
    } else {
        next()
    }
});


export default router