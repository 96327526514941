import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import createLogger from 'vuex/dist/logger'
import modules from './modules'

Vue.use(Vuex)
const debug = true; //process.env.NODE_ENV !== 'production'

let store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules,
    strict: debug,
    plugins: debug? [ createLogger() ] : []
})
export default store
