import {Model} from '@vuex-orm/core'
import Photo from '@models/Photo'

class Album extends Model {
    static entity = 'albums'

    static fields() {
        return {
            id: this.uid(),
            title: this.attr(''),
            systemic: this.attr(false),
            photo_ids: this.attr([]),
            cover: this.attr([]),
            created_at: this.attr(null),
            photos: this.hasMany(Photo, 'album_id')
        }
    }

    static apiConfig = {
        actions: {
            async fetch(params = {}) {
                return await this.get("/storage/albums", { params: params, dataKey: 'response' })
            },
            async fetchOne(id) {
                return await this.get(`/storage/albums/${id}`, { dataKey: 'response' })
            },
            async create(params = {}) {
                try {
                    let result = await this.post('/storage/albums', params, { dataKey: 'response' }),
                        photo_ids = result.response.data.response.photo_ids,
                        album_id = result.response.data.response.id
                    photo_ids.map((id) => { Photo.update({id, album_id}) })
                    return result.save()
                } catch(response) {
                    // TODO refactor - define handler
                    let message = ""
                    for(let field in response.data.message){
                        let errors = response.data.message[field]
                        errors.map((error) => {
                            message += `${field.capitalize()} ${error}.`
                        })
                    }
                    iziToast.error({ message: message, maxWidth: '700px' })
                    return;
                }
            },
            async update(id, params = {}) {
                try {
                    let result = await this.put(`/storage/albums/${id}`, params, { dataKey: 'response' }),
                        photo_ids = result.response.data.response.photo_ids,
                        album_id = result.response.data.response.id
                    photo_ids.map((id) => { Photo.update({id, album_id}) })
                    return result.save()
                } catch(response) {
                    // TODO refactor - define handler
                    let message = ""
                    for(let field in response.data.message){
                        let errors = response.data.message[field]
                        errors.map((error) => {
                            message += `${field.capitalize()} ${error}.`
                        })
                    }
                    iziToast.error({ message: message, maxWidth: '700px' })
                    return;
                }
            },
            async destroy(id) {
                try {
                    await this.delete(`/storage/albums/${id}`, { delete: id })
                } catch(err) {
                    iziToast.error({ message: err.data.message })
                }
            }
        }
    }
}

export default Album