<template>
<modal
    name="search-card-collection"
    :width="0"
    @before-open="modalOpened = true"
    @closed="modalOpened = false">
    <div class="modal show modal__search-card-collection modal__new_design">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <SearchCardCollection v-if="modalOpened" @close="hide" />
        </section>
    </div>
</modal>
</template>


<script>
import SearchCardCollection from '../PostForm/SearchCardCollection'
    export default {
        name: 'SearchCardCollectionModal',
        components: { SearchCardCollection },
        data() {
            return {
                modalOpened: false
            }
        },
        methods: {
            hide() {
                this.$modal.hide('search-card-collection')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>