<template>
    <div
            ref="theoplayer"
            class="theoplayer-container video-js theoplayer-skin vjs-fill THEOplayer"
    />
</template>

<script>
    export default {
        props: {
            source: {type: Array, default: () => []},
            poster: {type: String, default: ''}
        },

        data() {
            return {
                player: null
            }
        },

        watch: {
            source: {
                deep: true,
                handler() {
                    this.setSource()
                }
            },
            poster: function (nv, ov) {
                this.setSource()
            },
        },

        mounted() {
            this.playerInit();
        },

        methods: {
            playerInit() {
                if (this.player) {
                    return
                }
                const theo_lib_key = document.getElementById('THEOPLAYER_LIB_KEY').dataset.key;
                this.player = new window.THEOplayer.Player(this.$refs.theoplayer, {
                    fluid: true,
                    libraryLocation: `https://cdn.myth.theoplayer.com/${theo_lib_key}`
                });
                this.setSource()
            },
            setSource() {
                this.player.source = {
                    sources: this.source,
                    poster: this.poster
                };
            }
        },
    };
</script>

<style lang="scss">
    .tile__s__video > .video-js {
        position: absolute;
    }
</style>