import { Model } from '@vuex-orm/core';
import Post from "@models/Post";
import Comment from "@models/Comment";

class User extends Model {
    static entity = 'users'

    static fields () {
        return {
            id: this.attr(null),
            email: this.attr(''),
            // relationships
            posts: this.hasMany(Post, 'user_id'),
            comments: this.hasMany(Comment, 'user_id')
        }
    }
}

export default User