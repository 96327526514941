import Photo from '@models/Photo'

const photos = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        deselectAll() {
            Photo.query().updateByCondition({selected: false}, (photo) => photo.selected === true)
        },
    }
}

export default photos