<template>
<div class="create-card-btn-component CreateCardBtn">
    <button class="margin-l__10 btn btn__round btn__bordered" @click="btnOnClick">
        {{ $t('BUTTON.CREATE_CARD') }}
    </button>
</div>
</template>

<script>
    export default {
        methods: {
            btnOnClick() {
                this.$emit('click');
                $(".modal-save-to-lib .btn-close-savetolib").off("click").on("click", function () {
                    $(".modal-new-folder").modal("hide");
                    $(".modal-save-to-lib").removeClass("prevent-set-close")
                    const isClaimedCard = false //(card.claim_date || card.owner_id)
                    let message = 'Card successfully saved'
                    message += (( isClaimedCard ? ' in My Claimed' : ' in Unfiled') + ' Cards Collection.')
                    iziToast.success({ title: 'Success', message });
                });
                $(".modal-save-to-lib").addClass("prevent-set-close"); //prevent redirect by close modal-save-to-lib

                $('.modal-create-post-card #from_post').val(true); //prevent redirect by save modal-save-to-lib
                $(".modal-chose-post-card-type").modal("show");
            },
        },
    }
</script>

<style lang="scss" scoped>
    .create-card-btn-component {
        button {
            min-width: 13rem;
        }
    }
</style>