import axios from 'axios'
import { getToken } from './token'
import qs from 'qs';
import { Logout } from '@api/api.auth'

const TokenType = 'Bearer'
const timer = 50 * 1000
const baseURL = process.env.API_V2_ENDPOINT

// axios
const service = axios.create({
    baseURL, // api base_url
    timeout: timer, // time out for request
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Control-Allow-Origin': '*',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
})

// Format nested params correctly
service.interceptors.request.use(config => {
    config.paramsSerializer = params => {
        return qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false
        });
    };
    return config;
});

// request
service.interceptors.request.use(
    config => {
        if (getToken() && !config.url.includes('amazon')) {
            config.headers['Authorization'] = `${TokenType} ${getToken()}`
        }
        return config
    },
    error => {
        return error
    }
)

// response
service.interceptors.response.use(
    response => {
        if (response.config.url !== '/users/me' && response.data.status === 401) {
            Logout()
        }
        return response
    },
    error => {
        if (process.env.NODE_ENV === 'development') {
            console.log(`Response error: `, error.response)
        }
        throw error.response
    }
)

export default service