import axios from "axios"
import {getField, updateField} from 'vuex-map-fields'
import { awsConfigs } from "../../config"

const newVideo = function (payload) {
    const { kind } = payload
    const data = {
        uid: '_' + Math.random().toString(36).substr(2, 9),
        class_name: 'Media::Video',
        file: '',
        url: '',
        api_request_processing: false,
        uploadPercentage: 0,
        processingErrors: {}, // {error_field_or_function1: ['Error message 1', 'Error mesage 2'], ...}
        cancelUploading: null,
        api_state: {
            id: '',
            kind,
            // * read-only on API *
            // INITIATED - first initialization on the page
            // PARSING - file is selected - props is currently parsing on UI
            // CREATED - after POST /video - video record is created on API
            // UPLOADING - uploading video on S3
            // UPLOADED - after PUT /video/:id - video is uploaded and API is updated with origin_s3_path
            // SUBMITTED - transcode job is submitted and waiting in queue
            // PROGRESSING - transcoding in progress
            // ERROR - transcode job failed
            // CANCELED - transcode job is canceled
            // COMPLETE - video is handled and ready to display
            status: 'INITIATED',
            job_percent_complete: 0,
            origin_name: '', 
            /* ALLOWED FORMATS
            +-------------+--------------------------------+
            |	Extension |	           MIME Type           |
            +-------------+--------------------------------+
            |     .mp4    |            video/mp4           |
            +-------------+--------------------------------+
            |    .webm    |           video/webm           |
            +-------------+--------------------------------+
            |     .mov	  |         video/quicktime        |
            +-------------+--------------------------------+
            |     .avi    |   application/x-troff-msvideo  |
            |             |           video/avi            |
            |             |          video/msvideo         |
            |             |        video/x-msvideo         |
            +-------------+--------------------------------+
            |     .mkv    |         video/x-matroska       |
            +-------------+--------------------------------+
            |     .flv    |           video/x-flv          | 
            +-------------+--------------------------------*/
            origin_type: '',
            origin_size: '',
            origin_height: '',
            origin_width: '',
            origin_duration: '',
            origin_s3_path: '',
            hls_sources: [],
            dash_sources: [],
            for_product: false,
            reserved: false,
            can_edit_video: false,
            digital_access: 'protected',
            duration: 0
        },
        ui_state: {
            processCancelled: false,
        }
    };
    if (kind === 'main') {
        data.validForVideoTag = false;
        data.selectTimelineMode = false;
        data.api_state = { ...data.api_state,
            preview_type: null,
            preview_id: null,
            poster_index: '0',
            posters_list: {own: {}, 0: {}, 1: {}}
        }
        data.ui_state = { ...data.ui_state,
            preview_type: null,
            preview_id: 'none',
            poster_index: null,
            pesters_list: null,
            stay_on_form: false,
            leave_without_done_uploading: false,
            leave_without_confirm: false,
            warning_message: '',
        }
    }
    if (kind === 'generated') {
        data.await_main_video = false;
        data.api_state = { ...data.api_state, full_video_id: null, start_time: 0, duration: 60 }
    }
    if (kind === 'trailer'){
        data.api_state = { ...data.api_state, full_video_id: null }
    }
    return data
};

const state = {
    uploads: {
        main: Object.assign({}, newVideo({kind: 'main'})),
        trailer: Object.assign({}, newVideo({kind: 'trailer'})),
        generated: Object.assign({}, newVideo({kind: 'generated'}))
    },
    collection: []
}

const actions = {
    syncStatus({commit, state, dispatch}, payload) {
        let {kind} = payload,
            video = state.uploads[kind]
        if (video.ui_state.processCancelled && video.api_state.status === 'INITIATED' ) {
            console.log('Videos/syncStatus skip - processing video was cancelled')
        } else {
            dispatch('fetch', {kind, id: video.api_state.id}).then((response) => {
                let {status} = video.api_state
                if (!['COMPLETE', 'ERROR', 'CANCELED'].includes(status)) {
                    setTimeout(() => { dispatch('syncStatus', payload) }, 5000)
                }
            })
        }

    },
    fetch({commit}, payload) {
        return new Promise((resolve, reject) => {
            let {kind, id} = payload,
                video = state.uploads[kind]
            axios.get(`/media/videos/${id}`).then((response) => {
                if (kind === 'main') {
                    commit('updateField', {path: `uploads.main.ui_state.warning_message`, value: ''})
                }
                if (!video.ui_state.processCancelled) {
                    commit('FETCH', {kind, props: response.data.response})
                }
            }).catch((error) => {
                console.log(`videos.${kind}.fetch.error`, error)
                if (kind === 'main') {
                    commit('updateField', {path: `uploads.main.ui_state.warning_message`, value: 'Something went wrong, try to save the Card and come back later'})
                }
            }).then((res) => {
                resolve()
            })
        })
    },
    parseVideoFile({commit}, payload) {
        return new Promise((resolve, reject) => {
            let {kind, file, video_for_product} = payload;
            if (file.size > (10 * 1024 * 1024 *1024)) { //With a single PUT operation, you can upload objects up to 5 GB in size. https://docs.aws.amazon.com/AmazonS3/latest/dev/UploadingObjects.html
                const err_msg = 'file size must not exceed 10Gb';
                commit('SET_PROCESSING_ERRORS', {kind: kind, errors: { 'file_error': [ err_msg ] }});
                reject(err_msg);
            } else if (file.size > (3 * 1024 * 1024 *1024) && !video_for_product) { //Card Maximum file size 500MB <3Gb>
                const err_msg = 'file size must not exceed 3Gb';
                commit('SET_PROCESSING_ERRORS', {kind: kind, errors: { 'file_error': [ err_msg ] }});
                reject(err_msg);
            } else {
                commit('updateField', {path: `uploads.${kind}.api_state.status`, value: 'PARSING'})
                commit('SET_KNOWN_VIDEO_PROPS', payload)
                // try to get additional props
                const video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    if (video.duration < 10) {
                        const err_msg = `Video duration ${video.duration}s, but the minimum duration should not be less than 10s`;
                        commit('SET_PROCESSING_ERRORS', {kind: kind, errors: { 'file_error': [ err_msg ] }});
                        reject(err_msg);
                    } else {
                        commit('SET_ADDITIONAL_VIDEO_PROPS', {kind, video})
                        resolve()
                    }
                };
                video.onerror = function () {
                    console.log("Error " + video.error.code + "; details: " + video.error.message)
                    resolve()
                };
                video.src = state.uploads[kind].url
            }
        })
    },
    createVideo({commit, state}, payload) {
        let {kind} = payload,
            video = state.uploads[kind];
        if (!video.api_request_processing) {
            commit('updateField', {path: `uploads.${kind}.api_request_processing`, value: true})
            return new Promise((resolve, reject) => {
                const params = { ...video.api_state };
                if (kind !== 'main') {
                    const full_video = state.uploads.main.api_state;
                    params.full_video_id = full_video.id
                }
                axios.post('/media/videos', params)
                    .then((response) => {
                        commit('FETCH', {kind: kind, props: response.data.response})
                        commit('updateField', {path: `uploads.${kind}.ui_state.processCancelled`, value: false})
                        commit('updateField', {path: `uploads.${kind}.ui_state.leave_without_done_uploading`, value: false})
                        resolve()
                    }).catch((error) => {
                        if (error.response.data.status === 422) {
                            commit('SET_PROCESSING_ERRORS', {kind: kind, errors: error.response.data.message})
                        } else {
                            let errors = { 'api_error': ["post request is failed"] }
                            commit('SET_PROCESSING_ERRORS', {kind: kind, errors: errors})
                        }
                        reject("Failed to create video");
                    }).then(() => {
                        commit('updateField', {path: `uploads.${kind}.api_request_processing`, value: false})
                    })
            })
        } else {
            console.error('createVideo is already processing')
        }
    },
    deleteVideo({commit, state}, payload) {
        let {kind} = payload,
            video = state.uploads[kind];
        if (video.cancelUploading) {
            video.cancelUploading()
        }
        const { id } = video.api_state;
        commit('CLEAR', {kind: kind})
        return new Promise((resolve, reject) => {
            if ( !id ) {
                resolve("Video id is not detected");
            } else {
                axios.delete(`/media/videos/${id}`)
                    .then((response) => {
                        resolve()
                    })
                    .catch((error) => {
                        reject("Failed to delete video");
                    })
            }
        })
    },
    updateVideo({commit, getters, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            let {kind, ui_state} = payload,
                video = state.uploads[kind]
            if (!video.api_request_processing) {
                commit('updateField', {path: `uploads.${kind}.api_request_processing`, value: true})
                let params = {...video.api_state}
                if (ui_state){
                    params = {...params, ...getters['ui_difference'](kind)}
                }
                axios.put(`/media/videos/${video.api_state.id}`, params)
                    .then((response) => {
                        commit('FETCH', {kind: kind, props: response.data.response})
                        resolve(response)
                    }).catch((error) => {
                        if (error.response.data.status === 422) {
                            commit('SET_PROCESSING_ERRORS', {kind: kind, errors: error.response.data.message})
                            dispatch('fetch', {kind, id: video.api_state.id}).then((response) => {
                                if (!state.uploads[kind].api_state.origin_s3_path) {
                                    commit('PREPARE_TO_EDIT', {kind});
                                    commit('SET_PROCESSING_ERRORS', {kind: kind, errors: error.response.data.message})
                                }
                            })
                        } else {
                            let errors = { 'api_error': ["put request is failed"] }
                            commit('SET_PROCESSING_ERRORS', {kind: kind, errors: errors})
                        }
                        reject(error)
                    }).then(() => {
                        commit('updateField', { path: `uploads.${kind}.api_request_processing`, })
                    })
            } else {
                reject('updateVideo is already processing')
            }
        })
    },
    putS3({commit, state}, payload) {
        let {kind} = payload
        commit('updateField', {path: `uploads.${kind}.api_state.status`, value: 'UPLOADING'})
        const CancelToken = axios.CancelToken;
        let video = state.uploads[kind],
            signedUrl = video.api_state.upload_links.put_url,
            publicUrl = video.api_state.upload_links.public_url,
            file = video.file;

        return new Promise((resolve, reject) => {
            axios.put(signedUrl, file,{
                cancelToken: new CancelToken(function executor(c) {
                    commit('updateField', {path: `uploads.${kind}.cancelUploading`, value: c})
                }),
                headers: {'Content-Type': file.type},
                onUploadProgress: function (progressEvent) {
                    commit('updateField', {
                        path: `uploads.${kind}.uploadPercentage`,
                        value: parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                    })
                }
            }).then((response) => {
                commit('updateField', {
                    path: `uploads.${kind}.api_state.origin_s3_path`,
                    value: publicUrl
                })
                resolve()
            }).catch((error) => {
                if (axios.isCancel(error)) { //cancelUploading?
                    console.log('Request canceled', error);
                    commit('CLEAR', {kind: kind})
                } else {
                    let errors = { 'Upload to storage error': ["Please try again later"] }
                    commit('SET_PROCESSING_ERRORS', {kind: kind, errors: errors})
                }
            }).then(() => {
                commit('updateField', {path: `uploads.${kind}.cancelUploading`, value: null})
            })
        })
    },
    sdkS3Upload({commit, state}, payload){
        let {kind} = payload
        commit('updateField', {path: `uploads.${kind}.api_state.status`, value: 'UPLOADING'})
        
        let signedUrl = state.uploads[kind].api_state.upload_links.put_url,
            publicUrl = state.uploads[kind].api_state.upload_links.public_url,
            file = state.uploads[kind].file,
            s3Key = publicUrl.split(/s3.*\.amazonaws\.com\//)[1]
        // Upload the File
        var bucket = new AWS.S3({
            params: {
                Bucket: awsConfigs.S3VideoBucket
            }
        })

        var params = {Key: s3Key, ContentType: file.type, Body: file}

        return new Promise((resolve, reject) => {
            bucket.upload(params) //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
                .on('httpUploadProgress', function(evt) {
                    commit('updateField', {
                        path: `uploads.${kind}.uploadPercentage`,
                        value: parseInt(Math.round((evt.loaded / evt.total) * 100))
                    })
                })
                .send(function (err, data) {
                    if(err){
                        console.log('viseo.sdkS3Upload err', err)
                        let errors = { 'Upload to storage error': ["Please try again later"] }
                        commit('SET_PROCESSING_ERRORS', {kind: kind, errors: errors})
                    }else{
                        commit('updateField', {
                            path: `uploads.${kind}.api_state.origin_s3_path`,
                            value: data.Location
                        })
                        resolve()
                    }
                })
        })
    }
}

const clear = (state, payload) => {
    let {kind, errors} = payload
    if (state.uploads[kind].cancelUploading) {
        state.uploads[kind].cancelUploading()
    }
    state.uploads[kind].ui_state.processCancelled = true;
    state.uploads[kind].processingErrors = {};
    state.uploads[kind].uploadPercentage = 0;
    state.uploads[kind].api_state = newVideo({kind}).api_state;
    state.uploads[kind].file = '';
    state.uploads[kind].url = ''
    // state.uploads[kind] = Object.assign({}, newVideo({kind}))
    if (kind === 'main') {
        state.uploads.main.validForVideoTag = false
    }
}

const mutations = {
    updateField,
    FETCH(state, payload) {
        let {kind, props} = payload
        state.uploads[kind].api_state = props
    },
    SET_KNOWN_VIDEO_PROPS(state, payload) {
        let {kind, file} = payload
        state.uploads[kind].file = file
        state.uploads[kind].url = URL.createObjectURL(file)
        state.uploads[kind].api_state.origin_name = file.name
        state.uploads[kind].api_state.origin_type = file.type
        state.uploads[kind].api_state.origin_size = file.size
    },
    SET_ADDITIONAL_VIDEO_PROPS(state, payload) {
        let {kind, video} = payload
        state.uploads[kind].api_state = {
            ...state.uploads[kind].api_state, ...{
                origin_duration: video.duration,
                origin_width: video.videoWidth,
                origin_height: video.videoHeight,
            }
        };
        if (kind === 'main') {
            state.uploads.main.validForVideoTag = true
        }
    },
    SET_PROCESSING_ERRORS(state, payload) {
        let {kind, errors} = payload
        state.uploads[kind].processingErrors = errors
    },
    CLEAR(state, payload) {
        clear(state, payload)
    },
    PREPARE_TO_EDIT(state, payload) {
        let {kind} = payload
        const api_state = {
            ...state.uploads[kind].api_state,
            status: 'CREATED',
            reserved: true,
            job_percent_complete: 0,
            origin_name: '',
            origin_type: '',
            origin_size: '',
            origin_height: '',
            origin_width: '',
            origin_duration: '',
            origin_s3_path: '',
            duration: 0,
        };
        clear(state, {kind: 'generated'});
        if (state.uploads[kind].api_state.preview_type === 'generated') { api_state.preview_type = null; }
        if (state.uploads[kind].ui_state.preview_type  === 'generated') { state.uploads[kind].ui_state.preview_type = null; }
        state.uploads[kind].api_state = api_state;
        state.uploads[kind].processingErrors = {}
    },

    RESET_PROCESSING_ERRORS(state, payload) {
        let {kind} = payload
        state.uploads[kind].processingErrors = {}
    },
    SET_OWN_POSTER(state, payload) {
        let {kind, paths} = payload
        state.uploads[kind].api_state.posters_list.own = paths
        state.uploads[kind].api_state.poster_index = 'own'
    },
    REMOVE_OWN_POSTER(state, payload){
        let {kind} = payload
        state.uploads[kind].api_state.posters_list.own = {}
        state.uploads[kind].api_state.poster_index = '0'
    }
}

const getters = {
    getField,
    getById: state => uid => {
        return state.collection.find(item => item.id === id)
    },
    preview_type: state => kind => {
        return state.uploads[kind].ui_state.preview_type || state.uploads[kind].api_state.preview_type
    },
    preview_id: state => kind => {
        let {preview_id} = state.uploads[kind].ui_state;
        return preview_id !== 'none' && preview_id || state.uploads[kind].api_state.preview_id
    },
    poster_index: state => kind => {
        return state.uploads[kind].ui_state.poster_index || state.uploads[kind].api_state.poster_index
    },
    ui_difference: (state, getters) => kind => {
        return {
            preview_type: getters['preview_type'](kind),
            preview_id: getters['preview_id'](kind),
            poster_index: getters['poster_index'](kind)
        }
    }
}

export default {
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
    getters: getters
}