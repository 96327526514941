<template>
<div class="post-form-index-component">
    <div v-show="isMainView">
        <a class="modal__close">
            <i class="icon-close" @click="$emit('close')" />
        </a>

        <PostForm ref="PostForm"
                  :post_init="post_init"
                  :show-add-link-view="showAddLinkView"
                  :show-search-card-view="showSearchCardView" />
    </div>
    <div v-show="isAddLinksView">
        <AddLinks ref="AddLinks" @changed="postUpdate({url_hash: $event})" @close="view = 'main'" />
    </div>
    <div v-show="isSearchCardView">
        <SearchCardCollection @close="view = 'main'" />
    </div>
</div>
</template>

<script>
import PostForm from "./PostForm";
import AddLinks from "./AddLinks";
import SearchCardCollection from "./SearchCardCollection";
    export default {
        components: {PostForm, SearchCardCollection, AddLinks},
        props: {
            post_init: {type: Object, default: () => null},
        },
        data() {
            return {
                view: 'main', // 'search_card' | 'add_links'
            }
        },
        computed: {
            isMainView()        { return this.view === 'main' },
            isAddLinksView()    { return this.view === 'add_links' },
            isSearchCardView()  { return this.view === 'search_card' },
        },
        beforeMount() {
            window.addEventListener('card_created', this.showMainView)
        },
        methods: {
            showMainView() {
                this.view = 'main'
            },
            showAddLinkView(url_hash) {
                this.$refs.AddLinks.setLinks(url_hash)
                this.view = 'add_links'
            },
            showSearchCardView() {
                this.view = 'search_card'
            },
            postUpdate(payload) {
                this.$refs.PostForm.postUpdate(payload)
            }
        }
    }
</script>

<style lang="scss"> //unscoped

</style>

<style lang="scss" scoped>

</style>