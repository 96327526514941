<template>
<div v-tooltip.right="tooltipOptions" class="likers-count-component post-likers" @click.prevent.stop="openLikersModal">
    {{ likesCount }}
</div>
</template>

<script>
    import { getLikers } from "../../../../api/api.posts";
    import LikersList from "./LikersList";
    import {mapGetters} from "vuex";

    export default {
        name: "LikedPostCount",
        props: {
            post:   {type: Object, default: () => {}},
        },
        data() {
            return {
                likers: [],
                loading: false
            }
        },
        computed: {
            ...mapGetters('auth', {
                currentUser: 'getCurrentUser'
            }),
            likesCount() {
                return this.post.likes && this.post.likes >= 0 ? this.post.likes : 0
            },
            isDisabled() {
                return this.likesCount < 1
            },
            postId() {
                return this.post.id
            },
            tooltipContent() {
                return this.isDisabled ? null : this.getWhoLiked
            },
            tooltipOptions() {
                return {
                    content: this.tooltipContent,
                    loadingContent: '<div class="spinner-border" />',
                    classes: ['likerList'],
                    offset: 5,
                }
            },
            popupTemplate() {
                if (this.likers.length) {
                    const list = this.likers.map(u => u.full_name)
                    if (list.length < this.likesCount) {
                        list.push(`and ${this.likesCount - list.length} more...`)
                    }
                    return list.map(i => `<div class="item">${ i }</div>`).join('')
                } else {
                    return `<div>there is not anyone.</div>`
                }
            }
        },
        methods: {
            async getWhoLiked() {
                this.loading = true
                this.likers = (await getLikers(this.postId)).data.response
                this.loading = false
                return this.popupTemplate
                // return createElement(TooltipList, { props: { likers: this.likers } }) //how todo it?
                //https://github.com/Akryum/v-tooltip#component
            },

            openLikersModal() {
                if (!this.currentUser) {
                    this.$modal.show('please-login');
                } else if (!this.isDisabled) {
                    const componentSelf = this;
                    const likersModal = {
                        render(h) {
                            const modalSelf = this;
                            return h(LikersList, {
                                props: {
                                    postId:         componentSelf.post.id,
                                    likesCount:     componentSelf.likesCount,
                                    parentLikers:   componentSelf.likers,
                                    parentLoading:  componentSelf.loading,
                                },
                                on: {
                                    close() {
                                        modalSelf.$emit("close");
                                    }
                                }
                            });
                        }
                    }
                    this.$modal.show(likersModal, {},{ name: "LikersModal", width: 0, });
                }
            }
        }
    }
</script>