<template>
<a class="link-info-short-component" :href="link.url | validateURL">
    <!--    <div class="item-cover" :style="`background-image: url(${ imgURL })`" />-->
    <CoverImg v-if="imgURL" :src="imgURL" />
    <div class="textContent" :class="{notitle: !link.title}">
        <div>{{ source }}</div>
        <div class="title">{{ link.title }}</div>
    </div>
    <button v-if="with_delete" class="btn__reset" @click.stop.prevent="$emit('delete')">
        <i class="icon-close_bold color__secondary_60" />
    </button>
</a>
</template>

<script>
    import helper from "../../utils/helper";
    import CoverImg from "@kit/CoverImg";

    export default {
        name: 'LinkInfoShort',
        components: { CoverImg },
        filters: {
            humanizeLink (value) {
                return helper.extractHostname(value)
            },
            validateURL (link) {
                return (/https?:\/\/.*/.test(link) ? link : `http://${link}`)
            }
        },
        props: {
            link:         { type: Object, default: () => {} },
            with_delete:  { type: Boolean, default: false },
        },
        computed: {
            imgURL() {
                return this.link.image && this.$options.filters.validateURL( this.link.image );
            },
            source() {
                const { provider_name, provider_url, url } = this.link
                return this.$options.filters.humanizeLink( provider_name || provider_url || url );
            },
        },
        methods: {

        }
    }
</script>

<style lang="scss">
.link-info-short-component {
    .CoverImg {
        max-width: 50px;
        margin-right: 15px;
    }
}
</style>

<style lang="scss" scoped>
.link-info-short-component {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: #FAF9FC;
    margin: 10px 0;
    color: inherit;
    padding: 10px;
    .textContent {
        line-height: 18px;
        font-size: 12px;
        flex: auto;
        &.notitle, .title {
            line-height: 21px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}
</style>