<template>
    <div class="posters-nav-component" :class="className">
        <h6>
            Subtitles &nbsp;
            <i class="fa fa-plus small" @click="add_video_subs()"></i>
        </h6>

        <div v-for="subtitle in subtitles">
            <upload-subtitle :subtitle="subtitle"></upload-subtitle>
        </div>
    </div>
</template>

<script>
    import UploadSubtitle from "./UploadSubtitle"

    export default {
        props: ['kind', 'className'],
        components: {UploadSubtitle},
        data: function () {
            return {
                uid: 'video_sub'
            }
        },
        computed: {
            subtitles: function () {
                return this.$store.state.Subtitles.list
            },
            holder: function(){
                return this.$store.state.Videos.uploads.main
            },
            holder_type() {
                return this.holder.class_name
            },
            holder_id() {
                return (this.holder.api_state || {}).id
            },
        },
        methods: {
            add_video_subs: function (data) {
                let {holder_type, holder_id} = this;
                this.$store.commit('Subtitles/ADD', {holder_type, holder_id})
            },
        },
        created() {
            const { holder_type, holder_id } = this;
            if (holder_id) {
                this.$store.dispatch('Subtitles/fetch_all', {holder_type, holder_id})
            }
        }
    }
</script>

<style lang="scss" scoped>

    .posters-nav-component {
        .poster-img-container {
            border: 1px #d1d3d4 dashed;
            height: 60px;
            position: relative;
            background-color: #f8f8f9;
            display: flex;
            align-items: center;
            &.complete{
                background-color: black;
            }
            &:hover .poster-remove {
                opacity: 1;
            }
        }
        .poster-img{
            cursor: pointer;
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
        }
        .fa-spinner{
            margin: 0 auto;
        }
        .poster-index-input {
            display: none;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            vertical-align: middle;
            margin: 0 auto;
        }

        .poster-mark {
            color: white;
            position: absolute;
            top: 0;
            right: 0;
            background-color: black;
            padding-left: 2px;
            margin: 0;
        }

        .poster-remove {
            position: absolute;
            top: 0;
            left: 0;
            color: white;
            background: none;
            font-size: 1.5em;
            padding: 5px;
            cursor: pointer;
            opacity: 0;
            transition: opacity linear .3s;
            text-shadow: 1px 1px 5px black;
        }
        .styled-checkbox[disabled] + label {
            opacity: 0.5;
            cursor: not-allowed;
        }
        &.cardForm {
            .row {
                margin-left: -10px;
                margin-right: -10px;
            }
            .poster-img-container, .upload-image-component {
                height: 75px
            }
        }
        .temp-img-preview {
            opacity: 0.5;
        }
    }
</style>