<template>
<modal
    name="leave-group"
    :width="0"
    @before-open="beforeOpen">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <div class="text__center margin-b__20">
                <b>Leave Group?</b>
            </div>
            <div class="text__center">
                Are you sure you want to leave this group?
            </div>
            <div class="text__center">
                <button
                    class="btn__reset margin-r__10"
                    @click="hide">
                    Oops! I'll stay.
                </button>
                <button
                    class="btn btn__m btn__round">
                    {{ $t('BUTTON.YES') }}
                </button>
            </div>
        </section>
    </div>
</modal>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'KeaveGroupModal',
        data() {
            return {
                group: null
            }
        },
        methods: {
            // @vuese
            // Method to hide DeleteReply modal
            hide() {
                this.$modal.hide('leave-group')
            },
            handleDeleteReply() {
                this.deleteReply(this.reply)
                this.hide()
            },
            // @vuese
            // Method that call before modal open and set to local data reply
            // @arg The argument is modal-open event with param reply [object]
            beforeOpen(event) {
                if (event && event.params && event.params.group) {
                    this.group = event.params.group
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal__sphir_Online_Access_Agreement {
        section {
            width: 34rem;
        }
    }
</style>