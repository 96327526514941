import request from '@utils/request'

export function getCard (_params) {
    let { id, ...params } = typeof(_params) === 'object' ? _params : {}
    return request({
        url: `/cards/${id || _params}`,
        method: 'get',
        params //{post_on_sphir}
    })
}
