<template>
<div class="modal show modal__likers-list">
    <section>
        <a class="modal__close">
            <i class="icon-close" @click="$emit('close')" />
        </a>
        <!--#############################################################-->
        <!--likers-list-component-->

        <div class="likers-list-component">
            <vuescroll :ops="scrollOpt" @handle-scroll="handleScroll">
                <div class="wrapper">
                    <LikerItem v-for="liker in likers"
                               :key="liker.id"
                               :liker="liker"
                               @click="$emit('close')" />
                    <div v-if="loading" class="spinner-border" />
                </div>
            </vuescroll>
        </div>

        <!--#############################################################-->
    </section>
</div>
</template>

<script>
import { getLikers } from "../../../../api/api.posts";
import LikerItem from "./LikerItem";
import vuescroll from 'vuescroll';

export default {
    name: "LikersList",
    components: { LikerItem, vuescroll },
    props: {
        postId:         {type: String, default: ''},
        likesCount:     {type: Number, default: 0},
        parentLikers:   {type: Array, default: () => []},
        parentLoading:  {type: Boolean, default: false}
    },
    data() {
        return {
            loading: this.likersLoading,
            likers: this.parentLikers,
            scrollOpt: {
                scrollPanel: {
                    maxHeight: 500
                },
            }
        }
    },
    computed: {
        allLoaded() {
            return this.likers.length >= this.likesCount
        }
    },
    created() {
        if (!this.loading && !this.allLoaded && !this.likers?.length) {
            this.getMoreLikers()
        }
    },
    methods: {
        handleScroll(vertical, horizontal, nativeEvent) {
            if (vertical.process > 0.98 && !this.loading && !this.allLoaded) {
                this.getMoreLikers()
            }
        },
        async getMoreLikers () {
            try {
                this.loading = true
                const response = (await getLikers({post_id: this.postId, offset: this.likers.length })).data.response
                this.likers = [ ...(this.likers.length ? this.likers : this.parentLikers), ...response ]
            } catch (e) {
                console.error(e);
                iziToast.error({ message: e.message });
            }
            setTimeout(() => this.loading = false )
        },
    }
}
</script>

<style lang="scss" scoped>
.likers-list-component {
    .spinner-border {
        opacity: 0.4;
        padding: 20px;
    }
}
</style>