<template>
    <div class="upload-video-component"
         :class="{'block-file-selection': !isAllowToSelectFile, dragover: dragover}"
         @click="onClick"
         @drop.prevent="onDrop"
         @dragover.prevent="dragover = true"
         @dragleave.prevent="dragover = false"
         v-cloak>
        <input ref="fileInput"
               @change="selectionProcessing($event)" 
               type="file"
               accept=".mp4, .webm, .mov, .avi, .mkv, .flv">

        <div v-if="Object.keys(processingErrors).length > 0 || status === 'INITIATED' || isReserved">
            <div class="upload-video-title">
                Drop file here or click to <b>upload</b>.
            </div>

            <div v-if="Object.keys(processingErrors).length > 0" v-for="(messages, field) in processingErrors" class="errors">
                <p>{{field | capitalize | unSnakeCase }}</p>
                <div v-if="field === 'space_limited'" class="space_limited_msg">
                    The file you are trying to upload exceeds the allotted storage.
                    Please clean your <a href="/my_storage"  target="_blank" @click.stop="">Storage</a> first before attempting to re-upload
                    it<span v-if="!is_plus_user">, or upgrade to <a href="/subscriptions" target="_blank" @click.stop="">Plus account</a>.</span>
                </div>
                <ul v-else>
                    <li v-for="message in messages">
                        {{message | capitalize}}
                    </li>
                </ul>
            </div>

        </div>
        <div v-else-if="warning_message" style="text-align: center">{{warning_message}}</div>
        <div v-else-if="status==='PARSING'">Checking...</div>
        <div v-else-if="status==='UPLOADING'">Uploading: {{uploadPercentage}}%</div>
        <div v-else-if="inQueue">In queue...</div>
        <div v-else-if="isProgressing">Processing: {{job_percent_complete}}%</div>
        <div v-else-if="['ERROR'].includes(status)">Processing error: {{id}}</div>
        <div v-else-if="['CANCELED'].includes(status)">Processing is cancelled: {{id}}</div>
        <div v-else-if="['COMPLETE'].includes(status)">Complete</div>
    </div>
</template>

<script>
    import {mapDynamicFields} from "./helpers/mapDynamicFields"
    import ModalPreview from "./ModalPreview";

    export default {
        props: ['kind', 'for_product'],
        components: { ModalPreview },
        data: function () {
            return {
                spath: `Videos.uploads.${this.kind}`,
                pSpath: `Videos.uploads`,
                dragover: false,
                statusSyncing: false
            }
        },
        watch: {
            'status': function(val, old_val){
                if (val === 'INITIATED'){ //processCancelled
                    this.$refs.fileInput.value = ''
                } else if (['SUBMITTED', 'PROGRESSING'].includes(val) && !this.statusSyncing){
                    this.statusSyncing = true;
                    this.$store.dispatch('Videos/syncStatus', { kind: this.kind });
                } else if(['ERROR', 'CANCELED', 'COMPLETE'].includes(val)){
                    this.statusSyncing = false
                }
            },
            'uploadPercentage': function(val, ov){
                if (ov === 0 && val > 0 && this.kind === 'main' && !this.generate_await_main_video){
                    this.$modal.show('preview-modal')
                }
            },
        },
        computed: {
            ...mapDynamicFields('spath', {
                origin_name: 'api_state.origin_name',
                id: 'api_state.id',
                status: 'api_state.status',
                uploadPercentage: 'uploadPercentage',
                job_percent_complete: 'api_state.job_percent_complete',
                video_for_product: 'api_state.for_product',
                digital_access: 'api_state.digital_access',
                processingErrors: 'processingErrors',
                reserved: 'api_state.reserved',
                file: 'file',
                warning_message: 'ui_state.warning_message',
            }),
            ...mapDynamicFields('pSpath', {
                generate_await_main_video: 'generated.await_main_video',
                main_video_id: 'main.api_state.id',
            }),
            isReserved: function () {
                return this.status === 'CREATED' && (this.reserved || !this.file)
            },
            hasErrors: function () {
                return Object.keys(this.processingErrors).length > 0
            },
            isAllowToSelectFile(){
                let isInitiated = this.status === 'INITIATED'
                    // isErrorBeforeCreate = this.status === 'PARSING' && this.hasErrors
                return isInitiated || this.isReserved || this.hasErrors
            },
            isProgressing(){
                return this.status === 'PROGRESSING' && this.job_percent_complete > 0
            },
            inQueue(){
                let in_queue = this.status === 'SUBMITTED',
                    is_zero_processing = this.status === 'PROGRESSING' && this.job_percent_complete === 0
                return in_queue || is_zero_processing
            },
            is_plus_user(){
              const user = JSON.parse(localStorage.getItem('user'));
              return user && user.is_plus_account
            }
        },
        methods: {
            onClick() {
                if (!this.isAllowToSelectFile) return;
                this.$refs.fileInput.click()
            },
            onDrop(e) {
                this.dragover = false;
                if (!this.isAllowToSelectFile) return;
                let droppedFiles = e.dataTransfer.files;
                if (!droppedFiles) return;
                if (droppedFiles.length > 1) {
                    this.$store.commit('Videos/SET_PROCESSING_ERRORS', {
                        kind: this.kind,
                        errors: { too_many_files: ['Select please only one file']}
                    })
                } else {
                    this.$refs.fileInput.files = droppedFiles
                    this.$refs.fileInput.dispatchEvent(new Event("change"))
                }
            },
            async selectionProcessing(event) {
                const { isReserved } = this
                let file = event.target.files[0];
                if (!file) { return }
                this.video_for_product = !!this.for_product;
                const { kind, video_for_product } = this;
                this.$store.commit('Videos/RESET_PROCESSING_ERRORS', { kind });
                // start processing file
                await this.$store.dispatch('Videos/parseVideoFile', { kind, file, video_for_product });
                if (this.id && isReserved) {
                    await this.$store.dispatch('Videos/updateVideo', { kind }); // api set reserved to false;
                } else {
                    if (this.kind === 'trailer' && !this.main_video_id) {
                        this.$store.commit('Videos/updateField', {path: 'uploads.main.api_state.reserved', value: true});
                        await this.$store.dispatch('Videos/createVideo', {kind: 'main'});
                    }
                    await this.$store.dispatch('Videos/createVideo', { kind });
                }
                await this.$store.dispatch('Videos/sdkS3Upload', { kind });
                await this.$store.dispatch('Videos/updateVideo', { kind });
                if (this.kind === 'main') { this.$root.$emit('main_video_uploaded'); }
            },
        },
        created(){
            // if (this.kind === 'main' && this.for_product){ this.digital_access = 'private' }
            if (this.kind === 'main') {
                this.$root.$on('upload_new_video', this.onClick);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upload-video-component {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        padding: 5px;
        height: 200px;
        width: 100%;
        border: 1px #d1d3d4 dashed;
        background-color: #f8f8f9;
        cursor: pointer;
        z-index: 1;

        &:hover {
            border-color: #8f969d;

            .upload-video-title {
                color: #212529;
            }
        }

        // dragover hover
        &.dragover {
            border-color: #8f969d;
            .upload-video-title {
                color: #212529;
            }
        }

        &.block-file-selection {
            cursor: default;
            &:hover {
                border-color: #d1d3d4;
            }
        }

        .upload-video-title {
            font-family: "montserrat-reg";
            font-size: 14px;
            color: #a7a9ac;
            text-align: center;
        }

        .errors {
            color: #5a5a5a;
            margin-top: 10px;

            p {
                margin: 0;
                color: #4b419a;
            }

            ul {
                margin: 0;
                padding-left: 25px;
            }
        }
        .space_limited_msg {
            padding: 5px 10px;
        }
    }

    input[type='file'] {
        display: none;
    }

</style>