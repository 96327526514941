import request from '@utils/request'

export function BecomePro (payload) {
    return request({
        url: '/users/pro',
        method: 'post',
        data: {
            payload
        }
    })
}