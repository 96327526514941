<template>
<modal
    name="join-form"
    :width="0">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i
                    class="icon-close"
                    @click="hide" />
            </a>
            <div class="modal__head">
                <h3>
                    {{ $t('JOIN.TITLE') }}
                </h3>
            </div>
            <div class="modal__body">
                <div class="join-agree-content">
                    <p>{{ $t('JOIN.P_1') }}</p>
                    <p>{{ $t('JOIN.P_2') }}</p>
                    <p>
                        {{ $t('JOIN.P_3') }}
                        <a
                            class="join-link"
                            href="/legal">{{ $t('LINK.LEGAL') }}</a> {{ $t('JOIN.P_4') }}
                        <a
                            class="join-link"
                            href="/terms_of_use">{{ $t('LINK.TERM-2') }}</a>,
                        <a
                            class="join-link"
                            href="/content_and_behavior_guidelines">{{ $t('LINK.CAB') }}</a>,
                        <a
                            class="join-link"
                            href="/privacy_policy">{{ $t('LINK.PRIVACY') }}</a>,
                        <a
                            class="join-link"
                            href="/card_guidelines">{{ $t('LINK.GUID') }}</a>,
                        <a
                            class="join-link"
                            href="/copyright_and_dmca_policy">{{ $t('LINK.COPY') }}</a>,
                        {{ $t('JOIN.P_5') }}
                    </p>
                    <p>
                        {{ $t('JOIN.P_6') }}
                        <a
                            class="join-link"
                            href="/legal">{{ $t('LINK.LEGAL') }}</a>,
                        {{ $t('JOIN.P_7') }}
                    </p>
                    <ul class="bullets__filled">
                        <li>{{ $t('JOIN.LI_1') }}</li>
                        <li>{{ $t('JOIN.LI_2') }}</li>
                        <li>{{ $t('JOIN.LI_3') }}</li>
                        <li>{{ $t('JOIN.LI_4') }}</li>
                        <li v-html="$t('JOIN.LI_5')" />
                        <li>{{ $t('JOIN.LI_6') }}</li>
                        <li>{{ $t('JOIN.LI_7') }}</li>
                    </ul>
                    <p class="text__bold margin-b__20">
                        {{ $t('JOIN.P_8') }}
                    </p>
                    <p>
                        <label class="choice checkbox">
                            <input
                                id=""
                                v-model="check1"
                                value=""
                                type="checkbox">
                            <span>{{ $t('JOIN.CHECK_1') }}</span>
                        </label>
                    </p>
                    <p>
                        <label class="choice checkbox">
                            <input
                                id=""
                                v-model="check2"
                                value=""
                                type="checkbox">
                            <span>{{ $t('JOIN.CHECK_2') }}</span>
                        </label>
                    </p>
                    <p class="margin-b__30">
                        <label class="choice checkbox">
                            <input
                                id=""
                                v-model="check3"
                                value=""
                                type="checkbox">
                            <span>{{ $t('JOIN.CHECK_3') }}</span>
                        </label>
                    </p>
                    <p class="margin-b__40">
                        {{ $t('JOIN.P_9') }}
                    </p>
                </div>
            </div>
            <div class="modal__footer text__center margin-t__40">
                <div
                    v-show="localError"
                    class="error">
                    {{ localError }}
                </div>
                <button
                    class="btn btn__round"
                    @click="agree">
                    {{ $t('BUTTON.AGREE') }}
                </button>
                <button
                    class="btn btn__bordered btn__round"
                    @click="disagree">
                    {{ $t('BUTTON.DISAGREE') }}
                </button>
            </div>
        </section>
    </div>
</modal>
</template>

<script>
    export default {
        name: 'JoinModal',
        data() {
            return {
                check1: false,
                check2: false,
                check3: false,
                localError: ''
            }
        },
        methods: {
            hide() {
                this.localError = ''
                this.$modal.hide('join-form')
                // fullpage_api.setAutoScrolling(true);
            },
            agree() {
                this.check1 && this.check2 && this.check3 ? window.location.href = '/users/sign_up' : this.localError = 'Please check all the policies check-boxes.'
            },
            disagree() {
                this.hide()
                this.$modal.show('disagree-form')
            }
        }
    }
</script>