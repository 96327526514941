<template>
<div>
    <transition
        name="fade"
        mode="out-in">
        <router-view />
    </transition>
    <Modals />
    <IncompleteViewingMsg />
</div>
</template>

<script>
    import Modals from '@components/modals'
    import IncompleteViewingMsg from '@components/IncompleteViewingMsg'
    import {mapActions} from 'vuex'

    export default {
        name: 'App',
        components: {
            Modals,
            IncompleteViewingMsg,
        },
        mounted() {
            this.check_current_user()
        },
        methods: {
            ...mapActions('auth', {
                check_current_user: 'LOAD_USER'
            })
        }
    }
</script>