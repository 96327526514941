import {
    BecomePro
} from '../../api/api.pro'

const getDefaultState = () => {
    return {
        
    }
}

const pro = {
    namespaced: true,
    state: getDefaultState(),
    getters: {},
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
    },
    actions: {
        async BECOME_PRO ({ commit }, payload) {
            const data = {
                id: payload
            }
            const response = await BecomePro(data)
            return response
        }
    }
}

export default pro