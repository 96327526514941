<template>
        <div class="dropzone-podcast-template" id="dropzone-podcast-template">
            <div @click="clearSource()" class="fa fa-trash" title="Remove this source file" v-if="uploadedPodcast.original_url"></div>
            <div class="m-b-10 small">
                <div>Supported formats: MP3,M4A,WMA</div>
                <div>Max file size = {{max_size}}MB.</div>
            </div>
            <div class="dropzone dropzone-area dz-podcast" ref="audioSourceSection" :id="elemId" title="Drop files here or click to upload."
                 v-bind:class="{ 'dz-disabled': uploadedPodcast.isDropped }"
                 v-show="!uploadedPodcast.original_url">
                <div class="dz-message">
                    <div class="btn-browse btn-sm c-dz-progress">{{ status || 'Upload' }}</div>
                    <div class="description-txt sub-info">Drop files here or click to upload.</div>
                    <div class="description-txt error show" v-show="hasError">
                        <div class="error-message">{{ errorMessage || 'Something went wrong' }}</div>
                        <i>Max size: {{ max_size }}MB</i>
<!--                        <br/>-->
<!--                        <i>Valid types: {{ valid_types }}</i>-->
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        props: ['uploadedPodcast', 'api', 'form_for', 'title', 'max_size', 'min_size', 'min_duration'],

        data() {
            return {
                // fields: ['name', 'type', 'size', 'duration', 'width', 'height', 'public_url'],
                // selector: `${this.$parent.elSelector} #${this.elemId}`, //
                status: '',
                hasError: false,
                errorMessage: '',
                elemId: 'dropzone-podcast-file'
            }
        },

        computed: {
            data_forms: function () {
                const {form_for} = this;
                return (form_for || 'uploaded_video').split(':')
            },
            Api: function () {
                return this.api
            },
        },

        watch: {
            'uploadedPodcast.id': function (nv, ov) { this.$emit('update:id', nv) },
            'uploadedPodcast.original_url': function (nv, ov) { this.$emit('update:original_url', nv) },
       },

        created() {
            const vm = this;
            this.uploadedPodcast.elemId = this.elemId;
            setTimeout(function () {
                vm.initPodcastDZ()
            })
        },

        methods: {
            initPodcastDZ() {
                const vm = this;
                const formId = vm.$refs.audioSourceSection.closest('form').id
                const selector = `#${formId} #${this.elemId}`;
                initPodcastDropzone({selector, vm}); //this.$el
            },
            checkSource(url) {
                return this.fileIsValid(url)
            },
            fileIsValid(objectURL) {
                const vm = this;
                return new Promise((resolve, reject) => {
                    // var objectUrl = URL.createObjectURL(file);
                    const audio = new Audio();
                    audio.oncanplay = function (e) {
                        if (audio.duration < (vm.min_duration || 10)) {
                            reject(`The audio is too short (< ${vm.min_duration || 10}s)`);
                        } else {
                            resolve({duration: audio.duration});
                        }
                    };
                    audio.onerror = function (e) {
                        reject('This file cannot be used')
                    };
                    audio.src = objectURL
                });
            },
            afterUploaded(file) {
                const vm = this;
                return new Promise(async (resolve, reject) => {
                    try {
                        if (file.public_url) {
                            vm.uploadedPodcast.original_url = file.public_url;
                        }
                        const {name, type, size, duration} = file;
                        const {id, source_type, original_url, poster_original_url, embed_url_info_id} = vm.uploadedPodcast;
                        const params = {
                            name,
                            type,
                            size,
                            duration,
                            source_type,
                            original_url,
                            poster_original_url,
                            embed_url_info_id
                        };
                        let audio;
                        if (id) {
                            audio = await vm.Api.audio.update(id, params)
                        } else {
                            audio = await vm.Api.audio.create(params)
                        }
                        vm.status = '';
                        vm.uploadedPodcast.id = audio.id;
                    } catch (e) {
                        vm.hasError = true;
                        vm.status = e.responseText;
                    }
                });
            },
            clearSource() {
                this.uploadedPodcast.original_url = ''
                this.uploadedPodcast.isDropped = false;
            },
        },
    }
</script>

<style scoped>

</style>