import request from '@utils/request'
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
// models
import Post from './models/Post'
import Comment from './models/Comment'
import Reply from './models/Reply'
import UserFile from './models/UserFile'
import Group from './models/Group'
import Profile from './models/Profile'
import Photo from './models/Photo'
import Album from './models/Album'
import Image from './models/Image'
// modules
import profile from './modules/profile'
import group from './modules/group'
import auth from './modules/auth'
import ui from './modules/ui'
import errors from './modules/errors'
import discover from './modules/discover'
import pro from './modules/pro'
import trending_feeds from './modules/trending_feeds'
import loader from './modules/loader'
import photos from './modules/photos'
import albums from './modules/albums'
import posts from './modules/posts'
import storage from './modules/storage'
import comments from './modules/comments'
import embed_url_info from './modules/embed_url_info'
import images from './modules/images'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)
VuexORM.use(VuexORMAxios, { axios: request })
// Create a new instance of Database.
const database = new VuexORM.Database()
// Register Models to Database.
database.register(Post, posts)
database.register(Group, group)
database.register(Profile, profile)
database.register(Comment, comments)
database.register(Reply)
database.register(UserFile, storage)
database.register(Photo, photos)
database.register(Album, albums)
database.register(Image, images)

const debug = true; //process.env.NODE_ENV !== 'production'
// define plugins
const plugins = [VuexORM.install(database)]
if (debug) {
    plugins.push(createLogger())
}

let store = new Vuex.Store({
    modules: {
        auth,
        ui,
        errors,
        discover,
        trending_feeds,
        loader,
        pro,
        embed_url_info
    },
    strict: debug,
    plugins: plugins
})
export default store
