<template>
<modal name="delete-group" :width="0" @before-open="beforeOpen">
    <div class="modal modal__sphir_Online_Access_Agreement show">
        <section v-click-outside="hide">
            <a class="modal__close">
                <i class="icon-close" @click="hide" />
            </a>
            <div class="title">
                Delete Group?
            </div>
            <p>
                Are you sure you want to delete this group?<br>
                This action can't be undone.
            </p>
            <a href="javascript:void(0)" class="btn btn__round btn__bordered" @click="hide">Cancel</a>
            <a href="javascript:void(0)"
               class="btn btn__round"
               style="color: white"
               @click="handleDeleteGroup">Delete Group</a>
        </section>
    </div>
</modal>
</template>

<script>
import {mapActions} from 'vuex'
    export default {
        name: 'DeleteGroupModal',
        data() {
            return {
                id: ''
            }
        },
        methods: {
          ...mapActions('entities/group', {
            deleteGroup: 'DELETE_GROUP'
          }),
          async handleDeleteGroup() {
            const response = await this.deleteGroup(this.id)
            if (response.data.status === 200) {
               this.$toast.success('The group was deleted successfully');
               setTimeout(() => {
                 window.location = '/groups'
               }, 1000)
            }
          },
            // @vuese
            // Method to hide BecomePro modal
            hide() {
                this.$modal.hide('delete-group')
            },
            // @vuese
            // Method that call before modal open and set to local data email
            // @arg The argument is modal-open event with param email [string]
            beforeOpen(event) {
                if (event && event.params && event.params.id) {
                    this.id = event.params.id
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal__sphir_Online_Access_Agreement {
        section {
            width: 50rem;
            text-align: center;
            .title {
              margin-top: 10px;
              margin-bottom: 20px;
              font-size: 14px;
              line-height: 22px;
              color: black;
              font-weight: bold;
            }
            p {
              margin-bottom: 30px;
            }
            a {
              margin: 0 10px;
            }
        }
    }
</style>