import request from '@utils/request'

export function getPosts(payload, link, group_id = '') {
    return request({
        url: `/${link}${link === 'groups' || 'users' ? `/${group_id}`: ''}/posts`,
        method: 'get',
        params: {
            ...payload
        }
    })
}

export function getPost(id) {
    return request({
        url: `/posts/${id}`,
        method: 'get'
    })
}

export function createPost(payload) {
    return request({
        url: '/posts',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function updatePost(payload) {
    return request({
        url: `/posts/${payload.id}`,
        method: 'put',
        data: {
            ...payload
        }
    })
}

export function deletePost(id) {
    return request({
        url: `/posts/${id}`,
        method: 'delete',
    })
}

export function searchAttachments(keyword) {
    return request({
        url: '/feed/comments/search_comment_attachment',
        method: 'get',
        params: {
            key_word: keyword
        }
    })
}

export function likePost(post_id) {
    return request({
        url: `/feed/posts/${post_id}/like`,
        method: 'post'
    })
}

export function getLikers(_params) {
    let { id, post_id, ...params } = typeof(_params) === 'object' ? _params : {}
    post_id = post_id || id || _params
    return request({
        url: `/posts/${ post_id }/likers`,
        method: 'get',
        params //{ offset, limit }
    })
}

export function getPostAttachments(post_id) {
    return request({
        url: `/feed/posts/${post_id}/get_attachments`,
        method: 'get',
    })
}