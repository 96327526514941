<template>
    <div class="upload-subtitle-component">
        <div class="row m-b-10">
            <div class="col-md-5 lang" :class="[{'l-selected': selected_lang}]"><vue-select placeholder="lang" :options="languages" label="first_name" :reduce="lang => lang" v-model="selected_lang"></vue-select></div>
            <div class="col-md-6 file">
                <div v-if="selected_lang">
                    <input v-if="status === 'INITIATED'" ref="fileInput" @change="selectionProcessing($event)" type="file" :accept="formats"/>
                    <a v-else-if="status === 'COMPLETE'" target="_blank" :title="subtitle.origin_name" :href="subtitle.origin_s3_path">{{ subtitle.origin_name }}</a>
                    <i v-else class="fa fa-spin fa-spinner"></i>
                </div>
            </div>
            <div class="col-md-1"><i class="fa fa-trash remove-btn" @click="destroy_sub()"></i></div>
<!--            <div class="col-md-1"><input class="styled-checkbox" type="checkbox" v-model="subtitle."/></div>-->
        </div>
    </div>
</template>

<script>
    import CountryLanguage from 'country-language'
    import VueSelect from 'vue-select'
    import {mapDynamicFields} from "./helpers/mapDynamicFields"
    import "vue-select/dist/vue-select.css";
    export default {
        props: ['subtitle'],
        components: {VueSelect},
        data() {
            return {
                languages: [],
                selected_lang: null,
                formats: ".vtt, .srt" //, .ttml, .webvtt, .emsg, .eventstream, .id3, .cea608, .daterange" https://docs.portal.theoplayer.com/api-reference/web/theoplayer.texttrackdescription.md
            }
        },
        computed: {
            processing_errors: function () {
                return (this.subtitle || {}).processing_errors || {}
            },
            holder: function(){
                return this.$store.state.Videos.uploads.main
            },
            holder_type() {
                return this.holder.class_name
            },
            holder_id() {
                return (this.holder.api_state || {}).id
            },
            id() {
                return (this.subtitle || {}).id
            },
            uid() {
                return (this.subtitle || {}).uid
            },
            srclang() {
                return (this.selected_lang ? this.selected_lang.iso639_1 : this.subtitle.srclang)
            },
            label() {
                return this.selected_lang ? this.selected_lang.first_name : ""
            },
            status() {
                return this.subtitle.status
            },
            used_lang_codes() {
                return this.$store.state.Subtitles.list.map(s => s.srclang)
            },
            is_plus_user(){
                const user = JSON.parse(localStorage.getItem('user'));
                return user && user.is_plus_account
            },
        },
        watch: {
            'srclang': function (val) {
                const {id, uid, srclang, label} = this
                this.$store.commit('Subtitles/UPDATE', { id, uid, srclang, label });
                if (id) {
                    this.$store.dispatch('Subtitles/update', {uid})
                }
            },
            'processing_errors': function() {
                this.displayValidationErrors()
            }
        },
        methods: {
            destroy_sub: function (data) {
                let {id, uid, holder_id} = this.subtitle;
                this.$store.dispatch('Subtitles/destroy', {id, uid, holder_id})
            },
            async selectionProcessing(event) {
                let file = event.target.files[0];
                const {uid} = this;
                this.$store.commit('Subtitles/PARSE_FILE', {uid, file})
                await this.$store.dispatch('Subtitles/create', {uid})
                await this.$store.dispatch('Subtitles/upload', {uid})
                await this.$store.dispatch('Subtitles/update', {uid})
            },
            displayValidationErrors() {
                let { processing_errors } = this
                Object.keys(processing_errors).forEach(function (k) {
                    let error = `${k}: ${processing_errors[k]}`;
                    if (k === 'space_limited') {
                        const no_plus_part = is_plus_user ? `<span>, or upgrade to <a href="/subscriptions" target="_blank" @click.stop="">Plus account</a></span>` : ''
                        error = `The file you are trying to upload exceeds the allotted storage.
                            Please clean your <a href="/my_storage"  target="_blank" @click.stop="">Storage</a> first before attempting to re-upload it${ no_plus_part }.`
                    }
                    iziToast.error({ message: error, maxWidth: '700px' })
                });
            }
        },
        created() {
            const all_languages = CountryLanguage.getLanguages().filter(lang => !!lang.iso639_1);
            all_languages.forEach(lang => lang.first_name = lang.name[lang.name.length-1]);
            this.languages = all_languages.filter(lang => !this.used_lang_codes.includes(lang.iso639_1));
            if (this.srclang) {
                this.selected_lang = all_languages.filter(l => l.iso639_1 === this.srclang)[0]
            }
        }
    }
</script>

<style lang="scss">
    .upload-subtitle-component {
        .lang {
            .vs__selected-options{
                min-height: 21px;
                overflow: hidden;
            }
            &.l-selected .vs__selected-options{
                max-width: calc(100% - 40px);
            }
            .vs__selected + .vs__search {
                position: absolute;
                width: 50px;
                height: 21px;
            }
        }
        .file {
            overflow: hidden;
            margin-top: 2px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .remove-btn {
            opacity: 0.2;
            transition: opacity ease-out 200ms;
            &:hover {
                opacity: 0.8;
            }
        }
    }
</style>