import {eventBus} from '@utils/event_bus'

const getDefaultState = () => {
    return {
        sign_up: {
            hasError: false
        },
        update_pass: {
            hasError: false
        },
        album: {
            hasError: false
        }
    }
}

const errors = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        search(state) {
            return (form, field) => state[form] ? state[form][field] : ''
        },
        isFormHasError(state) {
            return (form) => state[form].hasError
        }
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        COMMIT_ERROR(state, errorObj) {
            const {form, field, message} = errorObj
            state[form][field] = message
            state[form].hasError = Object.values(state[form]).some(field => field.length);
            eventBus.$emit('error-updated')
        }
    },
    actions: {
        SET_ERROR({commit}, errorObj) {
            if (errorObj.fields) {
                const fields = Object.keys(errorObj.fields)
                fields.forEach((field) => {
                    commit('COMMIT_ERROR', {form: errorObj.form, field: field, message: errorObj.fields[field][0]})
                })
            } else {
                commit('COMMIT_ERROR', errorObj)
            }
        }
    }
}

export default errors