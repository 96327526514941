export default {
    capitalize(value){
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    },

    unSnakeCase(value){
        if (!value) return ''
        value = value.toString()
        return value.replace(/_/g, ' ');
    }
}