const albums = {
    namespaced: true,
    state: {
        loaded: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
}

export default albums