<template>
<div class="add-photo-btn-component">
    <button v-tooltip="$t('FEED.ATTACH_IMAGE')"
            class="btn__reset"
            @click="attachPhoto">
        <input ref="file"
               class="hide"
               type="file"
               accept="image/*"
               multiple
               @change="handleAttachPhoto">
        <i class="icon-photo" />
    </button>
</div>
</template>

<script>
import {mapActions} from 'vuex'
import Image from '@models/Image'
    export default {
        methods: {
            ...mapActions('entities/comments', {
                uploadImage: 'UPLOAD_IMAGE',
            }),
            attachPhoto() {
                this.$refs.file.click()
                this.$refs.file.value = ''
            },
            handleAttachPhoto(event) {
                let files = event.target.files
                this.$emit('uploading', true)
                for (let i = 0, file; file = files[i]; i++) {
                    const image = document.createElement('img')
                    image.onerror = (e) => {
                        file.checked = true
                        if ([...files].filter((f) => !f.checked).length === 0) {
                            this.$emit('uploading', false)
                        }
                        console.error('handleAttachPhoto image onerror', file.name, e)
                        this.$toast.error(`It looks like the "${ file.name }" is in an unsupported format`)
                    }
                    image.onload = async () => {
                        file.checked = true
                        if ([...files].filter((f) => !f.checked).length === 0) {
                            this.$emit('uploading', false)
                        }
                        try {
                            Image.api().createAndUpload(file, {
                                onCreate: (response) => {
                                    this.$emit('addImage', { id: response.id, selector: 'photo', response })
                                }
                            })
                        } catch (e) {
                            console.error('handleAttachPhoto Image create err:', e)
                            this.$toast.error(this.$t('ERROR_MESSAGE.SOMETHING_WRONG'), 'Error', {position: 'topRight'})
                        }
                    }
                    const fr = new FileReader()
                    fr.onload = function(e) {
                        image.src = e.target.result
                    }
                    fr.readAsDataURL(file);
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .add-photo-btn-component {

    }
</style>