import request from '@utils/request'

export function SignIn(payload) {
    return request({
        baseURL: `${window.location.origin}`,
        url: '/users/login',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function Logout() {
    return request({
        baseURL: `${window.location.origin}`,
        url: '/users/logout',
        method: 'delete',
        headers: {'Accept': 'text/javascript'}
    })
}

export function SignUp(payload) {
    return request({
        url: '/auth',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function ForgotPass(payload) {
    return request({
        url: '/auth/password',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function getUser(id) {
    return request({
        baseURL: `${window.location.origin}`,
        url: '/users/current',
        method: 'get',
    })
}

export function getUserInfo() {
    return request({
        url: '/users/me',
        method: 'get',
    })
}

export function ResendEmail(payload) {
    return request({
        url: '/auth/confirmation',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function CheckConfirmToken(token) {
    return request({
        url: `/auth/password/edit?reset_password_token=${token}`,
        method: 'get'
    })
}

export function UpdatePassword(payload) {
    return request({
        url: '/auth/password',
        method: 'patch',
        data: {
            ...payload
        }
    })
}
