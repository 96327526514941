import axios from 'axios'
import filters from '../../helpers/filters'

const new_user = (payload) => {
  return {
    id: '',
    banner: {
      status: 'INITIATED', // 'INITIATED', 'CHECKING', 'PROCESSING', 'UPLOADING', 'UPLOADED', 'COMPLETE', 'ERROR'
      origin_name: '',
      origin_type: '',
      origin_size: '',
      paths: {},
      upload_links: {},
      file: undefined,
      url: '',
      api_request_processing: false,
      processing_errors: {},
      upload_percentage: 0,
      croppedImageUrl: '',
      croppedImageFile: '',
      initImageUrl: '',
    },
    avatar: {
      status: 'INITIATED', // 'INITIATED', 'CHECKING', 'PROCESSING', 'UPLOADING', 'UPLOADED', 'COMPLETE', 'ERROR'
      origin_name: '',
      origin_type: '',
      origin_size: '',
      paths: {},
      upload_links: {},
      file: undefined,
      url: '',
      api_request_processing: false,
      processing_errors: {},
      upload_percentage: 0,
      croppedImageUrl: '',
      croppedImageFile: '',
      initImageUrl: '',
    },
    api_request_processing: false,
    ...payload
  }
}

const state = {
    list: []
}

const actions = {
  getS3Urls({commit, state, getters}, payload){
    let {id, imgType} = payload,
        imageObj = getters.getUserById(id)[imgType]
    return new Promise((resolve, reject) => {
      axios.post(`/users/get_s3_urls`, {
        imgType,
        file_name: imageObj.origin_name,
        file_size: imageObj.origin_size,
        file_type: imageObj.origin_type,
      }).then((response) => {        
        commit(`UPDATE_${imgType.toUpperCase()}`, {id, data: {upload_links: response.data.message} })
        resolve()
      }).catch((error) => {
        let errors = { 'api_error': ["get /users/get_s3_urls is failed"] }
        commit(`UPDATE_${imgType.toUpperCase()}`, {id, data: {processing_errors: errors}})
        
        reject("Failed to get /users/get_s3_urls")
      })
    })
  },
  upload({commit, state, getters}, payload) {
    let {id, imgType} = payload,
        user = getters.getUserById(id),
        signedUrl = user[imgType].upload_links.put_url,
        publicUrl = user[imgType].upload_links.public_url,
        file = user[imgType].croppedImageFile
    commit(`UPDATE_${imgType.toUpperCase()}`, {id, data: {status: 'UPLOADING'}})
    return new Promise((resolve, reject) => {
      axios.put(signedUrl, file,{
        headers: {'Content-Type': file.type},
        onUploadProgress: function (progressEvent) {
          let percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          commit(`UPDATE_${imgType.toUpperCase()}`, {id, data: {upload_percentage: percent}})
        }
      }).then((response) => {
        commit(`UPDATE_${imgType.toUpperCase()}`, {id, data: {status: 'UPLOADED', origin_s3_path: publicUrl}})
        resolve()
      }).catch((error) => {
        console.error(error);
        let errors = { 'Upload to storage error': ["Please try again later"] }
        commit(`UPDATE_${imgType.toUpperCase()}`, {id, data: {processing_errors: errors}})
      })
    })
  },
  updateBanner({commit, getters}, payload) {
    let {id} = payload,
        user = getters.getUserById(id)
        
    if (!user.api_request_processing) {
      commit('UPDATE', {id, api_request_processing: true})
      return new Promise((resolve, reject) => {
        axios.put(`/users/${user.id}/update_v2_banner`, {
          remote_banner_url: user.banner.origin_s3_path
        }).then((response) => {
          commit('UPDATE_BANNER', {id, data: {status: 'COMPLETE', ...response.data.response}})
          resolve()
        }).catch((error) => {
          if (error.response.data.status === 422) {
            commit('UPDATE_BANNER', {id, data: {processing_errors: error.response.data.message}})
          } else {
            let errors = { 'api_error': ["put request is failed"] }
            commit('UPDATE_BANNER', {id, data: {processing_errors: errors}})
          }
          reject("Failed to update User banner")
        }).then(() => {
          commit('UPDATE', {id, api_request_processing: false})
        })
      })
    } else {
      console.error('PUT /users/:id is already processing')
    }
  },
  updateAvatar({commit, getters}, payload) {
    let {id} = payload,
        user = getters.getUserById(id)
        
    if (!user.api_request_processing) {
      commit('UPDATE', {id, api_request_processing: true})
      return new Promise((resolve, reject) => {
        axios.put(`/users/${user.id}/update_avatar`, {
          remote_avatar_url: user.avatar.origin_s3_path
        }).then((response) => {
          commit('UPDATE_AVATAR', {id, data: {status: 'COMPLETE'}})
          resolve()
        }).catch((error) => {
          if (error.response.data.status === 422) {
            commit('UPDATE_AVATAR', {id, data: {processing_errors: error.response.data.message}})
          } else {
            let errors = { 'api_error': ["put request is failed"] }
            commit('UPDATE_AVATAR', {id, data: {processing_errors: errors}})
          }
          reject("Failed to update User avatar")
        }).then(() => {
          commit('UPDATE', {id, api_request_processing: false})
        })
      })
    } else {
      console.error('PUT /users/:id is already processing')
    }
  },
  updateBusinessAvatar({commit, getters}, payload) {
    let {id, business_id} = payload,
        user = getters.getUserById(id)
        
    if (!user.api_request_processing) {
      commit('UPDATE', {id, api_request_processing: true})
      return new Promise((resolve, reject) => {
        axios.put(`/settings/${business_id}/update_bio_avatar`, {
          remote_avatar_url: user.avatar.origin_s3_path
        }).then((response) => {
          commit('UPDATE_AVATAR', {id, data: {status: 'COMPLETE'}})
          resolve()
        }).catch((error) => {
          if (error.response.data.status === 422) {
            commit('UPDATE_AVATAR', {id, data: {processing_errors: error.response.data.message}})
          } else {
            let errors = { 'api_error': ["put request is failed"] }
            commit('UPDATE_AVATAR', {id, data: {processing_errors: errors}})
          }
          reject("Failed to update User avatar")
        }).then(() => {
          commit('UPDATE', {id, api_request_processing: false})
        })
      })
    } else {
      console.error('PUT /users/:id is already processing')
    }
  },
}

const mutations = {
  ADD(state, payload) {
    state.list.push(new_user(payload))
  },

  PARSE_IMAGE(state, payload){
    let {id, file, status, imgType} = payload
    let user = state.list.find(user => user.id === id)
    Object.assign(user[imgType], {
      status: status || 'CHECKING',
      file: file,
      origin_name: file.name,
      origin_type: file.type,
      origin_size: file.size,
      url: URL.createObjectURL(file)
    })
  },

  UPDATE_BANNER(state, payload){
    let {id, data} = payload
    let user = state.list.find(user => user.id === id)
    Object.assign(user.banner, data)
    if(data.hasOwnProperty('processing_errors')){
      let message = ''
      for (let [key, errors] of Object.entries(data.processing_errors)) {
        message += `<div><b>${filters.capitalize(filters.unSnakeCase(key))}</b>`
        message += `<ul style="margin: 0">`
        for(let error of errors){
          message += `<li>${error}</li>`
        }
        message += '</ul><div>'
      }
      iziToast.error({
        message: message,
        maxWidth: '700px'
      })
    }
  },

  UPDATE_AVATAR(state, payload){
    let {id, data} = payload
    let user = state.list.find(user => user.id === id)
    Object.assign(user.avatar, data)
    if(data.hasOwnProperty('processing_errors')){
      let message = ''
      for (let [key, errors] of Object.entries(data.processing_errors)) {
        message += `<div><b>${filters.capitalize(filters.unSnakeCase(key))}</b>`
        message += `<ul style="margin: 0">`
        for(let error of errors){
          message += `<li>${error}</li>`
        }
        message += '</ul><div>'
      }
      iziToast.error({
        message: message,
        maxWidth: '700px'
      })
    }
  },

  UPDATE(state, payload) {
    let {id} = payload
    let user = state.list.find(user => user.id === id)
    Object.assign(user, payload)
    if(payload.hasOwnProperty('processing_errors')){
      let message = ''
      for (let [key, errors] of Object.entries(payload.processing_errors)) {
        message += `<div><b>${filters.capitalize(filters.unSnakeCase(key))}</b>`
        message += `<ul style="margin: 0">`
        for(let error of errors){
          message += `<li>${error}</li>`
        }
        message += '</ul><div>'
      }
      iziToast.error({
        message: message,
        maxWidth: '700px'
      })
    }
  },

  CLEAR(state, payload) {
    let {id} = payload;
    let user = state.list.find(user => user.id === id)
    Object.assign(img, new_user(payload))
  }
}

const getters = {
  getUserById: state => id => {
    return state.list.find(user => user.id === id)
  }
}

export default {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
}