<template>
<a class="AddFriendListBtn btn ghost btn__round" href="#" @click="addFriendsList">
    {{ $t('BUTTON.NEW_LIST') }}
</a>
</template>

<script>
    export default {
        mounted() {
            const that = this
            //window.dispatchEvent(new CustomEvent('created-friends-list', { id: newListItemId, name: newListName }));
            window.addEventListener('created-friends-list', (e) => {
                const newList = e.detail
                if (newList?.id) {
                    that.$emit('created', newList)
                }
            })
        },
        methods: {
            addFriendsList() {
                $(".add-friend-list-modal .list_name").val('').trigger("blur");
                $("#frm-add-friend-list").validate().resetForm();
                $('.add-friend-list-modal .from_parent').val('from-post');
                $('.add-friend-list-modal').modal('show');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .AddFriendListBtn {
        min-width: 110px;
    }
</style>