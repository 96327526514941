<!--WARNING: !!!placeholder=" " IMPORTANT STYLE HOCK, DONT TOUCH!!!-->
<template>
<form @submit.prevent="signUp">
    <div class="modal__head" />
    <div class="modal__body">
        <img
            src="@assets/imgs/Sphir_logo_trademark_WEB-HEADER.svg"
            alt="">
        <div class="input__field">
            <input
                id="email"
                v-model="email"
                type="email"
                name="email"
                placeholder=" "
                required
                @input="validateEmail"
                @keyup="clearError('login')">
            <label
                class="label"
                for="email">{{ $t('INPUT.EMAIL') }}</label>
        </div>
        <div class="input__field">
            <input
                id="password"
                v-model="password"
                type="password"
                name="password"
                placeholder=" "
                required
                @keyup="clearError('login')">
            <label
                class="label"
                for="password">{{ $t('INPUT.PASS') }}</label>
        </div>
        <div class="text__right">
            <a
                class="text__right color__secondary text__bold fs__11 margin-t__10"
                @click="showForm('forgot')">{{
                    $t('LINK.FORGOT') }}</a>
        </div>
    </div>
    <div class="modal__footer">
        <div
            v-show="localError || error('login')"
            class="error-message"
            v-html="error('login') || localError">
            {{ localError || error('login') }}
        </div>
        <button
            class="btn full__width"
            type="submit"
            :disabled="isBtnDisabled"
            @click="signUp">
            {{ message || $t('BUTTON.LOGIN') }}
        </button>
        <div class="text__center color__secondary fs__11 margin-t__15">
            {{ $t('SIGN_IN.AGREE') }}
            <router-link
                :to="{name: 'terms-of-use'}"
                target="_blank"
                class="text__bold color__secondary">
                {{
                    $t('LINK.TERM') }}
            </router-link>
        </div>
    </div>
</form>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'

    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

    export default {
        name: "Login",
        data() {
            return {
                email: '',
                password: '',
                localError: '',
                message: ''
            }
        },
        computed: {
            ...mapGetters('auth', {
                error: 'getError'
            }),
            // @vuese
            // Check is need to disable submit button
            isBtnDisabled() {
                return !!this.message || (!this.email || !this.password) || this.localError !== '' || this.error('login') !== ''
            }
        },
        methods: {
            ...mapActions('auth', {
                login: 'LOGIN'
            }),
            ...mapMutations('auth', {
                clearError: 'CLEAR_ERROR',
                showForm: 'SHOW_FORM'
            }),
            // @vuese
            // Function to validate email field
            // @arg `[email: String]`
            validateEmail() {
                (this.email == '') ? '' : (reg.test(this.email)) ? this.localError = '' : this.localError = 'Please enter a valid email address.';
            },
            // @vuese
            // Submit method that send form data to SignUp endpoint
            // @arg `[event]`, `[domain: 'sphir.io']`, `[email: String]`, `[password: String]`
            async signUp(event) {
                event.preventDefault();
                event.stopPropagation();
                var data = {
                    domain: process.env.API_CLIENT,
                    email: this.email,
                    password: this.password
                }
                this.message = 'Loading...'
                let res = await this.login(data)
                if (!res || res.status !== "success") {
                    this.message = ''
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .error-message {
        color: red;
        text-align: center;
    }
</style>