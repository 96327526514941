<template>
<div class="post-preview-component" :class="{shareable: shareable}">
    <FollowFrequency v-if="post.follow_frequency" />
    <div
        v-if="!post.follow_frequency"
        :class="{'full__attachment': isFullAttachment}"
        class="tile__l">
        <a
            class="tile__l__head ava__line__mk1"
            :href="owner_profile_link">
            <div
                class="ava__s"
                :style="{'background-image': `url(${post.owner.avatar})`}" />
            <div class="ava__line__content fs__12 padding-l__20">
                <div class="text__bold text__ellipsis color__secondary">
                    <a
                        :href="owner_profile_link">{{ post.owner.first_name }} {{ post.owner.last_name }}</a><span
                        v-if="group"
                        class="group_link"> to <a
                            :href="`/groups/${group.id}`">{{ group.group_name }}</a></span>
                </div>
                <div class="color__secondary_60">
                    {{ moment(post.created_at).fromNow() }}
                    <i
                        class="color__main"
                        :class="(postFromGroup || post.privacy === 'friends') ? 'icon-friends_full' : (post.privacy === 'friends_group' ? 'icon-friends_left' : 'icon-earth')" />
                </div>
            </div>
        </a>
        <div class="tile__l__body color__secondary reset_link">
            <a class="content_link" :href="`posts/${post.id}`">
                <div v-if="post.description">
                    <p v-linkified class="break-word" v-html="postText" />
                    <span v-if="post.description.length > 490">... <a
                        href="#"
                        class="color__main text__bold"
                        @click="toggleShowMore"> {{ showMoreText }} </a></span>
                </div>
            </a>

            <PostPreview
                v-if="post.share_post"
                :shareable="true"
                :post="post.share_post"
                :user="user" />

            <div v-if="!post.share_post">
                <TextEmbed v-if="textEmbed" :embed_url_info="textEmbed" />
                <ParsedLink :content="post" />
                <div
                    v-if="post.attachment_order_type.length && !isFullAttachment || post.attachment_order_type.length === 1"
                    class="feedAttachment">
                    <div
                        v-if="showAttachCount"
                        class="feedAttachment__count">
                        {{ $t('FEED.ATTACH_COUNT', {count: attachCount}) }}
                    </div>
                    <FeedAttachment :content="post" :open-post="true" />
                </div>
                <div v-if="isFullAttachment && post.attachment_order_type.length !== 1">
                    <AttachmentList :post_id="post.id" />
                </div>
            </div>
        </div>
        <div v-if="!shareable" class="tile__l__footer">
            <div>
                <button
                    class="btn__reset no__padding margin-r__25"
                    @click="toggleComments">
                    <i class="icon-comment fs__18 content__sub margin-r__5" />
                    {{ $tc('FEED.COMMENT_COUNT', post.comment_count, { count: post.comment_count }) }}
                </button>
                <span class="likeBlock active">
                    <button
                        class="btn__reset"
                        :disabled="likeLoading"
                        @click="postLike">
                        <span>
                            <i
                                v-if="!post.liked"
                                class="icon-heart" />
                            <i
                                v-if="post.liked"
                                class="icon-heart_fill">
                                <span class="path1" /><span class="path2" /><span class="path3" />
                            </i>
                        </span>
                        <!--{{ post.likes }}-->
                        <LikersCount :post="post" />
                    </button>
                </span>
            </div>
            <div>
                <button
                    class="btn__reset fs__20"
                    @click="showShareModal">
                    <i class="icon-share" />
                </button>
                <div
                    class="dropdown__block dropdown__block__right"
                    @mouseleave="hideDropdown">
                    <button
                        class="btn__reset fs__20 margin-l__25"
                        @click="toggleDropdown">
                        <i class="icon-dots" />
                    </button>
                    <DropDown
                        v-if="showDropdown"
                        :items="dropdownContent" />
                </div>
            </div>
        </div>
        <CommentsList
            v-show="showComments"
            :load-comments="isFullAttachment"
            :pinned-comment="pinnedComment"
            :post="post"
            :user="user" />
    </div>
</div>
</template>

<script>
    import {eventBus} from '@utils/event_bus'
    import Post from '@models/Post'
    import TextEmbed from "../Preview/TextEmbed";
    import LikersCount from "../Preview/Post/LikersCount";
    import {mapGetters} from "vuex";
    const CommentsList = () => import('./comments/CommentsList')
    const FeedAttachment = () => import('./FeedAttachment')
    const DropDown = () => import('../Dropdown')
    const ParsedLink = () => import('../Preview/ParsedLink')
    const FollowFrequency = () => import('./FollowFrequency.vue')
    const AttachmentList = () => import('../Card/AttachmentList')

    export default {
        name: 'PostPreview',
        components: {
            TextEmbed,
            CommentsList,
            FeedAttachment,
            DropDown,
            ParsedLink,
            FollowFrequency,
            AttachmentList,
            LikersCount
        },
        props: {
            post: {type: Object, default: () => {}},
            user: {type: Object, default: () => {}},
            shareable: {type: Boolean, default: false},
            isFullAttachment: {type: Boolean, default: false},
            group_id: {type: String, default: null},
            pinnedComment: { type: Object, default: () => {}}
        },
        data() {
            return {
                showComments: false,
                showMore: false,
                showDropdown: false,
                likeLoading: false
            }
        },
        computed: {
            ...mapGetters('auth', {
                currentUser: 'getCurrentUser',
            }),
            showAttachCount () {
                return this.post.attachment_order_id.length > 1 ? true : false
            },
            attachCount () {
                return this.post.attachment_order_id.length - 1
            },
            textEmbed () {
                return this.post.text_embed
            },
            postText () {
                let result = this.post.description
                this.post.mentions.map((mention) => {
                  let auth_user_id = (this.user || {}).id;
                  let link = (auth_user_id === mention.value) ? "/users/profile" : `/users/${mention.value}/other_profile`
                  let html_link = `<a target="_blank" href="${link}">${mention.label}</a>`
                  result = result.replace(`@${mention.label}`, html_link)
                })

                if (this.showMore) {
                    result = result.replace(/\n/g, '<br>')
                } else {
                    result = result.substr(0, 490).replace(/\n/g, '<br>')
                }
                return result
            },
            showMoreText () {
                return this.showMore ? this.$t('FEED.LESS') : this.$t('FEED.MORE')
            },
            group() {
              return this.post.group
            },
            postFromGroup() {
                return this.post.my_group_id
            },
            dropdownContent () {
                if (this.user && this.user.id === this.post.owner_id) {
                    return [
                        {
                            title: this.$t('FEED.EDIT_POST'),
                            action: 'entities/posts/EDIT_POST',
                            payload: this.post
                        },
                        {
                            title: this.$t('FEED.DELETE_POST'),
                            action: 'entities/posts/OPEN_DELETE_POST_MODAL',
                            payload: this.post
                        },
                    ]
                } else {
                    return [
                        {
                            title: this.$t('FEED.FLAG_POST'),
                            action: this.user ? 'entities/posts/FLAG_POST' : (()=> this.$modal.show('please-login')),
                            payload: this.post
                        }
                    ]
                }
            },
            owner_profile_link(){
              let auth_user_id = (this.user || {}).id;
              let link = (auth_user_id === this.post.owner_id) ? "/users/profile" : `/users/${this.post.owner.id}/other_profile`
              return link
            },
        },
        mounted() {
            if (this.isFullAttachment) {
                this.toggleComments()
            }
        },
        methods: {
            showShareModal() {
                if (!this.currentUser) {
                    return this.$modal.show('please-login');
                }
              const that = this
              const dataId = this.post.id
              const data_url = `${window.location.origin}/posts/${dataId}`
              $(".modal-share-post .copy_clip").attr("data-clipboard-text", data_url);
              $(".modal-share-post #postId").val(dataId);
              const data_title = this.post.title
              const data_description = this.post.description
              $(".modal-share-post .social-share")
                  .attr("data-url", data_url)
                  .attr("data-title", data_title)
                  .attr("data-description", data_description);
              const msg_url = `${window.location.origin}/messages/new?post_id=${dataId}`
              $(".modal-share-post .btn-share-message-friend").attr("href", msg_url);

              const btnShareToFeed = $(".modal-share-post .btn-share-to-feed");
              if (this.post.privacy === "public"){
                  btnShareToFeed
                      .removeClass('hide')
                      .off("click")
                      .on("click", function (e) {
                          $(".modal-share-post").modal("hide");
                          const share_post = that.post.share_post || that.post
                          that.$modal.show('post-form', {post: {
                              share_post,
                              share_post_id: share_post.id,
                              // my_group_id: that.group_id,
                          }})
                      });
              } else {
                  btnShareToFeed.addClass('hide');
              }
              $(".modal-share-post").modal("show");
            },
            async postLike() {
                if (!this.currentUser) {
                    return this.$modal.show('please-login');
                }
                this.likeLoading = true
                await Post.dispatch('LIKE_POST', this.post.id)
                this.likeLoading = false
            },
            toggleComments() {
                this.showComments = !this.showComments
                if (this.showComments) {
                  eventBus.$emit('show-comments', {data: {post_id: this.post.id}})
                }
            },
            toggleDropdown() {
                this.showDropdown = !this.showDropdown
            },
            hideDropdown () {
                this.showDropdown = false
            },
            toggleShowMore(e) {
                e.preventDefault()
                e.stopPropagation()
                if (this.showMore) {
                  const el = document.querySelector(`a[href='#${this.post.id}']`)
                  el.scrollIntoView()
                }
                this.showMore = !this.showMore
            }
        }
    }
</script>

<style lang="scss" scoped>
  .post-preview-component {
      .ava__line__mk1 {
          .ava__line__content {
              width: calc(100% - 40px);
          }
      }
      .full__attachment {
          .feedAttachment {
                max-width: 400px;
                margin: 0 auto;
          }
      }
      &.shareable {
          padding: 0 20px 0 10px;
          .tile__l {
              border: none;
              box-shadow: none;
              padding-bottom: 0;
              margin-bottom: 0;
          }
          .tile__l__head, .tile__l__body {
              padding-left: 0;
              padding-right: 0;
          }
          a {
              font-weight: unset;
              color: unset;
          }
      }
      .post-preview-component {
          &.shareable {
              padding: 0;
              .tile__l__head {
                  background: #FAF9FC;
                  padding: 10px 20px;
                  margin: 10px 0 ;

              }
          }
      }
  }


  .reset_link {
    .content_link {
      color: black;
      cursor: default;
      &:hover {
        color: black;
      }
    }
  }
  .break-word {
    overflow-wrap: break-word;
  }
  .icon-earth {
    left: 0px;
  }
  .icon-share {
    position: relative;
    top: 2px;
  }
  .icon-friends_left, .icon-friends_full {
    color: black;
  }
  .ava__line__content {
    .group_link {
      font-weight: normal;
    }
    a {
      font-weight: bold;
      color: black;
      &:hover {
        color: black;
      }
    }
  }

</style>