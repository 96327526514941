import {Model} from '@vuex-orm/core'
import axios from 'axios'
class Image extends Model {
    static entity = 'images'

    static fields() {
        return {
            // api partial
            id: this.uid(),
            is_temp: this.attr(false),
            owner_id: this.attr(null),
            is_vendor_avatar: this.attr(null),
            file_size: this.attr(0),
            crop_x: this.attr(0),
            crop_y: this.attr(0),
            crop_w: this.attr(0),
            crop_h: this.attr(0),
            width: this.attr(0),
            height: this.attr(0),
            class_name: this.attr('Image'),
            thumb_avatar: this.attr(null),
            preview_image: this.attr(null),
            main_image: this.attr(null),
            avatar: this.attr(null),
            upload_links: this.attr({}),

            // only ui
            processing_stage: this.attr(''), // 'RESERVED', 'UPLOADING', 'UPLOADED', 'COMPLETED'
            image_tmp_url: this.attr(''),
            upload_percentage: this.attr(null),
            cancelTokenSource: this.attr(null),
            // relations
        }
    }

    static apiConfig = {
        actions: {
            // async fetch(params = {}) {
            //     return await this.get("/images", {
            //         params: params,
            //         dataKey: 'response'
            //     })
            // },

            // async destroy(id) {
            //     return await this.delete(`/images/${id}`, { delete: id })
            // },

            // Send information about the file to make sure file is valid and getting upload links
            async createAndUpload(file, processingParams={}){
                const {onCreate, onUploaded} = processingParams
                let data = {
                    file_name: file.name,
                    file_type: file.type,
                    file_size: file.size
                }

                let create_response;
                try {
                    create_response = (await this.post('/images', {image: data}, {
                        dataKey: 'response'
                    })).response.data.response
                }catch(response){
                    console.error(response)
                    let message = `${file.name} - ${Object.values(response.data.message)[0]}`
                    iziToast.error({ message: message, maxWidth: '700px' })
                    return;
                }

                if (onCreate) { onCreate(create_response) }

                // uploading
                let image = Image.find(create_response.id),
                    signedUrl = image.upload_links.put_url,
                    publicUrl = image.upload_links.public_url
                Image.update({id: image.id, processing_stage: 'UPLOADING', image_tmp_url: URL.createObjectURL(file)})


                let upload_result;
                try {
                    const cancelTokenSource = axios.CancelToken.source();
                    Image.update({id: image.id, cancelTokenSource})
                    upload_result = await this.put(signedUrl, file, {
                        timeout: 60 * 60 * 1000,
                        headers: {'Content-Type': file.type},
                        cancelToken: cancelTokenSource.token,
                        onUploadProgress: function (progressEvent) {
                            let percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            Image.update({id: image.id, upload_percentage: percent})
                        }
                    })
                }catch(response){ //by cancelToken ('cancel img upload by user') or by something went wrong '
                    console.error(response)
                    let message = `${file.name} - Uploading failed`
                    iziToast.error({ message: message, maxWidth: '700px' })
                    Image.delete(image.id)
                    return;
                }

                if (onUploaded) { onUploaded() }

                Image.update({id: image.id, processing_stage: 'UPLOADED', avatar: publicUrl, cancelTokenSource: null})

                let data2 = {
                    remote_avatar_url: publicUrl
                }
                try {
                    const update_result = await this.put(`/images/${image.id}`, {image: data2}, {
                        dataKey: 'response'
                    })
                } catch(response) {
                    console.error(response)
                    let message = `${file.name} - ${Object.values(response.data.message)[0]}`
                    iziToast.error({ message: message, maxWidth: '700px' })
                    Image.delete(image.id)
                    return;
                }
                Image.update({id: image.id, processing_stage: 'COMPLETED'})
            }
        }
    }
}

export default Image