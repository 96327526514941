<template>
<div class="search-card-collection-component">
    <div class="text__bold text__center modal-header">
        <i class="icon-arrow-back pointer" @click="$emit('close')" />
        {{ $t('POST_FORM.ATTACH_A_CARD') }}
    </div>
    <div class="comments__cardAttach__form">
        <div class="flex flex__between flex__row">
            <input
                v-model="searchKeyWord"
                type="text"
                class="full__width input__reset bg-transparent"
                :placeholder="$t('PLACEHOLDER.SEARCH_CARDS')"
                @input="searchAttach">
            <span class="icon icon-main-nav-search" @click="searchAttach" />
            <CreateCardBtn />
        </div>
    </div>
    <div v-if="searchKeyWord" class="comments__cardAttach__results">
        <div v-if="searchProgress" class="spinner text-center">
            <div class="spinner-border" />
        </div>

        <div
            v-if="searchedAttachments.length == 0"
            class="no-items">
            {{ $t('ERROR_MESSAGE.NO_SEARCH_RESULT') }}
        </div>
        <div
            v-for="attach_group in searchedAttachments"
            :key="attach_group.$index">
            <div v-if="attach_group.items.length">
                <div class="comments__cardAttach__label">
                    {{ attach_group.type === 'my' ? $t('FEED.MY') : $t('FEED.OTHER') }}
                </div>
                <SearchItem
                    v-for="item in attach_group.items"
                    :key="item.$index"
                    :img_src="getImgUrl(item)"
                    :sub_title="getTypeLabel(item)"
                    :title="item.title || item.name"
                    @click="handleChooseSearchedItem(item)" />
            </div>
        </div>
    </div>
    <div class="modal-footer text__right">
        <CreateCardBtn />
    </div>
</div>
</template>


<script>
    import helper from "@utils/helper";
    import {mapActions, mapGetters} from "vuex";
    import CreateCardBtn from "./CreateCardBtn";
    import SearchItem from "@kit/SearchItem";

    export default {
        components: { CreateCardBtn, SearchItem },
        data() {
            return {
                searchKeyWord: '',
                searchProgress: false,
            }
        },
        computed: {
            ...mapGetters('entities/posts', {
                searchedAttachments: 'getSearchedAttachments',
            }),
        },
        methods: {
            ...mapActions('entities/posts', {
                searchAttachments: 'SEARCH_ATTACHMENTS'
            }),
            searchAttach: helper.debounce( async function() {
                this.searchProgress = true
                await this.searchAttachments(this.searchKeyWord)
                this.searchProgress = false
            }, 500),
            async handleChooseSearchedItem(attach) {
                this.$root.$emit('searchCardCollection:chosen', attach);
                this.searchKeyWord = ''
                this.$emit('close')
                // this.$modal.hide('search-card-collection')
            },
            getImgUrl(item) {
                return item && (item.embed_url_info?.img_src || item.cover_photo || item.logo_image || (item.four_cards_logo && item.four_cards_logo[0]?.logo_image)) || process.env.DEFAULT_IMAGE
            },
            getTypeLabel(item) {
                if (item.selector.includes('card')) {
                    return item.type
                } else if (item.selector.includes('product')) {
                    let res = `${item.category_name} &nbsp; &nbsp; $${item.price[0]}`
                    return (!item.price[1]) ? res : `${res} / $${item.price[1]}`
                } else if (item.selector.includes('folder')) {
                    return 'Collection'
                }
            },
        },
    }
</script>

<style lang="scss">
.search-card-collection-component {
    .search-item-component {
        margin: 15px 0;
        &:hover {
            background: #cfcbe5;
        }
    }
    @media (max-width: 540px){
        .comments__cardAttach__form .CreateCardBtn {
            display: none;
        }
        .modal-footer .CreateCardBtn {
            margin: 0;
            button {
                width: 100%;
                margin: 30px 0 0;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .search-card-collection-component {
        .input__reset {
            padding: 0 20px 0 0;
        }
        .comments__cardAttach__form .icon-main-nav-search {
            margin-left: -15px;
        }
        .modal-header .icon-arrow-back {
            position: absolute;
            left: 20px;
            color: #4c4c4c;
            font-size: 14px;
            line-height: 30px;
        }
        .comments__cardAttach__form {
            padding: 0;
            margin: 0;
        }
        .comments__cardAttach__results {
            margin-top: 15px;
            padding: 0;
        }
        .spinner {
            margin: 15px;
        }
        .comments__cardAttach__label {
            margin: 0;
        }
        .spinner-border {
            opacity: 0.4;
        }

        .modal-footer {
            display: none;
            @media (max-width: 540px){
                display: block;
            }
        }
    }
</style>