import axios from 'axios'

const state = {
    list: []
}

const actions = {
    create({commit, state, getters}, payload) {
        let {uid} = payload,
            image = getters.getImageByUid(uid)

        if (!image.api_request_processing) {
            commit('UPDATE', {uid, api_request_processing: true})
            return new Promise((resolve, reject) => {
                axios.post('/media/images', {image: {
                            origin_name: image.origin_name,
                            origin_type: image.origin_type,
                            origin_size: image.origin_size,
                            holder_type: image.holder_type,
                            holder_id: image.holder_id
                        }
                    })
                    .then((response) => {
                        commit('UPDATE', {uid, ...response.data.response})
                        resolve()
                    }).catch((error) => {
                        if (error.response.data.status === 422) {
                            commit('UPDATE', {uid, processing_errors: error.response.data.message})
                        } else {
                            let errors = { 'api_error': ["post request is failed"] }
                            commit('UPDATE', {uid, processing_errors: errors})
                        }
                        reject("Failed to create image")
                    }).then(() => {
                        commit('UPDATE', {uid, api_request_processing: false})
                    })
            })
        } else {
            console.error('POST /media/images is already processing')
        }
    },
    upload({commit, state, getters}, payload) {
        let {uid} = payload,
            image = getters.getImageByUid(uid),
            signedUrl = image.upload_links.put_url,
            publicUrl = image.upload_links.public_url,
            file = image.file
        commit('UPDATE', {uid, status: 'UPLOADING'})
        return new Promise((resolve, reject) => {
            axios.put(signedUrl, file,{
                headers: {'Content-Type': file.type},
                onUploadProgress: function (progressEvent) {
                    let percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                    commit('UPDATE', {uid, upload_percentage: percent})
                }
            }).then((response) => {
                commit('UPDATE', {uid, origin_s3_path: publicUrl})
                resolve()
            }).catch((error) => {
                let errors = { 'Upload to storage error': ["Please try again later"] }
                commit('UPDATE', {uid, processing_errors: errors})
            })
        })
    },
    update({commit, getters}, payload) {
        let {uid} = payload,
            image = getters.getImageByUid(uid)
        if (!image.api_request_processing) {
            commit('UPDATE', {uid, api_request_processing: true})
            return new Promise((resolve, reject) => {
                axios.put(`/media/images/${image.id}`, {
                    image: { origin_s3_path: image.origin_s3_path }
                }).then((response) => {
                    commit('UPDATE', {uid, ...response.data.response})
                    resolve()
                }).catch((error) => {
                    if (error.response.data.status === 422) {
                        commit('UPDATE', {uid, processing_errors: error.response.data.message})
                    } else {
                        let errors = { 'api_error': ["put request is failed"] }
                        commit('UPDATE', {uid, processing_errors: errors})
                    }
                    reject("Failed to create image")
                }).then(() => {
                    commit('UPDATE', {uid, api_request_processing: false})
                })
            })
        } else {
            console.error('PUT /media/images/:id is already processing')
        }
    },
    delete({commit, getters}, payload) {
        let {id, uid, holder_id} = payload,
            image = getters.getImageBy(payload)
        return new Promise((resolve, reject) => {
            if ( !image || !image.id ) {
                resolve("Image id is not detected");
            } else {
                axios.delete(`/media/images/${image.id}`)
                    .then((response) => {
                        resolve()
                    })
                    .catch((error) => {
                        reject("Failed to delete image");
                    })
                commit('CLEAR', {id, uid, holder_id})
            }
        })
    },
}

const newImg = function (payload){
    return {
        uid: '_' + Math.random().toString(36).substr(2, 9),
        id: '',
        status: 'INITIATED', // 'INITIATED', 'CHECKING', 'UPLOADING', 'UPLOADED', 'COMPLETE'
        origin_name: '',
        origin_type: '',
        origin_size: '',
        holder_type: '',
        holder_id: '',
        paths: {},
        upload_links: {},
        file: undefined,
        url: '',
        api_request_processing: false,
        processing_errors: {},
        upload_percentage: 0,
        ...payload
    }
}

const mutations = {

    ADD(state, payload) {
        state.list.push(newImg(payload))
    },

    PARSE_FILE(state, payload){
        let {uid, file} = payload
        let img = state.list.find(img => img.uid === uid)
        Object.assign(img, {
            status: 'CHECKING',
            file: file,
            origin_name: file.name,
            origin_type: file.type,
            origin_size: file.size,
            url: URL.createObjectURL(file)
        })
    },

    UPDATE(state, payload) {
        let {uid} = payload
        let img = state.list.find(img => img.uid === uid)
        if (img) { Object.assign(img, payload) }
    },

    CLEAR(state, payload) {
        let img = getters.getImageBy(state)(payload)
        let { uid, holder_id, holder_type } = img
        if (img) { Object.assign(img, newImg({ uid, holder_id, holder_type })) }
    },
}

const getters = {
    getImageByUid: state => uid => {
        return state.list.find(img => img.uid === uid)
    },
    getImageBy: state => payload => {
        let {id, uid, holder_id} = payload;
        return state.list.find(img => (id && id === img.id) || (uid && uid === img.uid) || (holder_id && holder_id === img.holder_id))
    },
}

export default {
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
    getters: getters
}