<template>
<div ref="modal" aria-hidden="true" class="modal_original_content_add_photo modal fade upload-card-detail" data-backdrop="static" data-keyboard="false" role="dialog" tabindex="-1">
  <div class="modal-dialog update-card-design">
    <div class="modal-content">
      <div class="modal-header">
        <div class="body-black bold text-center title block">
          <div>
            Add Photo
          </div>
          <button 
            @click.stop.prevent="close()" 
            aria-label="Close" 
            :class="['close', {'btn-disabled': !['INITIATED', 'COMPLETE'].includes(image.status)}]" 
            data-dismiss="modal" type="button">
          </button>
        </div>
      </div>
      <div class="modal-body clear-fix">
        <div class="form-group i-field">
          <input v-model="label" type="text" class="form-control large_body inputs" placeholder="Enter Label" style="maxlength: 1000">
        </div>
        <div v-show="image.status !== 'COMPLETE' && !_init_image_url"  
          :class="['upload-dropzone', {'in-progress': !['INITIATED', 'COMPLETE'].includes(image.status), 'dragover': dragover}]"
          @click="onClick"
          @drop.prevent="onDrop"
          @dragover.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          v-cloak>
          <input ref="fileInput"
                @change="selectionProcessing($event)"
                type="file"
                accept=".jpeg, .jpg, .png">

          <div v-if="image.status === 'INITIATED'">
            <div class="upload-image-title">
                <div class="bold-text">Drag your image here or click to add one!</div>
                <div class="reg-text">The max file size: 10 MB</div>
            </div>
          </div>

          <div v-else-if="image.status==='CHECKING'">Checking...</div>
          <div v-else-if="image.status==='UPLOADING'">{{image.upload_percentage}}%</div>
          <div v-else-if="['COMPLETE'].includes(image.status)">Complete</div>
        </div>
        <div v-show="image.status === 'COMPLETE' || _init_image_url" class="text-center">
          <img v-if="_init_image_url" :src="_init_image_url" alt="" class="img-responsive">
          <img v-else :src="image.url" alt="" class="img-responsive">
          <div class="new-line" style="margin-top: 20px; display: flex; justify-content: center;">
            <button @click.stop.prevent="changeImage()" class="round-btn-small" style="width: 200px;" type="button">
              Change Image
            </button>
          </div>
        </div>
        <button 
        @click.stop.prevent="done()" 
        :class="['btn-primary-v2', 'add-photo-original-text-card', 'mt-3', {'btn-disabled': !isCanBeProcessed} ]" 
        style="cursor:pointer;" type="button">
          Add
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['uid', 'initImageUrl'],
  data(){
    return{
      label: '',
      dragover: false,
      max_file_size: 1024 * 1024 * 10,
      _init_image_url: '',
      editImageId: '' 
    }
  },
	computed: {
		image: function () {
			return this.$store.getters['Card_details/getImageByUid'](this.uid)
		},
		processing_errors: function () {
			return this.image.processing_errors
    },
    isCanBeProcessed(){
      return (this.label && this.image.status == 'INITIATED' && this.image.id)
      || (this.editImageId && this.image.status == 'INITIATED' && this.image.id)
      || this.image.status == 'COMPLETE'
    }
	},
  methods: {
    async selectionProcessing(event) {
			let file = event.target.files[0];
			if(file){
        const {uid} = this
        if(file.size > this.max_file_size){
          this.$store.commit('Card_details/UPDATE', {
						uid,
						processing_errors: {too_big_file: ['Please select a file not exceeding 10 Mb']}
          })
          this.$refs.fileInput.value = ''
        }else{
          this._init_image_url = ''
          this.$store.commit('Card_details/PARSE_FILE', {uid, file})
          if(this.editImageId){
            let updateData = {}
            if(this.label){updateData.card_label = this.label}
            updateData.file_name = this.image.origin_name
            updateData.file_size = this.image.origin_size
            updateData.file_type = this.image.origin_type
            await this.$store.dispatch('Card_details/update', {uid, update_data: updateData})
            await this.$store.dispatch('Card_details/upload', {uid})
            await this.$store.dispatch('Card_details/update', {
              uid, update_data: { remote_card_photo_url: this.image.origin_s3_path }
            })
          }else{
            await this.$store.dispatch('Card_details/create', {uid})
            await this.$store.dispatch('Card_details/upload', {uid})
            await this.$store.dispatch('Card_details/update', {
              uid, update_data: { remote_card_photo_url: this.image.origin_s3_path }
            })
          }

        }
			}
		},
		onClick() {
			if(["INITIATED", "COMPLETE"].includes(this.image.status)){
				this.$refs.fileInput.click()
			}
		},
		onDrop(e) {
			if(["INITIATED", "COMPLETE"].includes(this.image.status)){
				let {uid} = this,
				droppedFiles = e.dataTransfer.files;
				this.dragover = false;
				if (!droppedFiles) return;
				if (droppedFiles.length > 1) {
					this.$store.commit('Card_details/UPDATE', {
						uid,
						processing_errors: {too_many_files: ['Select please only one file']}
					})
				} else {
					this.$refs.fileInput.files = droppedFiles
					this.$refs.fileInput.dispatchEvent(new Event("change"))
				}
			}
    },
    placeImageToOriginalScribe() {
      let content = `
      <div class="form-group i-field single-original-photo-detail" data-card-detail-id="${this.image.id}">
        <div class="new-photo-label" style="margin-top:20px;margin-bottom:20px;">
          <span> ${this.label} </span>
        </div>
        <div style="text-align:center;">
          <img class="img-responsive" src="${this.image.url}">
        </div>
        <div class="edit-newly-uploaded-photo-container" style="margin-top:20px;display:flex;justify-content:flex-end;">
          <button 
            type="button" 
            data-card-detail-id="${this.image.id}" 
            class="tiny-btn delete-newly-uploaded-photo" 
            style="margin-right: 20px;padding-left: 10px;padding-right: 10px;width: unset;"> 
            Delete 
          </button>
          <button 
            type="button" 
            data-card-detail-id="${this.image.id}" 
            class="tiny-btn edit-newly-uploaded-photo"> 
            Edit
          </button>
        </div>
      </div>`

      if (($(".modal-original-scribe-edit").data('bs.modal') || {})._isShown) {
        if(this.editImageId){
          $(`.single-original-photo-detail[data-card-detail-id="${this.editImageId}"]`).find('.img-responsive').attr("src", `${this.image.url}`);
        }else{
          $(".modal-original-scribe-edit #original-text-details-container").append(content);
        }
      }
      else if (($(".modal-original-scribe").data('bs.modal') || {})._isShown) {
        if(this.editImageId){
          $(`.single-original-photo-detail[data-card-detail-id="${this.editImageId}"]`).find('.img-responsive').attr("src", `${this.image.url}`);
        }else{
          $(".modal-original-scribe #original-text-details-container").append(content);
        }
      }
    },
    placeLabelOnly(){
      if (($(".modal-original-scribe-edit").data('bs.modal') || {})._isShown) {
        $(`.single-original-photo-detail[data-card-detail-id="${this.editImageId}"]`).find('.new-photo-label span').html(this.image.card_label)
      }
      else if (($(".modal-original-scribe").data('bs.modal') || {})._isShown) {
        $(`.single-original-photo-detail[data-card-detail-id="${this.editImageId}"]`).find('.new-photo-label span').html(this.image.card_label)
      }
    },
    async done(){
      let {uid, image, _init_image_url, editImageId} = this
    
      if(image.status == 'COMPLETE'){
        let cdWiIds = $('#card-details-ids')
        let imageIds = `${cdWiIds.val()};${image.id}`

        if(!this.editImageId){
          if(this.label){
            this.$store.commit('Card_details/UPDATE', {uid, card_label: this.label})
            await this.$store.dispatch('Card_details/update', {uid, update_data: { card_label: this.label }})
          }
          cdWiIds.val(imageIds)
        }
        this.placeImageToOriginalScribe()
        this.clear()
        $('.upload-card-detail').modal('hide')
      }else if(this.label && this.image.status == 'INITIATED' && this.image.id){
        this.$store.commit('Card_details/UPDATE', {uid, card_label: this.label})
        await this.$store.dispatch('Card_details/update', {uid, update_data: { card_label: this.label }})
        this.placeLabelOnly()
        this.clear()
        $('.upload-card-detail').modal('hide')
      }
    },
    async close(){
      let {uid, image} = this
      
      if(['COMPLETE', 'INITIATED'].includes(image.status)){
        if(uid && !this.editImageId){
          await this.$store.dispatch('Card_details/destroy', {uid})
          this.$store.commit('Card_details/CLEAR', {uid})
        }
        $(this.$refs.modal).modal('hide')
        this.$refs.fileInput.value = ''
        this.label = ''
        this._init_image_url = ''
        this.editImageId = ''
      }
    },
    changeImage(){
      this.$refs.fileInput.click()
    },
    clear(){
      this.$store.commit('Card_details/CLEAR', {uid: this.uid})
      this.$refs.fileInput.value = ''
      this.label = ''
      this._init_image_url = ''
      this.editImageId = ''
    }
  },
	created() {
    let {uid} = this
    this._init_image_url = this.initImageUrl
    this.editImageId = this.eImgId
    this.$store.commit('Card_details/ADD', {uid})
    if(this.eImgId){
      this.$store.commit('Card_details/ADD', {uid, id: this.eImgId})
    }else{
      this.$store.commit('Card_details/ADD', {uid})
    }

    window.addEventListener('upload-card-detail-init-editing', (e) => {
      this.editImageId = e.detail.editImageId
      this._init_image_url = e.detail.init_image_url
      this.label = e.detail.label
      if(e.detail.editImageId){
        this.$store.commit('Card_details/ADD', {uid, id: e.detail.editImageId})
      }else{
        this.$store.commit('Card_details/ADD', {uid})
      }
    })
	}
}
</script>'

<style lang="scss" scoped>
.upload-card-detail{
  .close {
    width: 20px;
    height: 20px;

    &:after {
        content: '';
        height: 25px;
        border-left: 2px solid black;
        position: absolute;
        transform: rotate(45deg);
        left: 10px;
        top: 0;
    }

    &:before {
        content: '';
        height: 25px;
        border-left: 2px solid black;
        position: absolute;
        transform: rotate(-45deg);
        left: 10px;
        top: 0;
    }

    &.btn-disabled{
      cursor: not-allowed;
    }
  }

  .upload-dropzone{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 5px;
    height: 100%;
    width: 100%;
    border: 1px #d1d3d4 dashed;
    background-color: #f8f8f9;
    cursor: pointer;
    z-index: 1;
    padding: 50px 10px;
    margin: 40px 0;

    &.in-progress{
      cursor: progress;
    }

    &:hover {
        border-color: #8f969d;
        .upload-image-title {
            color: #808285;
        }
    }
    // dragover hover
    &.dragover {
        cursor: copy;
        border-color: #8f969d;
        .upload-image-title {
            color: #808285;
        }
    }

    >input{
      display: none !important;
    }

    .upload-image-title {
        font-size: 14px;
        color: #a7a9ac;
        text-align: center;

        .bold-text{
          font-family: "montserrat-bold";
        }
    }
  }

  .add-photo-original-text-card{
    &.btn-disabled{
      cursor: not-allowed !important;
      border: 1px solid #d1d3d4;
      background-color: #ffffff;
      font-family: 'Montserrat', sans-serif !important;
      color: #d1d3d4 !important;
    }
  }
}
</style>