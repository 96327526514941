const getDefaultState = () => {
    return {
        modal: null,
    }
}

const ui = {
    namespaced: true,
    state: getDefaultState(),
    getters: {},
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
    },
    actions: {}
}

export default ui