
const images = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {

    }
}

export default images