<template>
<div class="image-preview-component ImagePreview" :class="{'inProgress': inProgress}">
    <CoverImg :src="imgURL" :height_ratio="1">
        <div class="item-content">
            <div class="text-right">
                <DeleteBtn @click="deleteImage" />
            </div>
            <div v-if="inProgress" class="text-center progress-status">
                <span v-if="image.upload_percentage">{{ image.upload_percentage }}%</span>
                <span v-else class="spinner-border" />
            </div>
        </div>
    </CoverImg>
</div>
</template>

<script>
import Image from '@models/Image'
import CoverImg from "@kit/CoverImg";
import DeleteBtn from "./DeleteBtn";
    export default {
        components: { CoverImg, DeleteBtn },
        props: {
            item: { type: Object }
        },
        computed: {
            image() {
                return Image.query().find(this.item.id)
            },
            inProgress() {
                return this.image && ['UPLOADING', 'UPLOADED'].includes(this.image.processing_stage)
            },
            imgData() {
                return this.image || this.item
            },
            imgURL() {
                return this.inProgress ? this.image.image_tmp_url : (this.imgData.thumb_avatar || this.imgData.avatar)
            },
        },
        methods: {
            deleteImage() {
                if (this.image?.cancelTokenSource) {
                    this.image.cancelTokenSource.cancel('cancel img upload by user')
                }
                this.$emit('delete')
            },
        }
    }
</script>

<style lang="scss">
.ImagePreview {
    &.inProgress {
        .item-content {
            background: rgba(255,255,255,0.5);
        }
        .progress-status {
            text-align: center;
            color: black;
            text-shadow: 0 0 0 #fff;
        }
    }
}
</style>


