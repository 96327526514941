import {
    getStorageSize,
    getFilesInfo
} from '../../api/api.storage'
import UserFile from '@models/UserFile'

const getDefaultState = () => {
    return {
        sizes: {
            total_space: 0,
            used_space: 0,
            free_space: 0,
            files_total: 0,
            files_images_total: 0,
            files_videos_total: 0,
            files_audios_total: 0,
            files_others_total: 0,
            files_images_total_size: 0,
            files_videos_total_size: 0,
            files_audios_total_size: 0,
            files_others_total_size: 0
        },
        meta: {
            userId: null,
            storageId: null
        }
    }
}

const storage = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getStorageInfo: state => state.sizes
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        UPDATE_SIZE_DATA(state, data) {
            state.sizes = {...state.sizes, ...data}
        },
        SET_META_DATA(state, data) {
            state.meta.storageId = data.storageId
            state.meta.userId = data.userId
        }
    },
    actions: {
        async GET_STORAGE_SIZE({commit, dispatch}, id) {
            const response = await getStorageSize()
            commit('UPDATE_SIZE_DATA', response.data.response)
            commit('SET_META_DATA', {userId: id, storageId: response.data.response.id})
            return dispatch('GET_FILES_INFO', {userId: id, storageId: response.data.response.id})
        },
        async GET_FILES_INFO({commit}) {
            const response = await getFilesInfo()
            let type = ''
            const formatedResponse = response.data.response.map(function(item) {
                switch (item.uploader_type) {
                    case 'AvatarUploader':
                        type = 'images'
                        break;
                    case 'CoverUploader':
                        type = 'images'
                        break;
                    case 'FullAvatarUploader':
                        type = 'images'
                        break;
                    case 'TrendingUploader':
                        type = 'images'
                        break;
                    case 'VideoUploader':
                        type = 'videos'
                        break;
                    case 'AudioUploader':
                        type = 'audios'
                        break;
                    case 'DocumentUploader':
                        type = 'documents'
                        break;
                    }
                return {
                    ...item,
                    type: type
                }
            })
            UserFile.insertOrUpdate({data: formatedResponse})
            return response
        },
        async FILTER_FILES_INFO({state, commit}, {filter, page}) {
            UserFile.create({data: {}})
            const response = await getFilesInfo(filter, page);
            let type = ''
            const formatedResponse = response.data.response.map(function(item) {
                switch (item.uploader_type) {
                    case 'AvatarUploader':
                        type = 'images'
                        break;
                    case 'CoverUploader':
                        type = 'images'
                        break;
                    case 'FullAvatarUploader':
                        type = 'images'
                        break;
                    case 'TrendingUploader':
                        type = 'images'
                        break;
                    case 'VideoUploader':
                        type = 'videos'
                        break;
                    case 'AudioUploader':
                        type = 'audios'
                        break;
                    case 'DocumentUploader':
                        type = 'documents'
                        break;
                    }
                return {
                    ...item,
                    type: type
                }
            })
            UserFile.insertOrUpdate({data: formatedResponse})
            if (!page) {
                return response
            }
        }
    }
}

export default storage