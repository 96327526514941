<template>
<div class="DeleteBtn" @click.stop.prevent="$emit('click')">
    <button class="btn__reset">
        <i class="icon-close_bold" />
    </button>
</div>
</template>

<script>
    export default {
    }
</script>

<style lang="scss" scoped>
.DeleteBtn {
    padding: 5px;
    cursor: pointer;
}
</style>


