<template>
<div class="liker-item-component LikerItem">
    <a class="item-info" :href="profileUrl" @click="goToProfile">
        <CoverImg :src="liker.avatar" :height_ratio="1" />
        <div class="user-name">{{ liker.full_name }}</div>
    </a>

    <div v-if="!isCurrent" class="item-actions">
        <FollowBtn :short="true" :user="liker" @is_following="liker.is_following = $event" />
        <AddFriendBtn :short="true"
                      :user="liker"
                      @is_friend="liker.is_friend = $event"
                      @friend_status="liker.friend_status = $event" />
    </div>
</div>
</template>

<script>

import {mapGetters} from "vuex";
import CoverImg from "@kit/CoverImg";
import FollowBtn from "@kit/buttons/FollowBtn";
import AddFriendBtn from "@kit/buttons/AddFriendBtn";

export default {
    name: "LikerItem",
    components: {CoverImg, FollowBtn, AddFriendBtn},
    props: {
        liker:     {type: Object, default: () => []},
    },
    data() {
        return {
            loadError: false,
            showAttachments: false,
            currentComment: null,
        }
    },
    computed: {
        ...mapGetters('auth', {
            currentUser: 'getCurrentUser'
        }),
        isCurrent() {
            return this.liker.is_current || this.liker.id === this.currentUser.id
        },
        profileUrl() {
            return this.isCurrent ? '/users/profile' : `/users/${this.liker.id}/other_profile`
        },
    },
    methods: {
        goToProfile() {
            if (this.profileUrl !== this.$router.currentRoute.path) {
                this.$router.push({ path: this.profileUrl })
            }
            this.$emit('click')
        }
    }
}
</script>

