<template>
<modal
    name="thanks-form"
    :width="0">
    <div
        id="login_modal"
        class="modal modal__400 modal__thankYouJoin show outside-click-exclude">
        <section v-click-outside="hide">
            <a
                class="modal__close"
                @click="hide">
                <i class="icon-close" />
            </a>
            <div class="modal__head text__bold text__center">
                {{ $t('THANK.TITLE') }}
            </div>
            <div class="modal__body text__center">
                <p>
                    {{ $t('THANK.P_1') }}
                    <br>
                    {{ $t('THANK.P_2') }}
                </p>
                <p class="margin-t__40">
                    {{ $t('THANK.P_3') }}
                    <br>
                    {{ $t('THANK.P_4') }}
                </p>
                <p class="margin-t__40">
                    {{ $t('THANK.P_5') }}
                    <br>
                    <a
                        href="#"
                        class="text__bold"
                        :class="{disabled: message}"
                        @click="resend">{{ message ||
                            $t('LINK.RESEND') }}</a>
                </p>
            </div>
            <div class="modal__footer text__center margin-t__40">
                <button
                    class="btn full__width"
                    @click="hide">
                    {{ $t('BUTTON.CLOSE') }}
                </button>
            </div>
        </section>
    </div>
</modal>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'ThanksForm',
        data() {
            return {
                message: ''
            }
        },
        methods: {
            ...mapActions('auth', {
                resendEmail: 'RESEND_EMAIL'
            }),
            // @vuese
            // Method to hide Thanks modal
            hide() {
                this.$modal.hide('thanks-form')
                this.message = ''
                this.$router.push({path: '/'})
            },
            // @vuese
            // Method to call resendEmail from Api
            async resend() {
                const response = await this.resendEmail()
                this.message = response.data.message
            }
        }
    }
</script>

<style lang="scss">
    a.disabled {
        pointer-events: none;
        cursor: auto;
    }
</style>