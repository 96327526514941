import axios from 'axios'

const state = {
    list: []
}

const actions = {
    fetch_all({commit}, payload) {
        return new Promise((resolve, reject) => {
            let {holder_type, holder_id} = payload;
            axios.get('/media/subtitles', {params: {holder_type, holder_id}}).then((response) => {
                commit('CLEAR_ALL');
                response.data.response.forEach(i => { commit('ADD', {holder_type, holder_id, ...i}) });
                resolve(response)
            }).catch(reject)
        })
    },
    create({commit, state, getters}, payload) {
        let {uid} = payload,
            subtitle = getters.getSubtitleByUid(uid)

        if (!subtitle.api_request_processing) {
            commit('UPDATE', {uid, api_request_processing: true})
            return new Promise((resolve, reject) => {
                axios.post('/media/subtitles', {subtitle: {
                            origin_name: subtitle.origin_name,
                            origin_type: subtitle.origin_type,
                            origin_size: subtitle.origin_size,
                            holder_type: subtitle.holder_type,
                            holder_id: subtitle.holder_id,
                            srclang: subtitle.srclang,
                            label: subtitle.label
                        }
                    })
                    .then((response) => {
                        commit('UPDATE', {uid, ...response.data.response})
                        resolve()
                    }).catch((error) => {
                        if (error.response.data.status === 422) {
                            commit('UPDATE', {uid, processing_errors: error.response.data.message})
                        } else {
                            let errors = { 'api_error': ["post request is failed"] }
                            commit('UPDATE', {uid, processing_errors: errors})
                        }
                        reject("Failed to create subtitle")
                    }).then(() => {
                        commit('UPDATE', {uid, api_request_processing: false})
                    })
            })
        } else {
            console.error('POST /media/subtitles is already processing')
        }
    },
    upload({commit, state, getters}, payload) {
        let {uid} = payload,
            subtitle = getters.getSubtitleByUid(uid),
            signedUrl = subtitle.upload_links.put_url,
            publicUrl = subtitle.upload_links.public_url,
            file = subtitle.file
        commit('UPDATE', {uid, status: 'UPLOADING'})
        return new Promise((resolve, reject) => {
            axios.put(signedUrl, file,{
                headers: {'Content-Type': file.type},
                onUploadProgress: function (progressEvent) {
                    let percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                    commit('UPDATE', {uid, upload_percentage: percent})
                }
            }).then((response) => {
                commit('UPDATE', {uid, origin_s3_path: publicUrl})
                resolve()
            }).catch((error) => {
                let errors = { 'Upload to storage error': ["Please try again later"] }
                commit('UPDATE', {uid, processing_errors: errors})
            })
        })
    },
    update({commit, getters}, payload) {
        let {uid} = payload,
            subtitle = getters.getSubtitleByUid(uid)
        if (!subtitle.api_request_processing) {
            commit('UPDATE', {uid, api_request_processing: true})
            return new Promise((resolve, reject) => {
                const { origin_s3_path, srclang, label } = subtitle;
                axios.put(`/media/subtitles/${subtitle.id}`, {
                    subtitle: { origin_s3_path, srclang, label }
                }).then((response) => {
                    commit('UPDATE', {uid, ...response.data.response})
                    resolve()
                }).catch((error) => {
                    if (error.response.data.status === 422) {
                        commit('UPDATE', {uid, processing_errors: error.response.data.message})
                    } else {
                        let errors = { 'api_error': ["put request is failed"] }
                        commit('UPDATE', {uid, processing_errors: errors})
                    }
                    reject("Failed to update subtitle")
                }).then(() => {
                    commit('UPDATE', {uid, api_request_processing: false})
                })
            })
        } else {
            console.error('PUT /media/subtitles/:id is already processing')
        }
    },
    destroy({commit, state}, payload) {
        commit('CLEAR', payload);
        return new Promise((resolve, reject) => {
            let {id} = payload;
            if ( !id ) {
                resolve("Subtitle id is not detected");
            } else {
                axios.delete(`/media/subtitles/${id}`)
                    .then((response) => {
                        resolve()
                    })
                    .catch((error) => {
                        reject("Failed to delete subtitle");
                    })
            }
        })
    },
}

const mutations = {

    ADD(state, payload) {
        state.list.push({
            uid: '_' + Math.random().toString(36).substr(2, 9),
            id: '',
            status: 'INITIATED', // 'INITIATED', 'CHECKING', 'UPLOADING', 'UPLOADED', 'COMPLETE'
            origin_name: '',
            origin_type: '',
            origin_size: '',
            holder_type: '',
            holder_id: '',
            srclang: '',
            label: '',
            paths: {},
            upload_links: {},
            file: undefined,
            url: '',
            api_request_processing: false,
            processing_errors: {},
            upload_percentage: 0,
            ...payload
        })
    },

    PARSE_FILE(state, payload){
        let {uid, file} = payload;
        let item = state.list.find(_item => _item.uid === uid)
        Object.assign(item, {
            status: 'CHECKING',
            file: file,
            origin_name: file.name,
            origin_type: file.type,
            origin_size: file.size,
            url: URL.createObjectURL(file)
        })
    },

    UPDATE(state, payload) {
        const {uid} = payload;
        const item = state.list.find(item => item.uid === uid);
        if (item) { Object.assign(item, payload) }
    },

    CLEAR(state, payload) {
        let {id, uid} = payload;
        const index = state.list.findIndex(item => (id && item.id === id) || item.uid === uid);
        state.list.splice(index, 1);
    },

    CLEAR_ALL(state, payload) {
        state.list = [];
    },
}

const getters = {
    getSubtitleByUid: state => uid => {
        return state.list.find(item => item.uid === uid)
    }
}

export default {
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
    getters: getters
}