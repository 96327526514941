<template>
<div class="SelectListsOfFriends">
    <div v-if="!loaded" class="spinner-border" />
    <vue-select v-if="loaded"
                multiple
                placeholder="Select Lists of Friends"
                :options="friend_lists"
                label="name"
                :reduce="option => option.id"
                :selectable="selected => !friends_group.includes(selected.id)"
                :value="friends_group"
                @input="$emit('changed', $event)" />
</div>
</template>

<script>
    import VueSelect from 'vue-select'
    import {getFriendLists} from "@api/api.friends"

    export default {
        components: {
            VueSelect,
        },
        props: {
            friends_group: {type: Array, default: () => []},
        },
        data() {
            return {
                loaded: false,
                friend_lists: [],
            }
        },
        mounted() {
            this.loadFriends()
        },
        methods: {
            async loadFriends() {
                try {
                    const resp = await getFriendLists()
                    this.friend_lists = resp.data.response
                } catch (e) {
                    console.log('loadFriends err', e)
                } finally {
                    this.loaded = true
                }
            },
            addNewFriendsList(newList) {
                this.friend_lists.push(newList)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .SelectListsOfFriends {
        display: flex;
        .v-select {
            min-width: 200px;
        }
    }
</style>