<template>
<modal
    name="post-form"
    :width="0"
    :click-to-close="false"
    @before-open="modalBeforeOpen"
    @closed="modalClosed">
    <div class="modal show modal__post_form modal__new_design" :class="{'share-post-form-modal': isSharingPost}">
        <section>
            <PostForm v-if="modalOpened" :post_init="post" @close="hide" />
        </section>
    </div>
</modal>
</template>

<script>
import PostForm from '../PostForm'

export default {
    name: 'PostFormModal',
    components: { PostForm },
    data() {
        return {
            post: null,
            modalOpened: false,
        }
    },
    computed: {
        isSharingPost () {
            return !!this.post?.share_post
        },
    },
    mounted() {
        const that = this
        //window.dispatchEvent(new CustomEvent('click-create-post'));
        window.addEventListener('click-create-post', async (e) => {
            that.$modal.show('post-form', { ...e.detail })
        })
    },
    methods: {
        hide() {
            this.$modal.hide('post-form')
        },
        modalBeforeOpen(event) {
            const { params } = { ...event }
            const { post } = { ...params }
            if (post) {
                this.post = post
            }
            this.modalOpened = true
        },
        modalClosed() {
            // this.modalOpened = false
            this.post = null
        },
    },
}
</script>

<style lang="scss"> //unscoped
</style>

<style lang="scss" scoped>
</style>