import request from '@utils/request'

export function getDiscoveryList(payload) {
    return request({
        url: '/discovers',
        method: 'get',
        params: {
            ...payload
        }
    })
}

export function getDiscoverTopics(payload) {
    return request({
        url: '/discovers/get_discover_topics',
        method: 'get',
        params: {
            ...payload
        }
    })
}

export function addDiscoverTopics(payload) {
    return request({
        url: '/discovers/add_topics',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function searchTopics(payload) {
    return request({
        url: '/follows/suggest_topics',
        method: 'get',
        params: {
            ...payload
        }
    })
}

export function createTopic(payload) {
    return request({
        url: '/follows/create_topic',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function setTopics(payload) {
    return request({
        url: '/discovers/set_topics',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function groupFollow (payload) {
    const { group_id } = payload
    return request({
        url: `/groups/${group_id}/members/group_follow`,
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function groupJoin (payload) {
    const { group_id } = payload
    return request({
        url: `/groups/${group_id}/members`,
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function groupUnjoin (payload) {
    const { group_id, user_id } = payload
    return request({
        url: `/groups/${group_id}/members/${user_id}`,
        method: 'delete',
        data: {
            ...payload
        }
    })
}

export function userFollow (payload) {
    return request({
        url: '/friends/follow',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function userUnfollow (payload) {
    return request({
        url: '/friends/unfollow',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function userLike (payload) {
    return request({
        url: '/users/elevate',
        method: 'post',
        data: {
            ...payload
        }
    })
}

export function contentLike (payload) {
    return request({
        url: '/elevates',
        method: 'post',
        data: {
            ...payload
        }
    })
}
