import {Model} from '@vuex-orm/core'
import Album from '@models/Album'

class Photo extends Model {
    static entity = 'photos'

    static fields() {
        return {
            // api partial
            id: this.uid(),
            album_id: this.attr(null),
            origin_name: this.attr(''),
            origin_type: this.attr(''),
            origin_size: this.attr(0),
            image_url: this.attr(''),
            image_thumb_url: this.attr(''),
            processing_stage: this.attr(''), // 'RESERVED', 'UPLOADING', 'UPLOADED', 'COMPLETED'
            image_upload_links: this.attr({}),
            created_at: this.attr(''),
            updated_at: this.attr(''),
            // only ui
            image_tmp_url: this.attr(''),
            upload_percentage: this.attr(null),
            selected: this.attr(false),
            // relations
            photoable_id: this.attr(''),
            photos: this.belongsTo(Album, 'album_id')
        }
    }

    get preview_url(){
        if (this.processing_stage === 'COMPLETED'){
            return this.image_thumb_url
        }else if (this.image_tmp_url) {
            return this.image_tmp_url
        } else {
            return this.image_url
        }
    }

    static apiConfig = {
        actions: {
            async fetch(params = {}) {
                return await this.get("/storage/photos", {
                    params: params,
                    dataKey: 'response'
                })
            },

            async destroy(id) {
                return await this.delete(`/storage/photos/${id}`, { delete: id })
            },

            // Send information about the file to make sure file is valid and getting upload links
            async create(file){
                let data = {
                    origin_name: file.name,
                    origin_type: file.type,
                    origin_size: file.size
                }
                let create_result;
                try {
                    create_result = await this.post('/storage/photos', data, {
                        dataKey: 'response'
                    })
                }catch(response){
                    let message = `${file.name} - ${Object.values(response.data.message)[0]}`
                    iziToast.error({ message: message, maxWidth: '700px' })
                    return;
                }

                // uploading
                let photo = Photo.find(create_result.response.data.response.id),
                    signedUrl = photo.image_upload_links.put_url,
                    publicUrl = photo.image_upload_links.public_url
                await Photo.update({id: photo.id, processing_stage: 'UPLOADING', image_tmp_url: URL.createObjectURL(file)})

                let upload_result;
                try {
                    upload_result = await this.put(signedUrl, file, {
                        timeout: 60 * 60 * 1000,
                        headers: {'Content-Type': file.type},
                        onUploadProgress: function (progressEvent) {
                            let percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            Photo.update({id: photo.id, upload_percentage: percent})
                        }
                    })
                }catch(response){
                    let message = `${file.name} - Uploading failed`
                    iziToast.error({ message: message, maxWidth: '700px' })
                    Photo.delete(photo.id)
                    return;
                }

                Photo.update({id: photo.id, image_url: publicUrl})

                let data2 = {
                    remote_image_url: publicUrl
                }
                try {
                    const update_result = await this.put(`/storage/photos/${photo.id}`, data2, {
                        dataKey: 'response'
                    })
                } catch(response) {
                    let message = `${file.name} - ${Object.values(response.data.message)[0]}`
                    iziToast.error({ message: message, maxWidth: '700px' })
                    Photo.delete(photo.id)
                    return;
                }
            }
        }
    }
}

export default Photo