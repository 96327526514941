import {
    FetchTrendingFrequencies,
    GetFollowersList,
    FollowUnfollow,
    GetFollowCount
} from "../../api/api.trending_feeds";

const getDefaultState = () => {
    return {
        followers: {},
        frequencies: [],
        followers_count: 0,
        followings_count: 0,
        pagination: {
            page: 1,
            limit: 10,
            domain: process.env.API_CLIENT
        }
    }
}

const trending_feeds = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getFollowersList: state => state.followers,
        getTrendingFrequencies: state => state.frequencies,
        getFollowersCount: state => state.followers_count,
        getFollowingsCount: state => state.followings_count
    },
    mutations: {
        FOLLOWERS(state, data) {
            state.followers = data
        },

        SET_FOLLOWERS_COUNT(state, data) {
            state.followers_count = data.followers_count
            state.followings_count = data.followings_count
        },

        async FOLLOW(state, data) {
            console.log('---------')
            console.log(state.followers)
            var index = state.followers.findIndex(e => e.user_id == data.follow.user_id)
            state.followers[index].is_following = data.type == 'follow' ? true : false
        },

        SET_TRENDING_FREQUENCIES(state, frequencies) {
            state.frequencies = frequencies
        },
        UPDATE_FOLLOW_STATUS(state, data) {
            const index = state.frequencies.findIndex(user => user.user_id === data.user_id)
            state.frequencies[index].is_following = data.is_following
            if (data.is_following) {
                state.frequencies[index].followers += 1
                state.followings_count +=1
            } else {
                state.frequencies[index].followers -= 1
                state.followings_count -=1
            }
        }
    },
    actions: {
        async FOLLOWERS({ commit, dispatch }, data) {
            const response = await GetFollowersList(data);
            commit('FOLLOWERS', response.data.response)
            commit('SET_FOLLOWERS_COUNT', response.data)
        },

        async FOLLOW({ commit, dispatch }, data) {
            const response = await FollowUnfollow(data);
            const follow = response.data.response
            // commit('FOLLOW', { follow, type: data["type"]})
            return response
        },

        async TRENDING_FREQUENCIES({ commit, dispatch }, data) {
            const response = await FetchTrendingFrequencies();
            commit('SET_TRENDING_FREQUENCIES', response.data.response);
            commit('SET_FOLLOWERS_COUNT', response.data)
        },

        async UPDATE_FOLLOW_STATUS({ commit, dispatch }, data) {
            await commit('UPDATE_FOLLOW_STATUS', data);
        },

        async UPDATE_FOLLOW_COUNT({ commit, dispatch }, data) {
            const response = await GetFollowCount();
            commit('SET_FOLLOWERS_COUNT', response.data.response)
        }

    }
}

export default trending_feeds