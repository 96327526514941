import axios from 'axios'
import filters from '../../helpers/filters'

const state = {
    list: []
}

const actions = {
    create({commit, state, getters}, payload) {
        let {uid} = payload,
            image = getters.getImageByUid(uid)

        if (!image.api_request_processing) {
          commit('UPDATE', {uid, api_request_processing: true})
          return new Promise((resolve, reject) => {
            axios.post('/card_details', {card_detail: {
                "file_name": image.origin_name,
                "file_size": image.origin_size,
                "file_type": image.origin_type
              }
            })
            .then((response) => {
              commit('UPDATE', {uid, ...response.data.response})
              resolve()
            }).catch((error) => {
              if (error.response.data.status === 422) {
                commit('UPDATE', {uid, processing_errors: error.response.data.message})
              } else {
                let errors = { 'api_error': ["post request is failed"] }
                commit('UPDATE', {uid, processing_errors: errors})
              }
              reject("Failed to create image")
            }).then(() => {
              commit('UPDATE', {uid, api_request_processing: false})
            })
          })
        } else {
          console.error('POST /card_details is already processing')
        }
    },
    upload({commit, state, getters}, payload) {
        let {uid} = payload,
            image = getters.getImageByUid(uid),
            signedUrl = image.upload_links.put_url,
            publicUrl = image.upload_links.public_url,
            file = image.file
        commit('UPDATE', {uid, status: 'UPLOADING'})
        return new Promise((resolve, reject) => {
            axios.put(signedUrl, file,{
              headers: {'Content-Type': file.type},
              onUploadProgress: function (progressEvent) {
                let percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                commit('UPDATE', {uid, upload_percentage: percent})
              }
            }).then((response) => {
              commit('UPDATE', {uid, ...{status: 'UPLOADED', origin_s3_path: publicUrl}})
              resolve()
            }).catch((error) => {
              let errors = { 'Upload to storage error': ["Please try again later"] }
              commit('UPDATE', {uid, processing_errors: errors})
            })
        })
    },
    update({commit, getters}, payload) {
        let {uid, update_data} = payload,
            image = getters.getImageByUid(uid)
            
        if (!image.api_request_processing) {
            commit('UPDATE', {uid, api_request_processing: true})
            return new Promise((resolve, reject) => {
                axios.put(`/card_details/${image.id}`, {
                    card_detail: update_data
                }).then((response) => {
                    commit('UPDATE', {uid, ...{status: 'COMPLETE'}, ...response.data.response})
                    resolve()
                }).catch((error) => {
                    if (error.response.data.status === 422) {
                        commit('UPDATE', {uid, processing_errors: error.response.data.message})
                    } else {
                        let errors = { 'api_error': ["put request is failed"] }
                        commit('UPDATE', {uid, processing_errors: errors})
                    }
                    reject("Failed to create image")
                }).then(() => {
                    commit('UPDATE', {uid, api_request_processing: false})
                })
            })
        } else {
            console.error('PUT /card_details/:id is already processing')
        }
    },
    destroy({commit, getters}, payload){
      let {uid} = payload,
          image = getters.getImageByUid(uid)
            
        if (!image.api_request_processing && image.id) {
            commit('UPDATE', {uid, api_request_processing: true})
            return new Promise((resolve, reject) => {
                axios.delete(`/card_details/${image.id}`)
                .then((response) => {
                    // delete from state
                    // commit('UPDATE', {uid, ...{status: 'COMPLETE'}, ...response.data.response})
                    resolve()
                }).catch((error) => {
                    if (error.response.data.status === 422) {
                        commit('UPDATE', {uid, processing_errors: error.response.data.message})
                    } else {
                        let errors = { 'api_error': ["Delete request is failed"] }
                        commit('UPDATE', {uid, processing_errors: errors})
                    }
                    reject("Failed to create image")
                }).then(() => {
                    commit('UPDATE', {uid, api_request_processing: false})
                })
            })
        }
    }
}

const new_card_detail = (payload) => {
    return {
        uid: '_' + Math.random().toString(36).substr(2, 9),
        id: '',
        status: 'INITIATED', // 'INITIATED', 'CHECKING', 'UPLOADING', 'UPLOADED', 'COMPLETE', 'ERROR'
        origin_name: '',
        origin_type: '',
        origin_size: '',
        card_text: '',
        card_label: '',
        upload_links: {},
        file: undefined,
        url: '',
        api_request_processing: false,
        processing_errors: {},
        upload_percentage: 0,
        initImageUrl: '',
        ...payload
    }
}

const mutations = {

    ADD(state, payload) {
        let {uid} = payload
        let img = state.list.find(img => img.uid === uid)
        if(img){
            Object.assign(img, new_card_detail(payload))
        }else{
            state.list.push(new_card_detail(payload))
        }
    },

    PARSE_FILE(state, payload){
        let {uid, file} = payload
        let img = state.list.find(img => img.uid === uid)
        Object.assign(img, {
            status: 'CHECKING',
            file: file,
            origin_name: file.name,
            origin_type: file.type,
            origin_size: file.size,
            url: URL.createObjectURL(file)
        })
    },

    UPDATE(state, payload) {
        let {uid} = payload
        let img = state.list.find(img => img.uid === uid)
        Object.assign(img, payload)
        if(payload.hasOwnProperty('processing_errors')){
          let message = ''
          for (let [key, errors] of Object.entries(payload.processing_errors)) {
            message += `<div><b>${filters.capitalize(filters.unSnakeCase(key))}</b>`
            message += `<ul style="margin: 0">`
            for(let error of errors){
              message += `<li>${error}</li>`
            }
            message += '</ul><div>'
          }
          iziToast.error({
            message: message,
            maxWidth: '700px'
          })
        }
    },

    CLEAR(state, payload) {
        let {uid} = payload;
        let img = state.list.find(img => img.uid === uid)
        Object.assign(img, new_card_detail(payload))
    }
}

const getters = {
    getImageByUid: state => uid => {
        return state.list.find(img => img.uid === uid)
    }
}

export default {
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
    getters: getters
}