<template>
<div class="power-area">
    <Mentionable
        :keys="['@']"
        :items="items"
        :insert-space="true"
        :map-insert="mapInsert"
        :placement="'bottom-start'"
        @open="loadIssues()"
        @search="loadIssues($event)"
        @onApply="addMention">
        <textarea-autosize ref="myTextarea"
                           :maxlength="maxlength"
                           :placeholder="placeholder"
                           :value="text"
                           :min-height="30"
                           :max-height="350"
                           @input="setText" />
        <template #no-result>
            {{ mentions_loading ? 'Loading...' : 'No result' }}
        </template>
        <template #item-@="{ item }">
            <div class="issue">
                <span class="dim">
                    {{ item.label }}
                </span>
            </div>
        </template>
    </Mentionable>
</div>
</template>

<script>
    import axios from 'axios'
    import Mentionable from './Mentionable'
    import {getUsersMentions} from '@api/api.users'

    export default {
        name: 'PowerArea',
        components: {Mentionable},
        props: {
            value: { type: Object, default: () => { return {text: '', mentions: []} } },
            maxlength: { type: Number, default: 1000 },
            placeholder: { type: String, default: '' }
        },
        data() {
            return {
                items: [], // mentions - search results
                mentions_loading: false,
                cancelToken: null
            }
        },
        computed: {
            text: {
                get: function(){
                    return this.value.text
                },
                set: function(val){
                    this.$emit('change', 'text', val)
                }
            },
            mentions: {
                get: function(){
                    return this.value.mentions
                },
                set: function(val){
                    this.$emit('change', 'mentions', val)
                }
            }
        },
        mounted() {
            this.$refs.myTextarea.$el.addEventListener('paste', this.onPaste)
            this.$refs.myTextarea.$el.addEventListener('focus', this.onFocus)
        },
        methods: {
            async loadIssues(searchText = '') {
                this.mentions_loading = true
                if (this.cancelToken){ this.cancelToken.cancel() }
                this.cancelToken = axios.CancelToken.source();
                let response = await getUsersMentions(searchText, 5, this.cancelToken)
                this.items = response.data.response || []
                this.mentions_loading = false
            },
            mapInsert(item, key) {
                return item.label
            },
            addMention(item){
                this.mentions = [...this.mentions, item]
            },
            setText(val){
                this.text = val
            },
            onFocus(event) {
                this.$emit('focus', event)
            },
            onPaste(event) {
                this.$emit('paste', event)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .popover-inner{
        padding: 5px !important;
    }
    .issue {
        padding: 2px 2px;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mention-selected .issue {
        background: rgb(139, 212, 255);
    }

    .issue .number {
        font-family: monospace;
    }

    .dim {
        color: #666;
    }

</style>